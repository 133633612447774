<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid" style="padding:0px !important">
    <div class="card col-xl-12">
      <div class="row">
        <!-- <div class="col-md-6 row mt-3"  v-if="userType == '2' || userType == '3'">
              <div class="col-xl-12">
              <h1 class="page-title mt-2">
                {{ $t('title.LeaveList') }}
              </h1>
              </div>
 
            <div class="col-xs-12 col-md-6 my-2">
                <treeselect v-model="departmentid" :multiple="false" 
                :options="departmentOption" :default-expand-level="Infinity"
                @input="changeDepartment"
                :noChildrenText="$t('label.noNodeChildren')" :placeholder="$t('label.chooseDepartment')" />
              </div>
              <div class="col-xs-12 col-md-6 my-2">
                <treeselect v-model="shiftid" :multiple="false" :options="shiftOption" :flat="false"
                          :default-expand-level="1" :placeholder="$t('label.filterByuser')" 
                          @input="changeDepartment"
                       /> 
                
              </div>
            <div class="col-xs-6 col-md-6">
                    <b-form-input v-model="aliasId" :placeholder=" $t('label.employeeCode')" @input="changeDepartment" type="text">
                    </b-form-input>
            </div>
            <div class="col-xs-6 col-md-6">
              <b-form-group>
                <div class="autocomplete" v-click-outside="handleClickOutside">
                  <b-form-input v-model="fullName" @input="onChangeName" type="text" :placeholder=" $t('label.fullName')">
                  </b-form-input>
                  <ul v-show="isOpenNameRes" class="autocomplete-results">
                    <li v-for="(result, i) in nameResults" :key="i" @click="setNameResult(result)"
                      class="autocomplete-result">
                      {{ result.value }} - {{ result.text }}
                    </li>
                  </ul>
                </div>
              </b-form-group>
            </div>

          </div> -->
        <div class="col-md-6 row mt-0">
          <div class="col-xl-12">
            <h1 class="page-title">
              Danh sách nghỉ chế độ
            </h1>
          </div>
          <div class="col-xs-12 col-md-6">
            <treeselect v-model="departmentid" :multiple="false" :options="departmentOption"
              :default-expand-level="Infinity" @input="changeDepartment" :noChildrenText="$t('label.noNodeChildren')"
              :placeholder="$t('label.chooseDepartment')" clearable />
          </div>
          <div class="col-xs-12 col-md-6">
            <b-form-group>
              <flat-pickr v-model="dateSelectSearch" :config="configDateTimePickerEdit"
                :placeholder="$t('label.chooseDateRange')" name="reportDate" class="form-control"
                @input="getListLeave()" clearable></flat-pickr>
              <div class="error-message w-100 clear text-left">
                {{ msg["offWorkFrom"] ? $t(msg["offWorkFrom"]) : "" }}
              </div>
            </b-form-group>
          </div>
          <div class="col-xs-6 col-md-6">
            <b-form-input v-model="aliasId" :placeholder="$t('label.employeeCode')" @input="changeDepartment"
              type="text">
            </b-form-input>
          </div>
          <div class="col-xs-6 col-md-6 pb-2">
            <b-form-group>
              <div class="autocomplete" v-click-outside="handleClickOutside">
                <b-form-input v-model="fullName" @input="onChangeName" type="text" :placeholder="$t('label.fullName')">
                </b-form-input>
                <ul v-show="isOpenNameRes" class="autocomplete-results">
                  <li v-for="(result, i) in nameResults" :key="i" @click="setNameResult(result)"
                    class="autocomplete-result">
                    {{ result.value }} - {{ result.text }}
                  </li>
                </ul>
              </div>
            </b-form-group>
          </div>

        </div>
        <div class="col-md-6 mt-3 pt-2">
          <h3 class="mt-1">Thêm mới ngày nghỉ chế độ</h3>

          <div class="row align-items-center justify-content-center">
            <!-- <div class="col-md-4 col-xs-12">
                <label>Loại đơn</label>
                <treeselect v-model="shiftsId" :multiple="false" :options="shiftsOptions" :flat="false"
                          :default-expand-level="1" :placeholder="$t('label.chooseShift')" 
                          />
              </div> -->

            <div class="col-md-4 col-xs-12">
              <label>{{ $t('label.startDate') }}</label>
              <flat-pickr v-model="startTimeAdd" :config="configDateTimePicker" name="birthdate"
                :placeholder="$t('label.startDate')" class="form-control"></flat-pickr>
            </div>
            <div class="col-md-4 col-xs-12">
              <label>{{ $t('label.endDate') }}</label>
              <flat-pickr v-model="endTimeAdd" :config="configDateTimePicker" name="birthdate" class="form-control"
                :placeholder="$t('label.endDate')"></flat-pickr>
            </div>
            <div class="col-md-4 col-xs-12">
              <label>{{ $t('label.leaveType') }}</label>
              <select v-model="leaveType" class="form-control">
                <option disabled hidden value="">
                  Chọn loại đơn báo
                </option>
                <option v-for="lt in leaveTypes" v-bind:value="lt.value">
                  {{ lt.text }}
                </option>
              </select>
              <div class="error-message w-100 clear text-left">
                {{ msg["leaveType"] ? $t(msg["leaveType"]) : "" }}
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-xs-12 pt-3">
              <!-- <b-form-group class="required-mess" id="input-group-2" :label="$t('label.reasonForOT')"> -->
              <b-form-group class="" id="input-group-2" :label="$t('label.reasonForLeave')">
                <textarea class="form-control form-control-sm" id="tar-2" :placeholder="$t('label.reasonForLeave')"
                  required v-model="reasonForOTAdd" rows="3"></textarea>
                <div class="error-message w-100 clear text-left">
                  {{ msg["reason"] ? $t(msg["reason"]) : "" }}
                </div>
              </b-form-group>
            </div>
            <div class="col-md-12 col-xs-12 mt-2">
              <div class="">
                <div class="text-right">
                  <a href="javascript:void(0)" class="btn btn-danger bg-gradient-danger"
                    @click="addLeave()"><font-awesome-icon icon="plus" /> {{ $t("label.addNew") }}</a>
                  <!-- <button class="btn btn-success bg-gradient-success btn-block" @click="addLeave()">Thêm mới ca</button> -->
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class=" col-xs-12 col-md-6">
          <div class="card">
            <div class="card-body m-0 p-0 table-responsive">
              <h2 class="ml-2">Danh sách nhân sự</h2>
              <b-table bordered="bordered" style="max-height: 500px;" class=" default-table" hover :items="dataTable"
                :fields="fields" responsive id="table-staff" @row-clicked="rowClickHandler"
                @head-clicked="handleHeaderClick($event)">
                <template #head(checkbox)="data">
                  <b-form-checkbox @click.native.stop @change="selectAll(data)" v-model="allSelected">
                  </b-form-checkbox>
                </template>
                <template #cell(checkbox)="data">
                  <b-form-checkbox @click.native.stop :value="data.item.aliasID" v-model="userSelected">
                  </b-form-checkbox>
                </template>
              </b-table>
              <b-pagination v-model="currentPage" :total-rows="totalRecord" :per-page="perPage" align="right" size="sm"
                class="m-4" aria-controls="table-staff" v-on:click.native="getEmployee()"></b-pagination>
            </div>
          </div>
        </div>
        <div class=" col-xs-12 col-md-6">
          <div class="card">
            <div class="card-body m-0 p-0 table-responsive">
              <h2 class="ml-2">Danh sách đơn nghỉ chế độ {{ nameEmployee }}</h2>
              <b-table bordered="bordered" style="max-height: 500px;" class="default-table" hover
                :items="dataTableLeave" :fields="fieldsHisotry" responsive :current-page="currentPageLeave"
                :per-page="perPage" id="table-Leave" show-empty empty-text="Không có dữ liệu"
                @head-clicked="handleHeaderClickDetails($event)">
                <template #cell(reason)="data">
                  <div class="m-0">

                    {{ data.item.reason }}
                  </div>
                  <small class="text-danger">
                    Mã:
                    {{ data.item.leaveCode }}
                  </small>
                </template>
                <template #cell(time)="data">
                  <div class="m-0">
                    {{ data.item.fromDate }} - {{ data.item.toDate }}
                  </div>
                </template>
                <template #cell(button)="data">
                  <button class="btn btn-primary btn-sm" @click="showInfoDetail(data.item)" v-b-tooltip.hover
                    :title="$t('label.edit')">
                    <font-awesome-icon icon="edit" />
                  </button>
                  <button class="btn btn-danger btn-sm" @click="showDelete(data.item.id)" v-b-tooltip.hover
                    :title="$t('label.delete')">
                    <font-awesome-icon icon="trash" />
                  </button>
                </template>
                <template #empty="scope">
                  {{ $t("notify.message.nodata") }}
                </template>
              </b-table>
              <b-pagination v-model="currentPageLeave" :total-rows="totalRecordOT" :per-page="perPageLeave"
                align="right" size="sm" class="m-4" aria-controls="table-Leave"
                v-on:click.native="getListLeave(this.employeeId)"></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="modalInfoDetail" ref="modal-info-detail" :title="$t('label.editLeaveRegimeApplication')"
      hide-footer header-bg-variant="secondary" modal-class="modal-info-detail text-center" centered size="lg">
      <b-form ref="form" class="row">
        <div class="col-lg-12 col-md-12 col-xs-12">
          <b-form-group id="input-group-1" class="required-mess" :label="$t('label.selectStaff')">
            <b-form-input disabled type="text" v-model="idPerson" min="0" max="200"></b-form-input>
            <div class="error-message w-100 clear text-left">
              {{ msg["userId"] ? $t(msg["userId"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12">
          <b-form-group id="input-group-1" class="required-mess" :label="$t('label.chooseDateRange')">
            <flat-pickr v-model="dateSelect" :config="configDateTimePickerEdit"
              :placeholder="$t('label.chooseDateRange')" name="birthdate" class="form-control"></flat-pickr>
            <div class="error-message w-100 clear text-left">
              {{ msg["dateLeave"] ? $t(msg["dateLeave"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12">
          <label>{{ $t('label.leaveType') }}</label>
          <select v-model="leaveCodeEdit" class="form-control">
            <option v-for="lt in leaveTypes" v-bind:value="lt.value">
              {{ lt.text }}
            </option>
          </select>
          <div class="error-message w-100 clear text-left">
            {{ msg["leaveType"] ? $t(msg["leaveType"]) : "" }}
          </div>
        </div>
        <!--   <div class="col-lg-6 col-md-6 col-xs-12">
          <b-form-group
            id="input-group-4"
            :label="$t('label.shift')"
            class="required-mess"
          >
            <select v-model="shiftId" class="form-control">
              <option v-for="shift in shifts" v-bind:value="shift.id">
                {{ shift.label }}
              </option>
            </select>
            <div class="error-message w-100 clear text-left">
              {{ msg["shiftId"] ? $t(msg["shiftId"]) : "" }}
            </div>
          </b-form-group>
        </div> -->
        <div class="col-lg-12 col-md-12 col-xs-12">
          <b-form-group class="required-mess" id="input-group-2" :label="$t('label.reasonForOT')">
            <textarea class="form-control form-control-sm" id="tar-2" :placeholder="$t('label.reasonForOT')" required
              v-model="reasonForOT" rows="3"></textarea>
            <div class="error-message w-100 clear text-left">
              {{ msg["reason"] ? $t(msg["reason"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <!-- <div class="row col-12" v-for="(approval, i) in approveList" :key="i">
          <div class="col-12">
            <h3>{{ $t("label.approveStep") }} {{ i + 1 }}</h3>
          </div>
          <div class="col-6">
            <b-form-group :label="$t('label.jobPosition')">
              <treeselect v-model="approval.position" :multiple="false" :options="approval.positionOption"
                :default-expand-level="Infinity" @select="changePosition(i)"
                :noChildrenText="$t('label.noNodeChildren')" :placeholder="$t('label.choosePosition')" />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group :label="$t('label.approver')">
              <select class="form-control" v-model="approval.user">
                <option v-for="user in approval.listUser" v-bind:value="user.id">
                  {{ user.fullName }}
                </option>
              </select>
              <div class="error-message w-100 clear text-left">
                {{
                  msg["approveBy[" + i + "]"]
                    ? $t(msg["approveBy[" + i + "]"])
                    : ""
                }}
              </div>
            </b-form-group>
          </div>
        </div> -->
        <!-- <div class="col-12" >
          <b-button @click="addMoreApprove">{{
            $t("label.addApproveStep")
          }}</b-button> 
        </div>-->
      </b-form>
      <div class="d-block text-center">
        <div class="text-center">
          <b-button class="btn btn-danger" @click="editLeave">{{ $t("label.save") }}
          </b-button>
          <b-button class="btn btn-default" @click="hideModalInfoDetail">{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="modalDelete" ref="modal-delete" hide-header hide-footer header-bg-variant="secondary"
      modal-class="modal-delete text-center" centered size="md">
      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureLeave") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="deleteLeave()">{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="secondary" @click="hideModalDelete">{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>

</template>
<script lang="js">
import apiClient from '@/axios'
import apiServer from '@/axiosServer'
import moment from 'moment'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import PulseLoader from '@/components/PulseLoader.vue'
import FlatpickrLanguages from "flatpickr/dist/l10n"
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: { Treeselect, PulseLoader, flatPickr },
  data() {
    return {
      modalDelete: false,
      modalInfoDetail: false,
      userType: null,
      currentPageLeave: 1,
      perPageLeave: 25,
      loading: false,
      dataTable: [],
      perPage: 25,
      shiftsId: null,
      currentPage: 1,
      allSelected: false,
      totalRecord: 0,
      totalRecordOT: 0,
      nameEmployee: '',
      leaveCodeEdit: null,
      fields: [
        {
          label: "",
          key: "checkbox",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t('label.idEmployee'),
          key: 'aliasID',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t('label.fullName'),
          key: 'fullName',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t('label.jobPosition'),
          key: 'position',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t('label.department'),
          key: 'department',
          class: 'align-middle',
          sortable: false
        }
      ],
      userSelected: [],
      startDateString: '',
      endDateString: '',
      configDateTimePicker: {
        // minDate: "today",
        dateFormat: 'd/m/Y',
        time_24hr: true,
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true

      },
      configDateTimePickerEnddate: {
        enableTime: true,
        dateFormat: 'd/m/Y',
        // time_24hr: true,
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true

      },
      configTimePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
      },
      dateSelectSearch: "",
      configDateTimePickerSearch: {
        dateFormat: "d/m/Y",
        mode: "range",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true,
        onClose: function (selectedDates, dateStr, instance) {
          if (selectedDates.length == 1) {
            instance.setDate([selectedDates[0], selectedDates[0]], true);
          }
        },
      },
      nameResults: [],
      fullName: null,
      isOpenNameRes: false,
      dataTableLeave: [],
      fieldsHisotry: [
        {
          label: this.$t("label.idEmployee"),
          key: 'aliasID',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t("label.staffsName"),
          key: "fullName",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.departmentName"),
          key: "departmentName",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.reason"),
          key: "reason",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.timeDay"),
          key: "time",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.control"),
          key: "button",
          tdClass: "text-center",
          class: "align-middle",
          thClass: "text-center",
        },
      ],
      departmentid: null,
      shiftid: null,
      aliasId: null,
      departmentOption: [],
      shiftOption: [{
        "id": null,
        "label": 'Tất cả'
      },
      {
        "id": 0,
        "label": 'Chưa có ca'
      },
      {
        "id": 1,
        "label": 'Đã có ca'
      }],
      shiftsOptions: [],
      employeeId: null,
      idDelete: null,
      idPerson: "",
      minuteOT: null,
      LeaveId: null,
      reasonForOT: "",
      reasonForOTAdd: "",
      startTime: null,
      msg: [],
      endTime: null,
      dateSelect: "",
      startTimeAdd: null,
      endTimeAdd: null,
      leaveTypes: [],
      leaveType: '',
      configDateTimePickerEdit: {
        dateFormat: "d/m/Y",
        mode: "range",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true,
        onClose: function (selectedDates, dateStr, instance) {
          if (selectedDates.length == 1) {
            instance.setDate([selectedDates[0], selectedDates[0]], true);
          }
        },
      },
    }
  },
  watch: {
    startTime: function (newStartTime, oldStartTime) {
      this.calculateMinuteOT();
    },
    endTime: function (newEndTime, oldEndTime) {
      this.calculateMinuteOT();
    }
  },
  mounted() {
    this.userType = this.$store.getters.userType
    this.getDepartment();
    this.getEmployee();
    this.getShiftoption();
    this.getListLeave()
    this.startDateString = this.getNowDate()
    this.endDateString = this.getNowDate()
    this.getListLeaveType();
    if (this.$store.getters.locale == 'vi') {
      this.configDateTimePickerEdit.locale = FlatpickrLanguages["vn"];
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"]
    };
  },
  methods: {
    async getListLeaveType() {
      let res;
      try {
        res = await apiServer.post("/api/v1/leave-type/get-list", {
          type: 1
        });
        if (res && res.data.data.result) {
          this.leaveTypes = res.data.data.result.map(function (val, index) {
            return { value: val.code, text: val.code + " - " + val.name };
          });
        }
      } catch (error) {
        if (error.response.status === 400) {
          console.log(error.response.message);
        }
      }
    },
    calculateMinuteOT: function () {
      if (this.startTime && this.endTime) {
        const startMoment = moment(this.startTime, "HH:mm");
        const endMoment = moment(this.endTime, "HH:mm");
        let minuteOT = endMoment.diff(startMoment, "minutes");
        minuteOT = Math.max(0, minuteOT);
        this.minuteOT = minuteOT;
      }
    },
    calculateMinuteOTAdd(start, end) {
      if (start && end) {
        const startMoment = moment(start, "HH:mm");
        const endMoment = moment(end, "HH:mm");
        let minuteOT = endMoment.diff(startMoment, "minutes");
        minuteOT = Math.max(0, minuteOT);
        return minuteOT
      }
    },
    hideModalInfoDetail() {
      this.$refs["modal-info-detail"].hide();
    },
    hideModalDelete() {
      this.$refs["modal-delete"].hide();
    },
    showDelete(id) {
      this.idDelete = id
      console.log();
      this.modalDelete = true
    },
    async deleteLeave() {
      let res;
      try {
        res = await apiServer.post("/api/v1/Leave/delete", {
          id: this.idDelete,
        });
        if (res && res.data) {
          this.modalDelete = false;
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(res.data.message),
            position: "bottom-center",
          });
          await this.getListLeave();
        }
      } catch (e) {
        console.log(e);
      }
    },
    formatDate(dateString) {
      const dateParts = dateString.split('-');
      return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
    },

    async showInfoDetail(item) {
      console.log(item);
      this.dateSelect = `${this.formatDate(item.fromDate)} đến ${this.formatDate(item.toDate)}`;
      console.log(this.dateSelect);
      this.idPerson = item.fullName
      this.minuteOT = item.minute_ot;
      this.reasonForOT = item.reason;
      this.LeaveId = item.id;
      this.startTime = item.startTime
      this.leaveCodeEdit = item.leaveCode
      this.endTime = item.endTime
      this.modalInfoDetail = true;
    },
    async editLeave() {
      let res;
      let user_id =
        this.idPerson != null && this.idPerson != ""
          ? this.idPerson.value
          : null;

      this.loading = true;
      let df = null;
      let dt = null;
      if (this.dateSelect != undefined && this.dateSelect !== "") {
        let dateArr = this.dateSelect.split(" ");
        df = this.convertTimestampSendDate(dateArr[0]);
        if (dateArr.length == 1) {
          dt = df;
        } else {
          dt = this.convertTimestampSendDate(dateArr[2]);
        }
      }

      try {
        res = await apiServer.post("/api/v1/Leave/save", {
          id: this.LeaveId,
          aliasID: user_id,
          leaveCode: this.leaveCodeEdit,
          fromDate: df,
          toDate: dt,
          reason: this.reasonForOT.trim(),
        });
        if (res && res.data) {
          if (res.status === 200) {
            this.modalAddLeave = false;
            this.modalInfoDetail = false;
            this.$notify({
              group: "bottomnotification",
              title: this.$t("label.notification"),
              text: this.$t(res.data.message),
              position: "bottom-center",
            });
            await this.getListLeave();
          }
        }
      } catch (error) {
        this.loading = false;
        if (error.response.status === 400) {
          this.msg = error.response.data;
        }
        if (error.response.data["message"]) {
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notificationError"),
            text: this.$t(this.msg["message"]),
            type: "error",
          });
        }
      }
    },
    async getDepartment() {
      let res
      try {
        res = await apiServer.post('api/v1/department/list', {})
      } catch (error) { }
      if (res && res.data) {
        this.departmentOption = res.data;
      }
    },
    selectAll() {
      if (this.allSelected == true) {
        this.userSelected = this.dataTable.map((x) => x.aliasID);
      } else {
        this.userSelected = [];
      }
    },
    async getShiftoption() {
      let res
      try {
        res = await apiServer.post('/api/v1/shifts/get', {})
      } catch (error) { }
      if (res && res.data) {
        this.shiftsOptions = res.data;
      }
    },
    getNowDate() {
      let today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()
      today = dd + '/' + mm + '/' + yyyy
      return today
    },
    getTomorrowDate() {
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const dd = String(tomorrow.getDate()).padStart(2, '0');
      const mm = String(tomorrow.getMonth() + 1).padStart(2, '0');
      const yyyy = tomorrow.getFullYear();
      return `${dd}/${mm}/${yyyy}`;
    },
    convertTimestampSendDate(date) {
      if (date == undefined || date == null || date == '') {
        return null;
      }
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, "$3-$2-$1");
      var date = new Date(changedDate);
      return moment(date).format("yyyy-MM-DD");
    },
    handleHeaderClick(column) {
      this.clickedColumn = column;
      console.log('Bạn đã click vào header của cột:', column);
    },
    handleHeaderClickDetails(column) {
      this.clickedColumn = column;
      console.log('Bạn đã click vào header của cột:', column);
    },
    async getEmployee() {
      this.loading = true;
      let placeID = this.$store.getters.placeID;
      let dpid = (this.departmentid != null && this.departmentid != undefined) ?
        [this.departmentid] : null;
      this.dataTable = [];
      let res
      try {
        res = await apiServer.post('api/v1/user/get-list-user', {
          placeID: placeID,
          aliasID: this.aliasId,
          userName: this.userName,
          department: dpid,
          currentPage: this.currentPage,
          rowPerPage: this.perPage,
          hadAShift: this.shiftid
        })
      } catch (error) {
        this.loading = false;
      }
      if (res && res.data) {
        this.dataTable = res.data.data.results;
        this.totalRecord = res.data.data.totalRecord;
        this.loading = false;
      }
    },
    async addLeave() {

      if (this.leaveType == [] || this.leaveType == null) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: "Bạn cần chọn loại đơn",
          position: "bottom-center",
          type: "error",

        });
        return false;
      }
      if (this.startTimeAdd == null || this.endTimeAdd == null) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: "Bạn chưa chọn ngày",
          position: "bottom-center",
          type: "error",

        });
        return false;
      }
      if (this.userSelected.length == 0 || this.userSelected == undefined) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: "Chưa có nhân viên được chọn",
          position: "bottom-center",
          type: "error",

        });
        return false;
      }
      if (this.reasonForOTAdd == '' || this.endTimeAdd == null) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: "Bạn cần nhập lý do",
          position: "bottom-center",
          type: "error",

        });
        return false;
      }
      let res;
      try {
        res = await apiServer.post("api/v1/leave/save", {
          // dateOT: this.convertTimestampSendDate(this.startDateString),
          reason: this.reasonForOTAdd.trim(),
          aliasIDs: this.userSelected,
          fromDate: this.convertTimestampSendDate(this.startTimeAdd),
          toDate: this.convertTimestampSendDate(this.endTimeAdd),
          minuteOT: this.calculateMinuteOTAdd(this.startTimeAdd, this.endTimeAdd),
          id: null,
          leaveCode: this.leaveType
        });
      } catch (error) {
        if (error.response.data.message) {
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notificationError'),
            text: this.$t(error.response.data.message),
            type: 'error',
            position: 'bottom-center'
          });
        }
      }
      if (res && res.data) {
        this.userSelected = false;
        await this.getListLeave();
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
      }
      this.userSelected = [];
    },
    async getListLeave() {
      this.dataTableLeave = [];
      let res
      let df = null;
      let dt = null;
      if (this.dateSelectSearch != undefined && this.dateSelectSearch !== "") {
        let dateArr = this.dateSelectSearch.split(" ");
        df = this.convertTimestampSendDate(dateArr[0]);
        if (dateArr.length == 1) {
          dt = df;
        } else {
          dt = this.convertTimestampSendDate(dateArr[2]);
        }
      }
      try {
        res = await apiServer.post('api/v1/leave/get-list', {
          currentPage: this.currentPageLeave,
          perPage: this.perPageLeave,
          fromDate: df,
          toDate: dt,
          departmentId: this.departmentid,
          aliasID: this.aliasId,
          aliasIDRow: this.employeeId,
          type: 1


        })
      } catch (error) { }
      if (res && res.data) {
        this.dataTableLeave = res.data.data.result;
        this.totalRecordOT = res.data.data.totalRecords;
      }
    },
    handleClickOutside() {
      this.isOpenNameRes = false;
    },
    async onChangeName() {
      this.employeeId = null
      this.nameEmployee = ''
      this.isOpenNameRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.fullName,
          searchType: "name"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.nameResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.nameResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },
    setNameResult(result) {
      this.aliasId = result.value;
      this.fullName = result.text;
      this.employeeId = null
      this.isOpenNameRes = false;
      this.nameEmployee = ''
      setTimeout(() => { this.getEmployee(), this.getListLeave() }, 500);
    },
    changeDepartment() {
      this.employeeId = null
      this.nameEmployee = ''

      setTimeout(() => { this.getEmployee(), this.getListLeave() }, 500);
    },
    convertDate(inputDate) {
      if (inputDate == '' || inputDate == null) return;
      return moment(inputDate).format("DD/MM/yyyy HH:mm:ss");
    },
    rowClickHandler(record, index) {
      this.nameEmployee = record.fullName

      this.employeeId = record.aliasID
      this.getListLeave(this.employeeId);
    }
  }
}
</script>