<template>
  <div id="main-default" class="container-fluid">
    <div v-show="isInProgress" class="load-submit">
      <font-awesome-icon icon="spinner" />
    </div>
    <div class="card col-12">
      <div class="row info-user">
        <!-- <div class="col-12 col-md-3">
          <div class="info-user__image">
            <span><img :src="avatarUser" alt="" /></span>
          </div>
        </div> -->
        <div class="col-12 col-md-9 ml-2 info-user-desc">
          <div style="width: 100%">
            <div class="info-user-desc--title">
              <h2>{{ nameUser }}</h2>
              <p style="margin-bottom: 0px">Email: {{ emailUser }}</p>
              <p style="margin-bottom: 0px">
                {{ $t("label.phoneNumber") }}: {{ phoneNumberUser }}
              </p>
              <p style="margin-bottom: 0px">
                {{ $t("label.company") }} : {{ companyUser }}
              </p>
              <p>{{ $t("label.branch") }} : {{ branchName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card col-12">
      <h3 class="mt-2">{{ $t("label.staffInformation") }}</h3>
      <div>
        <div class="information-user-collapse">
          <!-- <div v-b-toggle:my-collapse class="information-user-collapse__title">
            <span class="when-open"
              ><font-awesome-icon icon="caret-down" /></span
            ><span class="when-closed"
              ><font-awesome-icon icon="caret-right"
            /></span>
            {{ $t("label.mainInformation") }}
          </div> -->
          <b-collapse
            id="my-collapse"
            visible
            class="information-user-collapse__body"
          >
            <div class="row">
              <div class="col-6 col-md-3">
                <b-form-group class="required-mess" :label="$t('label.name')">
                  <b-form-input v-model="nameUser"></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{
                      msg["fullName"] && msg["fullName"][0]
                        ? $t(msg["fullName"][0])
                        : ""
                    }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group
                  class="required-mess"
                  :label="$t('label.userName')"
                >
                  <b-form-input
                    v-model="userName"
                    required
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group label="Email">
                  <b-form-input v-model="emailUser"></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{
                      msg["email"] && msg["email"][0] ? $t(msg["email"][0]) : ""
                    }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group :label="$t('label.phoneNumber')">
                  <b-form-input v-model="phoneNumberUser"></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{
                      msg["phone"] && msg["phone"][0] ? $t(msg["phone"][0]) : ""
                    }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group :label="$t('label.gender')">
                  <select v-model="genderUser" class="form-control">
                    <option
                      v-for="gender in genders"
                      v-bind:value="gender.value"
                    >
                      {{ gender.text }}
                    </option>
                  </select>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group :label="$t('label.address')">
                  <b-form-input v-model="addressUser"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group :label="$t('label.dateOfBirth')">
                  <flat-pickr
                    v-model="dateOfBirthUser"
                    :config="configDateTimePicker"
                    name="birthDate"
                    class="form-control"
                  ></flat-pickr>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group :label="$t('label.idCard')">
                  <b-form-input
                    type="number"
                    v-model="idCardUser"
                    required
                  ></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{
                      msg["cardID"] && msg["cardID"][0]
                        ? $t(msg["cardID"][0])
                        : ""
                    }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group :label="$t('label.userType')">
                  <select v-model="userType" class="form-control">
                    <!-- <option v-bind:value="0">{{ $t("label.employee") }}</option> -->
                    <option v-bind:value="3">{{ $t("label.manager") }}</option>
                    <option v-bind:value="2">{{ $t("label.admin") }}</option>
                  </select>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3" v-if="isSupperAdmin">
                <b-form-group
                  class="required-mess"
                  :label="$t('label.company')"
                >
                  <select
                    v-model="companyId"
                    class="form-control"
                    @change="changeCompany"
                    required
                  >
                    <option v-for="cp in companies" v-bind:value="cp.value">
                      {{ cp.text }}
                    </option>
                  </select>
                  <div class="error-message w-100 clear text-left">
                    {{ msg["company"] ? $t(msg["company"][0]) : "" }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3" v-if="isSupperAdmin || isComAdmin">
                <b-form-group class="required-mess" :label="$t('label.branch')">
                  <select
                    v-model="branchUser"
                    class="form-control"
                    @change="changeBranch"
                    required
                  >
                    <option
                      v-for="branchByUser in branchByUsers"
                      v-bind:value="branchByUser.value"
                    >
                      {{ branchByUser.text }}
                    </option>
                  </select>
                  <div class="error-message w-100 clear text-left">
                    {{
                      msg["branch"] && msg["branch"][0]
                        ? $t(msg["branch"][0])
                        : ""
                    }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group :label="$t('label.department')">
                  <treeselect
                    v-model="departmentUser"
                    :multiple="false"
                    :options="departmentOption"
                    :default-expand-level="Infinity"
                    :noChildrenText="$t('label.noNodeChildren')"
                    :placeholder="$t('label.chooseDepartment')"
                  />
                  <div class="error-message w-100 clear text-left">
                    {{ msg["department"] ? $t(msg["department"][0]) : "" }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3" v-if="isSupperAdmin || isComAdmin">
                <b-form-group :label="$t('label.groupUser')">
                  <select v-model="groupId" class="form-control">
                    <option v-for="gu in groupUsers" v-bind:value="gu.id">
                      {{ gu.group_name }}
                    </option>
                  </select>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3" v-if="isSupperAdmin || isComAdmin">
                <b-form-group :label="$t('label.role')" class="required-mess">
                  <select v-model="userRole" class="form-control" required>
                    <option v-for="r in roles" v-bind:value="r.value">
                      {{ r.text }}
                    </option>
                  </select>
                  <div class="error-message w-100 clear text-left">
                    {{ msg["role"] ? $t(msg["role"]) : "" }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group :label="$t('label.jobPosition')">
                  <treeselect
                    v-model="jobPositionUser"
                    :multiple="false"
                    :options="positionOption"
                    :default-expand-level="Infinity"
                    :noChildrenText="$t('label.noNodeChildren')"
                    :placeholder="$t('label.choosePosition')"
                  />
                  <div class="error-message w-100 clear text-left">
                    {{ msg["office"] ? $t(msg["office"][0]) : "" }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group
                  :label="$t('label.employeeCode')"
                  class="required-mess"
                >
                  <b-form-input type="text" v-model="aliasID" disabled></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{
                      msg["aliasID"] && msg["aliasID"][0]
                        ? $t(msg["aliasID"][0])
                        : ""
                    }}
                  </div>
                </b-form-group>
              </div>
              <!-- <div class="col-6 col-md-3">
                <b-form-group :label="$t('label.checkInCode')">
                  <b-form-input
                    type="text"
                    v-model="idCheckIn"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div> -->
              <div class="col-6 col-md-3">
                <b-form-group
                  :label="$t('label.dateStartWork')"
                  class="required-mess"
                >
                  <flat-pickr
                    v-model="dateStartWorkUser"
                    :config="configDateTimePicker"
                    name="dateStartWorkUser"
                    class="form-control"
                  ></flat-pickr>
                  <div class="error-message w-100 clear text-left">
                    {{
                      msg["dateStartWork"] && msg["dateStartWork"][0]
                        ? $t(msg["dateStartWork"][0])
                        : ""
                    }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group :label="$t('label.annualLeave')">
                  <b-form-input
                    v-model="annualLeave"
                    type="number"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group :label="$t('label.bankAccountNumber')">
                  <b-form-input v-model="bankAccountNumberUser"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-6 col-md-3">
                <b-form-group :label="$t('label.shift')">
                  <treeselect
                  disabled
                    v-model="shiftid"
                    :multiple="true"
                    :options="shifts"
                    :flat="true"
                    :default-expand-level="1"
                    :placeholder="$t('label.chooseShift')"
                  />
                </b-form-group>
              </div>
            </div>
              <!-- <div class="col-6 col-md-3">
                <b-form-group :label="$t('label.asACollaborator')">
                  <select v-model="asACollaboratorUser" class="form-control">
                    <option
                      v-for="asACollaborator in asACollaborators"
                      v-bind:value="asACollaborator.value"
                    >
                      {{ asACollaborator.text }}
                    </option>
                  </select>
                </b-form-group>
              </div> -->
              <!-- <div class="col-12 col-md-6">
                <b-form-group :label="$t('label.shift')">
                  <treeselect
                  disabled
                    v-model="shiftid"
                    :multiple="true"
                    :options="shifts"
                    :flat="true"
                    :default-expand-level="1"
                    :placeholder="$t('label.chooseShift')"
                  />
                </b-form-group>
              </div>
            </div> -->
          </b-collapse>
        </div>
        <!-- <div class="information-user-collapse">
          <div v-b-toggle:my-collapse2 class="information-user-collapse__title">
            <span class="when-open"
              ><font-awesome-icon icon="caret-down" /></span
            ><span class="when-closed"
              ><font-awesome-icon icon="caret-right"
            /></span>
            {{ $t("label.personalImage") }}
          </div>
          <b-collapse
            id="my-collapse2"
            visible
            class="information-user-collapse__body"
          >
            <div class="row">
              <div id="preview">
                <img
                  style="
                    height: 100px;
                    margin: 15px;
                    border-radius: 5px;
                    width: 100px;
                  "
                  v-if="newAvatar"
                  :src="newAvatar"
                />
                <img
                  style="
                    height: 100px;
                    margin: 15px;
                    border-radius: 5px;
                    width: 100px;
                  "
                  v-else="avatarUser"
                  :src="avatarUser"
                />
              </div>
              <div class="upload-images">
                <label for="insurance_policy" class="upload-images__hidden">
                  <input
                    type="file"
                    style="
                      display: block;
                      width: 100px;
                      height: 100px !important;
                      width: 100px !important;
                    "
                    name="insurance_policy"
                    multiple="multiple"
                    required
                    class="upload-file"
                    id="insurance_policy"
                    accept=".png, .jpg, .jpeg, .pdf"
                    v-on:change="updateAvatarUser($event.target.files)"
                  />
                </label>

                <div class="upload-images--show">
                  <div class="text-center">
                    <font-awesome-icon icon="plus" />
                    <p>{{ $t("label.upload") }}</p>
                  </div>
                </div>
              </div>
              <div class="error-message w-100 clear text-left">
                {{
                  msg["avatar"] && msg["avatar"][0] ? $t(msg["avatar"][0]) : ""
                }}
              </div>
            </div>
          </b-collapse>
        </div> -->
        <div class="submit-form mt-4 mb-4 text-center">
          <button
            type="submit"
            style="background: #fb6340; color: #fff"
            @click="updateProfile()"
            class="btn"
          >
            {{ $t("label.update") }}
          </button>
          <button type="button" class="btn btn-default" @click="$router.go(-1)">
            {{ $t("label.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiServer from "@/axiosServer";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import FlatpickrLanguages from "flatpickr/dist/l10n";
import Treeselect from "@riophae/vue-treeselect";
import moment from "moment";
export default {
  components: {
    flatPickr,
    Treeselect,
  },
  data() {
    return {
      isSupperAdmin: false,
      isComAdmin: false,
      idUser: this.$route.params.userId,
      aliasID: "",
      idCheckIn: "",
      nameUser: "",
      avatarUser: "",
      avatarUserUpdate: "",
      newAvatar: "",
      emailUser: "",
      phoneNumberUser: "",
      companyUser: null,
      companyId: null,
      departmentUser: null,
      branchUser: null,
      branchName: "",
      groupUsers: [],
      groupId: null,
      userRole: 2,
      genderUser: "0",
      genders: [
        { text: this.$t("label.male"), value: "1" },
        { text: this.$t("label.female"), value: "0" },
      ],
      asACollaboratorUser: "0",
      asACollaborators: [
        { text: this.$t("label.yes"), value: "1" },
        { text: this.$t("label.no"), value: "0" },
      ],
      addressUser: "",
      dateOfBirthUser: "",
      configDateTimePicker: {
        dateFormat: "d/m/Y",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true,
      },
      idCardUser: null,
      jobPositionUser: null,
      dateStartWorkUser: "",
      bankAccountNumberUser: "",
      urlImage: null,
      departmentByUsers: [{ text: "Phòng 1", value: "phong1" }],
      companies: [],
      branchByUsers: [],
      msg: [],
      isInProgress: false,
      userName: "",
      roles: [],
      departmentOption: null,
      positionOption: null,
      userType: null,
      annualLeave: null,
      shiftid: null,
      shifts: [],
    };
  },
  mounted() {
    this.getProfile();
    if (this.$store.getters.superAdmin == true) {
      this.isSupperAdmin = true;
      this.getCompany();
      this.roles.push({ text: this.$t("label.adminCompany"), value: 9 });
      this.roles.push({ text: this.$t("label.adminBranch"), value: 1 });
    }
    if (this.$store.getters.comAdmin == true) {
      this.isComAdmin = true;
      this.roles.push({ text: this.$t("label.adminBranch"), value: 1 });
    }
    this.roles.push({ text: this.$t("label.employee"), value: 2 });
    if (this.$store.getters.locale == "vi") {
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"];
    }
    this.getListShifts();
  },
  methods: {
    convertDate(inputDate) {
      if (inputDate == undefined || inputDate == null) return "";
      return moment(inputDate).format("DD/MM/yyyy");
    },
    async getProfile() {
      let res;
      try {
        this.isInProgress = true;
        res = await apiServer.post("api/v1/user/detail", {
          id: this.$route.params.userId,
        });
      } catch (error) {
      } finally {
        this.isInProgress = false;
      }
      if (res && res.data) {
        if (res.status === 200) {
          this.aliasID = res.data[0].aliasID;
          this.avatarUser = res.data[0].avatar;
          this.nameUser = res.data[0].fullName;
          this.idCheckIn = res.data[0].personID;
          this.branchName = res.data[0].branch_name;
          this.emailUser = res.data[0].email;
          this.phoneNumberUser = res.data[0].phone;
          this.companyUser = res.data[0].company;
          this.companyId = res.data[0].companyId;
          this.departmentUser = res.data[0].department;
          this.genderUser = res.data[0].gender;
          this.asACollaboratorUser = res.data[0].isCollaborator;
          this.jobPositionUser = res.data[0].office;
          this.idCardUser = res.data[0].cardID;
          this.dateStartWorkUser = this.convertDate(res.data[0].dateStartWork);
          this.dateOfBirthUser = this.convertDate(res.data[0].date_of_birth);
          this.addressUser = res.data[0].address;
          this.bankAccountNumberUser = res.data[0].bankNumber;
          this.userName = res.data[0].username;
          this.branchUser = res.data[0].placeID;
          this.userRole = res.data[0].role_id;
          this.groupId = res.data[0].group_id;
          this.annualLeave = res.data[0].num_of_leave;
          this.userType = res.data[0].user_type;
          this.shiftid = res.data[0].shiftid;
          if (this.isSupperAdmin || this.isComAdmin) {
            this.getBranch();
          }
          this.getDepartment();
          this.getPosition();
          this.getGroupUser();
        }
      }
    },
    async getCompany() {
      let res;
      try {
        res = await apiServer.post("/api/v1/company/get-list", {});
      } catch (error) {}
      if (res && res.data) {
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.companies.push({
              text: res.data[i].name,
              value: res.data[i].id,
            });
          }
        }
      }
    },
    async getListShifts() {
      this.shifts = [];
      let res;
      try {
        res = await apiServer.post("api/v1/shifts/get", {});
      } catch (error) {}
      if (res && res.data) {
        this.shifts = res.data
        // if (res.data.length > 0) {
        //   for (let i = 0; i < res.data.length; i++) {
        //     this.shifts.push({
        //       id: res.data[i].shift_id,
        //       pid: 0,
        //       name: res.data[i].shifts_name,
        //       label: res.data[i].shifts_name,
        //     });
        //   }
        // }
      }
    },
    async updateProfile() {
      this.msg = [];
      let res;
      try {
        this.isInProgress = true;
        const config = {
          header: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 0,
        };
        let dob =
          this.dateOfBirthUser != undefined && this.dateOfBirthUser != ""
            ? this.convertTimestampSendDate(this.dateOfBirthUser)
            : null;
        let dateStartWork =
          this.dateStartWorkUser != undefined && this.dateStartWorkUser != ""
            ? this.convertTimestampSendDate(this.dateStartWorkUser)
            : null;
        if (this.shiftid && this.shiftid.length == 0) {
          this.shiftid = null;
        }
        const formData = new FormData();
        formData.append("id", this.idUser);
        formData.append("aliasID", this.aliasID);
        //formData.append("file", this.avatarUserUpdate);
        formData.append("fullName", this.nameUser);
        // formData.append("branchID", this.branchUser);
        formData.append("roleId", this.userRole);
        formData.append("dateBirthDay", dob);
        formData.append("gender", this.genderUser);
        formData.append("cardID", this.idCardUser);
        formData.append("address", this.addressUser);
        formData.append("phone", this.phoneNumberUser);
        formData.append("email", this.emailUser);
        // formData.append("company", this.companyId);
        formData.append("department", this.departmentUser);
        formData.append("office", this.jobPositionUser);
        // formData.append("isCollaborator", this.asACollaboratorUser);
        formData.append("dateStartWork", dateStartWork);
        formData.append("bankNumber", this.bankAccountNumberUser);
        // formData.append("groupId", this.groupId);
        formData.append("annualLeave", this.annualLeave);
        formData.append("userType", this.userType);
        formData.append("shiftid", this.shiftid);
        res = await apiServer.post("api/v1/user/update", formData, config);
      } catch (error) {
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notificationError'),
            text: "Cập nhập thất bại",
            type: 'error',
            position: 'bottom-center'
          });
      } finally {
        this.isInProgress = false;
      }
      if (res && res.data) {
        if (res.status === 200) {
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(res.data.message),
          });
          this.$router.replace("/admin/employee-manager");
        }
      }
    },
    changeCompany() {
      if (this.companyUser != null) {
        this.getBranch();
        this.branchByUsers = null;
        this.departmentUser = null;
      }
    },
    async getBranch() {
      let res;
      try {
        res = await apiServer.post("api/v1/company/get-all-branch", {
          company: this.companyId,
        });
      } catch (error) {}
      if (res && res.data) {
        this.branchByUsers = [];
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.branchByUsers.push({
              text: res.data[i].branch_name,
              value: res.data[i].placeID,
            });
          }
        }
      }
    },
    changeBranch() {
      if (this.branchUser != null) {
        this.getDepartment();
        this.getPosition();
        this.getGroupUser();
        this.departmentUser = null;
        this.jobPositionUser = null;
        this.groupId = null;
      }
    },
    async getDepartment() {
      let res;
      try {
        res = await apiServer.post("api/v1/department/list", {
          companyId: this.companyId,
          placeId: this.branchUser,
        });
      } catch (error) {}
      if (res && res.data) {
        this.departmentOption = [];
        if (res.data.length > 0) {
          this.departmentOption = res.data;
        }
      }
    },
    async getPosition() {
      let res;
      try {
        res = await apiServer.post("api/v1/position/list", {
          companyId: this.companyId,
          placeId: this.branchUser,
        });
      } catch (error) {}
      if (res && res.data) {
        this.positionOption = [];
        if (res.data.length > 0) {
          this.positionOption = res.data;
        }
      }
    },
    async getGroupUser() {
      let res;
      try {
        res = await apiServer.post("api/v1/group-user/list", {
          placeId: this.branchUser,
        });
      } catch (error) {}
      if (res && res.data) {
        this.groupUsers = [];
        if (res.data.length > 0) {
          this.groupUsers = res.data;
        }
      }
    },
    updateAvatarUser(file) {
      this.avatarUserUpdate = file[0];
      this.newAvatar = URL.createObjectURL(file[0]);
    },
    convertTimestampSendDate(date) {
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, "$3-$2-$1");
      var date = new Date(changedDate);
      return moment(date).format("DD-MM-yyyy");
    },
  },
};
</script>
<style scoped lang="scss">
.info-user {
  .info-user__image {
    span {
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      padding: 10px;
      display: inline-block;
      margin: 20px 0;
      border-radius: 5px;
    }
  }
  .info-user-desc {
    display: flex;
    align-items: center;
    .info-user-desc--more-info {
      ul {
        padding: 0;
        margin: 0;
        width: 100%;
      }
    }
  }
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.information-user-collapse {
  border-bottom: 1px solid #ddd;
  .information-user-collapse__title {
    outline: none;
    cursor: pointer;
    padding: 10px;
    svg {
      margin-right: 10px;
    }
  }
  .information-user-collapse__body {
    padding: 10px;
    .col-form-label {
      font-weight: bold;
    }
    .image-avatar-user {
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      padding: 10px;
      display: inline-block;
      margin: 20px 0;
      border-radius: 5px;
    }
  }
}
.upload-images {
  position: relative;
  display: inline-block;
  margin: 15px 0 30px;
  cursor: pointer;
  .upload-images__hidden {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    .custom-file {
      height: 100%;
      .custom-file-label {
        height: 100%;
      }
    }
  }
  .upload-images--show {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    p {
      margin: 0;
    }
  }
  &:hover {
    .upload-images--show {
      border: 1px dashed #fb6340;
    }
  }
}
</style>
<style lang="scss">
.information-user-collapse__body {
  .col-form-label {
    font-weight: bold;
  }
}
.custom-file-label {
  height: 100%;
  cursor: pointer;
}
.form-control[readonly] {
  background: #fff !important;
}
</style>
