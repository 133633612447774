import CancelMeal from "@/views/admin/CancelMeal.vue";

export default {
  menu: {
    dashboard: "Bảng điều khiển",
    inOutHistory: "Xem lịch sử ra vào",
    employeeManager: "Quản lý nhân viên",
    attendanceManagement: "Bảng chấm công",
    checkIn: "Quản lý chấm công",
    showListShift: "Quản lý ca làm việc",
    arrangeShifts: "Xếp ca",
    managementLeave: "Quản lý chế độ",
    dataManagement: "Quản lý dữ liệu",
    branchManagement: "Quản lý chi nhánh",
    departmentManager: "Quản lý phòng ban",
    reportTimeKeep: "Báo cáo chấm công",
    dayOff: "Thiết lập ngày nghỉ",
    overTime: "Quản lý tăng ca",
    systemSettings: "Thiết lập hệ thống",
    workSchedule: "Thiết lập lịch làm việc",
    deviceSchedule: "Thiết lập máy chấm công",
    fingerManager: "Quản lý vân tay",
    deviceDetails: "Chi tiết thiết bị",
    timeManagement: "Quản lý thời gian",
    welcome: "Trang chào",
    timekeepingManagement: "Quản lý chấm công",
    companyManager: "Quản lý công ty",
    leaveTypeManagement: "Quản lý CT, HH, TH...",
    leaveManagement: "Các lý do khác",
    positionManagement: "Quản lý chức vụ",
    approveLeave: "Duyệt đơn báo",
    groupUserManagement: "Quản lý nhóm nhân sự",
    managementOT: "Quản lý tăng ca",
    approveOvertime: "Duyệt đơn tăng ca",
    shiftHistoryManagement: "Quản lý lịch sử ca làm việc",
    mealRequest: "Đăng ký suất ăn",
    notifyMeal: "Thông báo suất ăn",
    deviceManagement: "Quản lý thiết bị",
    attendanceData: "Nhập dữ liệu chấm công",
    fileUpload: "Quản lí tệp tin",
    cancelMeal: "Quản lí cắt cơm ca",
    report: "Báo cáo",
    claimtimesheetlist: "Giải trình chấm công "
  },
  title: {
    login:
      "Đăng nhập :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    logout:
      "Đăng xuất :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    admin:
      "Bảng điều khiển :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    InOutHistory:
      "Lịch sử ra vào :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    attendanceManagement:
      "Bảng chấm công :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    showListShift:
      "Quản lý ca làm việc :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    staffInShift:
      "Nhân viên trong ca :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    arrangeWorkShift:
      "Xếp ca :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    branchManagement:
      "Quản lý chi nhánh :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    departmentManager:
      "Quản lý phòng ban :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    employeeManager:
      "Quản lý nhân viên :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    leaveApplication:
      "Quản lý chế độ :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    leaveType:
      "Quản lý loại nghỉ phép :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    CheckIn:
      "Quản lý chấm công :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    cancelMeal:
      "Đăng kí hủy cơm ca",
    user: "Thành viên :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    managementLeave: "Quản lý chế độ",
    overtimeList: "Danh sách nhân viên tăng ca",
    leaves: "Danh sách nghỉ phép",
    setHoliday: "Thiết lập ngày lễ",
    compensationWorkDay: "Duyệt bù công",
    reportSetup: "Thiết lập báo cáo",
    homePage:
      "DRC x Viettel Construction :: Giải pháp Camera thông minh, chấm công nhận diện khuôn mặt",
    claimTimeSheetList: "Danh sách đơn duyệt bù công",
    welcome:
      "DRC x Viettel Construction :: Smart reception Robot, AI Camera | Giải pháp chấm công nhận diện khuôn mặt",
    download: "Tải xuống",
    companyManager: "Quản lý công ty",
    leaveTypeManagement: "Quản lý CT, HH, TH...",
    groupUserManagement: "Quản lý nhóm nhân sự",
    managementOvertime: "Quản lý tăng ca",
    shiftHistoryManagement: "Quản lý lịch sử ca làm việc",
    deviceManagement: "Quản lý thiết bị",
    deviceDetails: "Chi tiết thiết bị",
    uncheckin: "Chưa checkin",
    checkinLate: "Đi muộn",
    checkedin: "Đã checkin",
    waitingApproveLeave: "Đơn chờ duyệt",
    waitingApproveOvertime: "Đơn chờ duyệt",
    leaveNotice: "Đơn báo",
    overtimeNotice: "Đơn tăng ca",
    mealNotify: "Thông báo suất ăn",
    mealRequest: "Đăng ký suất ăn",
    attendanceData: "Nhập dữ liệu chấm công",
    workSchedule: "Thiết lập lịch làm việc",
    deviceSchedule: "Thiết lập máy chấm công",
    fingerManager: "Quản lý vân tay"
  },

  validate: {
    message: {
      accountRequired: "Vui lòng nhập tài khoản",
      existPhone: "Số điện thoại đã có trong hệ thống",
      placeIdRequired: "Mã chi nhánh không được để trống",
      placeAddressRequired: "Địa chỉ chi nhánh không được để trống",
      placeNameRequired: "Tên chi nhánh không được để trống",
      fullNameRequired: "Tên người dùng không được để trống",
      cardIDInvalid: "Số chứng minh thư không đúng định dạng",
      phoneInvalid: "Số điện thoại không đúng định dạng",
      phoneRequired: "Số điện thoại không được để trống",
      emailRequired: "Email không được để trống",
      startWorkRequired: "Ngày bắt đầu làm việc không được để trống",
      avatarRequired: "Ảnh đại diện không được để trống",
      placeRequired: "Chi nhánh không được để trống",
      departmentRequired: "Phòng ban không được để trống",
      aliasIdRequired: "Mã nhân viên không được để trống",
      passwordRequired: "Vui lòng nhập mật khẩu",
      confirmPassRequired: "Xác nhận mật khẩu không được để trống",
      oldPasswordRequired: "Mật khẩu cũ không được để trống",
      staffRequired: "Nhân viên không được để trống",
      leaveRequired: "Chọn ngày nghỉ",
      leaveNameRequired: "Tên ngày nghỉ không được để trống",
      startLeaveRequired: "Ngày bắt đầu nghỉ không được để trống",
      endLeaveRequired: "Ngày kết thúc nghỉ không được để trống",
      shiftNameRequired: "Tên ca làm việc không được để trống",
      shiftNameMaxLength: "Tên ca làm việc không được quá 50 ký tự",
      workDayRequired: "Số ngày công làm việc không được để trống",
      workDayInvalid: "Số ngày công làm việc không đúng định dạng",
      minuteOvertimeInvalid: "Số phút làm thêm không đúng định dạng",
      startWorkTimeRequired:
        "Thời gian bắt đầu ca làm việc không được để trống",
      endWorkTimeRequired: "Thời gian kết thúc ca làm việc không được để trống",
      startRestTimeRequired:
        "Thời gian bắt đầu nghỉ giữa ca không được để trống",
      endRestTimeRequired:
        "Thời gian kết thúc nghỉ giữa ca không được để trống",
      leaveHalfRequired: "Vui lòng chọn nghỉ nửa ca",
      userRequired: "Người dùng không được để trống",
      compensationDateRequired: "Ngày bù công không được để trống",
      compensationReasonRequired: "Lý do bù công không được để trống",
      userNameRequired: "Tên đăng nhập không được để trống",
      companyNameRequired: "Tên công ty không được để trống",
      companyAddressRequired: "Địa chỉ công ty không được để trống",
      taxcodeRequired: "Mã số thuế không được để trống",
      companyRequired: "Công ty không được để trống",
      positionRequired: "Chức vụ không được để trống",
      confirmPasswordRequired: "Vui lòng xác nhận mật khẩu",
      approverRequired: "Người duyệt không được để trống",
      tokenHanetRequired: "Hanet token không được để trống",
      noUserSelected: "Bạn chưa chọn nhân viên",
      minuteOvertimeLimit: "Số phút làm thêm không được quá 200 phút",
      noDepartmentSelected: "Bạn chưa chọn phòng ban",
      branchIdRequired: "Chọn chi nhánh",
      codeRequired: "Nhập mã",
      nameRequired: "Nhập tên",
      leaveTypeRequired: "Chọn loại đơn báo",
      groupNameRequired: "Nhập tên nhóm",
      dateIDRequired: "Ngày làm việc không được để trống",
      checkinRequired: "Giờ vào ca không được để trống",
      checkoutRequired: "Giờ tan ca không được để trống",
      dayOfWeekRequired: "Chọn ngày đăng ký",
      quantityRequired: "Nhập số lượng",
      deviceCodeRequired: "Chọn thiết bị",
      deviceNameRequired: "Nhập tên thiết bị",
      deviceTypeRequired: "Chọn loại thiết bị",
      userApproveRequired: "Chọn người duyệt",
      userApproveInvalid: "Người duyệt không hợp lệ",
      confirmPasswordNotMatch: "Mật khẩu xác nhận không khớp",
      reasonRequired: "Nhập lý do",
      minuteOvertimeRequired: "Nhập số phút làm thêm",
      dateOvertimeRequired: "Chọn ngày làm thêm",
      shiftIdRequired: "Chọn ca làm việc",
      approveByRequired: "Chọn người duyệt",
      quantityInvalid: "Số lượng không hợp lệ",
    },
  },

  error: {
    message: {
      registerFingerFailed: "Đăng ký vân tay không thành công",
      getFingerFromDeviceFailed: "Lấy vân tay thất bại",
      getLastestFingerFailed: "Lấy vân tay thất bại, vui lòng thực hiện lại",
      accountOrPassIncorrect: "Tài khoản hoặc mật khẩu không đúng",
      dbError: "Lỗi truy vấn cơ sở dữ liệu",
      general: "Lỗi",
      uploadPicture: "Lỗi tải ảnh",
      imageInvalid:
        "Hình ảnh không hợp lệ. Vui lòng đảm bảo hình ảnh rõ nét, chỉ có 1 người, đầy đủ mắt, mũi, miệng, nhìn thẳng vào camera, không đội mũ, đeo khẩu trang",
      tokenHanetNull: "Hanet token bị thiếu",
      invalidId: "Id không hợp lệ",
      userNotExist: "Nhân viên không tồn tại",
      userLimit:
        "Không thể tạo thêm FaceID, nơi này đã đạt giới hạn 15/15 Face ID",
      userExisted: "Nhân viên đã tồn tại",
      aliasIdExisted: "Mã nhân viên đã tồn tại",
      passwordNotMatch: "Mật khẩu không khớp",
      shiftNotExist: "Ca làm việc không tồn tại",
      leaveNotExist: "Không tồn tại nghỉ phép này",
      noUserFound: "Không có nhân viên nào",
      dateRangeLessThan31: "Khoảng ngày không lớn hơn 31 ngày",
      shiftNameExisted: "Tên ca làm việc đã có trong hệ thống",
      contactNotExist: "Liên hệ không tồn tại",
      oldPasswordIncorrect: "Mật khẩu cũ không đúng",
      aliasIDAlreadyExisted: "Mã nhân viên đã tồn tại",
      userNameExisted: "Tên đăng nhập đã tồn tại",
      leaveTypeExist: "Loại đơn báo đã tồn tại",
      deviceExist: "Thiết bị đã tồn tại",
      groupExisted: "Nhóm đã tồn tại",
      userApproveDuplicate: "Trùng người duyệt",
      userApproveEmpty: "Không có người duyệt",
      noSelectLeave: "Vui lòng chọn bản ghi duyệt hàng loạt",
      noSelectOvertime: "Vui lòng chọn bản ghi duyệt hàng loạt",
      noSelectExcelFile: "Vui lòng chọn file excel",
      noSelectFile: "Vui lòng chọn file",
      invalidFile: "File không hợp lệ",
      userNotFound: "Không tìm thấy nhân viên",
      userNotInShift: "Nhân viên không có trong ca làm việc",
      mealExists: "Xuất ăn đã tồn tại",
      workTimeInvalid: "Thời gian làm việc không hợp lệ",
      restTimeInvalid: "Thời gian nghỉ giữa ca không hợp lệ",
    },
  },

  notify: {
    message: {
      syncFaceByDeviceSuccessful: "Đồng bộ thành công",
      getFingerFromDeviceSuccessful: "Lấy vân tay thành công",
      success: "Thành công",
      removeFaceSuccessful: "Đã xóa vân tay/khuôn mặt",
      removeAllFaceSuccessful: "Đã xóa tất cả vân tay/khuôn mặt trong thiết bị",
      getLastestFingerFailed: "Lấy vân tay thất bại, vui lòng thực hiện lại",
      registerFingerSuccessful: "Đăng ký vân tay thành công", 
      getLastestFingerSuccessful: "Lấy vân tay thành công, nhấn đăng ký để hoàn tất",
      addUserToShiftSuccess: "Thêm nhân viên vào ca thành công",
      removeUserShiftSuccess: "Xoá nhân viên trong ca thành công",
      createdAccountSuccess: "Tạo tài khoản thành công",
      updatedSuccess: "Cập nhật thành công",
      deletedSuccess: "Xoá thành công",
      changePassSuccess: "Thay đổi mật khẩu thành công",
      deleteShiftSuccess: "Xoá ca làm việc thành công",
      addLeaveSuccess: "Thêm ngày nghỉ thành công",
      approveLeaveSuccess: "Duyệt ngày nghỉ thành công",
      deleteLeaveSuccess: "Xoá ngày nghỉ thành công",
      updateShiftSuccess: "Sửa ca làm việc thành công",
      addShiftSuccess: "Thêm mới ca làm việc thành công",
      addBranchSuccess: "Thêm mới chi nhánh thành công",
      updateBranchSuccess: "Sửa chi nhánh thành công",
      deleteBranchSuccess: "Xóa chi nhánh thành công",
      addContactSuccess: "Thêm liên hệ thành công",
      deleteContactSuccess: "Xoá liên hệ thành công",
      addCompensationSuccess: "Thêm bù công thành công",
      approveCompensationSuccess: "Duyệt bù công thành công",
      addCompanySuccess: "Thêm công ty thành công",
      updateCompanySuccess: "Chỉnh sửa thông tin công ty thành công",
      importUserSuccess: "Import nhân viên thành công",
      addDepartmentSuccess: "Thêm phòng ban thành công",
      editDepartmentSuccess: "Sửa phòng ban thành công",
      deleteDepartmentSuccess: "Xoá phòng ban thành công",
      moveDepartmentSuccess: "Chuyển phòng ban thành công",
      changeUserDepartment: "Chuyển phòng ban nhân viên thành công",
      addLeaveTypeSuccess: "Thêm loại đơn báo thành công",
      editLeaveTypeSuccess: "Sửa loại đơn báo thành công",
      deleteLeaveTypeSuccess: "Xoá loại đơn báo thành công",
      addPositionSuccess: "Thêm chức vụ thành công",
      editPositionSuccess: "Sửa chức vụ thành công",
      deletePositionSuccess: "Xoá chức vụ thành công",
      movePositionSuccess: "Chuyển chức vụ thành công",
      changeUserPosition: "Chuyển chức vụ nhân viên thành công",
      noData: "Không có dữ liệu",
      importUserToShiftSuccess: "Import nhân viên vào ca thành công",
      addGroupUserSuccess: "Thêm nhóm nhân sự thành công",
      editGroupUserSuccess: "Sửa nhóm nhân sự thành công",
      deleteGroupUserSuccess: "Xóa nhóm nhân sự thành công",
      addOvertimeSuccess: "Thêm đề xuất làm thêm thành công",
      importShiftSuccess: "Nhập ca thành công",
      addCheckinSuccess: "Thêm chấm công thành công",
      updateCheckinSuccess: "Cập nhật chấm công thành công",
      cannotApprove: "Duyệt không thành công",
      approveOvertimeSuccess: "Duyệt đề xuất tăng ca thành công",
      addMealsSuccess: "Thêm đăng ký suất ăn thành công",
      updateMealsSuccess: "Cập nhật đăng ký suất ăn thành công",
      deleteMealSuccess: "Xóa đăng ký suất ăn thành công",
      addDeviceSuccess: "Thêm thiết bị thành công",
      updateDeviceSuccess: "Cập nhật thiết bị thành công",
      deleteDeviceSuccess: "Xóa thiết bị thành công",
      approveListLeaveSuccess: "Duyệt danh sách nghỉ thành công",
      approveListOvertimeSuccess: "Duyệt danh sách tăng ca thành công",
      deleteOvertimeSuccess: "Xoá thành công đơn tăng ca",
      importLeaveSuccess: "Nhập ngày nghỉ thành công",
      importCheckinSuccess: "Nhập chấm công thành công",
      updateLeaveSuccess: "Cập nhật ngày nghỉ thành công",
      employeeCheckinlateShiftDate:
        "Nhân sự {name} checkin muộn ca {data} ngày {date}",
      employeeCheckedinShiftDate:
        "Nhân sự {name} đã checkin ca {data} ngày {date}",
      waitingForApproveLeave:
        "Đơn {name} của nhân sự {data} đang chờ phê duyệt",
      waitingForApproveOvertime:
        "Đơn {name} của nhân sự {data} đang chờ phê duyệt",
      yourLeaveApproved: "Đơn {name} của nhân sự {data} đã được duyệt",
      yourOvertimeApproved: "Đơn {name} của nhân sự {data} đã được duyệt",
      yourLeaveDenied: "Đơn {name} của nhân sự {data} bị từ chối",
      yourOvertimeDenied: "Đơn {name} của nhân sự {data} bị từ chối",
      updateSuccess: "Cập nhật thành công",
      nodata: "Không có dữ liệu",
      overtimeDenied: "Từ chối tăng ca thành công",
      overtimeDeletedSuccessfully: "Xoá đơn tăng ca thành công",
      saveOvertimeSuccessfully: "Thêm đơn tăng ca thành công",
      saveLeaveTypeSuccessfully: "Lưu loại đơn báo thành công",
      leaveTypeDeletedSuccessfully: "Xóa đơn báo thành công",
      leaveDeletedSuccessfully: "Xóa đơn nghỉ thành công",
      leaveUpdatedSuccessfully: "Sửa đơn nghỉ thành công",
      leaveAddedSuccessfully: "Thêm đơn nghỉ thành công",
      faceRegistySuccessfully: "Đăng kí khuôn mặt thành công",
      registerFaceSuccessful: "Đẩy nhân viên xuống thiết bị thành công",
      syncFingerSuccess: "Đồng bộ vân tay thành công",
      updateUserSuccess: "Cập nhật nhân viên thành công",
    },
  },
  home: {
    menu: {
      home: "Trang chủ",
      userCares: "Ứng dụng",
      services: "Dịch vụ",
      benefis: "Lợi ích",
      contact: "Liên hệ",
      resources: "Tài nguyên",
    },
    banner: {
      title: "Giải pháp Camera Thông minh",
      desc: "Kiểm soát ra vào và chấm công bằng nhận diện khuôn mặt, QR code.",
      slogan: "Checkin theo cách của bạn!",
    },
    ourServices: {
      ourServices: "Dịch vụ của chúng tôi",
      desc: "Chúng tôi luôn cung cấp dịch vụ với chất lượng tốt nhất",
      accessControl: "Kiểm soát ra vào",
      accessControlDesc:
        "Thiết lập chính sách ra vào địa điểm, thông tin cho nhân viên và khách.",
      timeAtendance: "Chấm công",
      timeAtendanceDesc:
        "Cài đặt thời gian làm việc theo ca, Ghi lại thời gian làm việc của từng nhân viên",
      vmsSystem: "Hệ thống VMS",
      vmsSystemDesc:
        "Tạo sự kiện, Tạo biểu mẫu tự đăng ký, Nhắc nhớ sự kiện qua Email, Tạo mã QR để tự đăng ký",
      analytics: "Phân tích",
      analyticsDesc:
        "Phân tích nhận dạng khuôn mặt, Phân tích kiểm soát truy cập, Cảnh bảo hết hạn, Tạo báo cáo trong tệp Excel",
      integration: "Tích hợp",
      integrationDesc: "Tích hợp với Nền tảng bên thứ ba.",
    },
    keyBenefits: {
      title: "Lợi ích chính",
      desc: "Các lợi ích mà bạn sẽ nhận được",
      affordable: "Chi phí hợp lý",
      affordableDesc: "Giải pháp có sẵn phù hợp với tất cả doanh nghiệp.",
      mutiTenant: "Multi-tenant",
      mutiTenantDesc:
        "Tùy chỉnh quyền truy cập cho mỗi đơn vị để quản lý địa điểm, nhân viên, sự kiện.",
      apisDriver: "APIs Driven",
      apisDriverDesc: "Tích hợp với nền tảng bên thứ ba.",
      analytics: "Phân tích",
      analyticsDesc:
        "Xem tổng thể hệ thống hàng ngày, hàng tuần, hàng tháng theo dòng thời gian, bảng và đồ thị.",
      cloudService: "Dịch vụ điện toán đám mây",
      cloudServiceDesc:
        "Quản lý dịch vụ trên đám mây, có thể truy cập Bảng điều khiển từ bất kỳ từ đâu.",
    },
    contactUs: {
      title: "Liên hệ với chúng tôi",
      desc: "Đội ngũ chuyên gia của chúng tôi sẽ tư vấn giải pháp phù hợp với doanh nghiệp của bạn",
      slogan: "Viettel Construction | Dựng xây cuộc sống mới",
      address: "Số 06 Phạm Văn Bạch, Phường Yên Hòa, Quận Cầu Giấy, Hà Nội.",
    },
    termscondition: "Điều khoản & Điều kiện",
    privacyPolicy: "Chính sách bảo mật",
  },

  label: {
    finger1: "Vân tay 1",
    finger2: "Vân tay 2",
    shiftName: 'Tên ca làm việc',
    idEmployee: "Mã NV",
    nameEmployee: "Họ tên",
    employee: "Nhân viên",
    camera: "Camera",
    image: "Hình ảnh",
    time: "Thời gian",
    performedBy: "Người thực hiện",
    selectReport: "Chọn báo cáo",
    all: "Tất cả",
    byHandle: "Chấm thủ công",
    stranger: "Người lạ",
    approver: "Người duyệt",
    guest: "Khách hàng",
    start: "Bắt đầu",
    end: "Kết thúc",
    filter: "Lọc",
    control: "Thao tác",
    removeFromShift: "Xoá khỏi ca làm việc",
    addToShift: "Thêm nhân viên vào ca",
    totalRecord: "Tổng số bản ghi",
    descriptionemployeeManager:
      "QUẢN LÝ NHÂN VIÊN TRỰC THUỘC TẤT CẢ PHÒNG BAN, CHI NHÁNH",
    descriptionAttendanceManagement: "CHẤM CÔNG CHO NHÂN VIÊN",
    descriptionShowListShift: "QUẢN LÝ CÁC CA LÀM VIỆC TRONG NGÀY",
    descriptionArrangeShifts: "QUẢN LÝ XẾP CA",
    descriptionBranchManagement:
      "QUẢN LÝ CÁC CHI NHÁNH TRỰC THUỘC DOANH NGHIỆP CỦA BẠN",
    descriptionDepartmentManager:
      "QUẢN LÝ CÁC PHÒNG BAN TRỰC THUỘC DOANH NGHIỆP",
    addNew: "Thêm mới",
    export: "Xuất ",
    import: "Nhập",
    leaveTypeRequired: "Loại đơn báo",
    employeeCode: "Mã nhân viên",
    totalNumberOfEmployees: "Tổng số nhân viên:",
    totalNumberOfEmployeesInDevice: "Tổng số nhân viên có trong máy:",
    fullName: "Họ và tên",
    branch: "Chi nhánh",
    department: "Phòng ban",
    departmentEmployee: "Nhân sự/Phòng ban",
    adminCompany: "Quản trị công ty",
    adminBranch: "Quản trị chi nhánh",
    admin: "Quản trị viên",
    role: "Quyền",
    jobPosition: "Chức vụ",
    dateOfEntry: "Ngày làm việc",
    phoneNumber: "Số điện thoại",
    totalWorkShifts: "Tổng số ca",
    statisticsToday: "Thống kê hôm nay",
    working: "Đang làm việc",
    late: "Đi muộn",
    absent: "Vắng mặt",
    takeLeave: "Nghỉ phép",
    birthday: "Sinh nhật",
    toWork: "Vào ca",
    sessionWork: "Ca làm việc",
    endWork: "Tan ca",
    minuteLate: "Đi muộn (phút)",
    minuteEarly: "Về sớm (phút)",
    name: "Tên",
    workingTime: "Thời gian làm việc",
    breakTimeBetweenShifts: "Thời gian nghỉ giữa ca",
    timeToAllowTimeAttendance: "Thời gian cho phép chấm vào",
    timeToAllowTimekeeping: "Thời gian cho phép chấm ra",
    loadShiftsInSeries: " Xếp ca hàng loạt",
    extraWorkShifts: " Gán thêm ca",
    defaultShift: "Ca mặc định",
    delete: "Xóa",
    address: "Địa chỉ",
    area: "Khu vực",
    total: "Tổng số",
    dateTime: "Ngày giờ",
    addEdit: "Thêm/Sửa",
    save: "Lưu",
    areYouSure: "Bạn có chắc chắn",
    sureDeleteTheBranch: "Bạn có chắc chắn muốn xoá chi nhánh không?",
    iAgree: "Tôi đồng ý",
    type: "Kiểu dữ liệu",
    mainWorkShift: "Ca chính",
    overtimeShift: "Ca làm thêm",
    numberOfWorkdays: "Đơn vị công/ngày",
    timeToStartShift: "Giờ bắt đầu ca",
    endTimeOfShift: "Giờ kết thúc ca",
    thereIsABreakBetweenShift: "Có nghỉ giữa ca",
    timeToStartABreakInTheMiddleOfTheShift: "Giờ bắt đầu nghỉ giữa ca",
    timeToApply: "Thời gian áp dụng",
    timeCheckinLate: "Cho phép checkin muộn (phút)",
    timeCheckoutEarly: "Cho phép checkout sớm (phút)",
    endHourBreakBetweenShift: "Giờ kết thúc nghỉ giữa ca",
    repeatShifts: "Lặp ca",
    staffInShift: "Nhân viên trong ca",
    check: "Kiểm tra",
    operationTimeOfShift: "Ngày làm việc của ca",
    sureDeleteShift: "Bạn có chắc chắn muốn xoá ca làm việc này không?",
    monday: "Thứ 2",
    tuesday: "Thứ 3",
    wednesday: "Thứ 4",
    thursday: "Thứ 5",
    friday: "Thứ 6",
    saturday: "Thứ 7",
    sunday: "Chủ nhật",
    date: "Ngày giờ",
    turnAudioOn: "Thông báo bằng tiếng:",
    notification: "Thông báo",
    checkAddApprover: "Vui lòng thêm bước duyệt",
    notificationError: "Thông báo lỗi",
    agree: "Đồng ý",
    cancel: "Huỷ",
    object: "Đối tượng",
    selectCamera: "Chọn camera",
    selectDevices: "Chọn máy chấm công",
    titleLogin: "Giải pháp chấm công nhận diện khuôn mặt",
    user: "Tài khoản",
    password: "Mật khẩu",
    changePassword: "Đổi mật khẩu",
    confirmPassword: "Xác nhận mật khẩu",
    login: "Đăng nhập",
    forGotPassword: "Quên mật khẩu",
    descCompany: "Copyright © 2021 Viettel Construction",
    search: "Tìm kiếm",
    timeDay: "Thời gian",
    deleteSuccess: "Xóa thành công",
    close: "Đóng",
    errorMessage: "Có lỗi sảy ra!",
    addBranchSuccess: "Thêm mới chi nhánh thành công",
    editBranchSuccess: "Chỉnh sửa chi nhánh thành công",
    office: "Vị trí công việc",
    dateStartWork: "Ngày vào làm",
    selectTime: "Sắp xếp theo thời gian",
    asc: "Tăng dần",
    desc: "Giảm dần",
    company: "Công ty",
    staffInformation: "Thông tin nhân viên",
    mainInformation: "Thông tin chính",
    male: "Nam",
    female: "Nữ",
    gender: "Giới tính",
    dateOfBirth: "Ngày sinh",
    idCard: "Chứng minh thư",
    asACollaborator: "Là cộng tác viên",
    yes: "Có",
    no: "Không",
    bankAccountNumber: "Số tài khoản ngân hàng",
    personalImage: "Hình ảnh cá nhân",
    avatar: "Ảnh đại diện",
    update: "Cập nhật",
    upload: "Tải lên",
    addStaff: "Thêm nhân viên",
    checkInCode: "Mã checkin",
    edit: "Chỉnh sửa",
    details: "Chi tiết",
    sureDeleteMember: "Bạn có chắc chắn muốn xoá thành viên này không?",
    sureDeleteMemberAll: "Bạn có chắc chắn muốn xoá tất cả vân tay/khuôn mặt không? Hành động sẽ không thể hoàn tác",
    staffsName: "Tên nhân viên",
    browser: "Người duyệt",
    reason: "Lý do",
    status: "Trạng thái",
    reportType: "Loại báo cáo",
    filterBy: "Lọc theo",
    filterByuser: "Lọc nhân viên",
    report: "Báo cáo",
    chooseDate: "Chọn ngày",
    chooseDateRange: "Chọn khoảng ngày",
    chooseTime: "Chọn giờ",
    exportReport: "Xuất báo cáo",
    moreHolidays: "Thêm ngày lễ",
    holidayList: "Danh sách ngày lễ",
    holiday: "Ngày lễ",
    creatat: "Ngày tạo",
    creatatJoin: "Ngày vào làm",
    creatatList: "Ngày tạo đơn",
    effect: "Hiệu lực",
    nameHoliday: "Tên ngày lễ",
    enterHolidayName: "Nhập tên ngày lễ",
    waitingForApprove: "Chờ duyệt",
    pending: "Đang duyệt",
    approved: "Đã duyệt",
    approval: "Duyệt",
    sureLeave: "Bạn có chắc chắn muốn xoá đơn nghỉ phép này không?",
    sureMeal: "Bạn có chắc chắn muốn xoá phiếu ăn này không?",
    sureLeaveType: "Bạn có chắc chắn muốn xoá loại đơn báo này không?",
    sureApproveLeave: "Bạn có chắc chắn muốn duyệt đơn nghỉ phép này không?",
    sureDevice: "Bạn có chắc chắn muốn xoá thiết bị này không?",
    totalLeave: "Tổng số đơn nghỉ phép:",
    approvalSuccess: "Duyệt thành công",
    addLeaveApplication: "Thêm đơn xin nghỉ",
    addLeaveRegimeApplication: "Thêm đơn xin nghỉ chế độ",
    editLeaveRegimeApplication: "Sửa đơn xin nghỉ phép",
    addOverTime: "Thêm đơn tăng ca",
    editOverTime: "Sửa đơn tăng ca",
    addClaimTime: "Thêm đơn duyệt bù công",
    reasonForLeave: "Lý do nghỉ",
    reasonForCompensation: "Lý do bù công",
    reasonForOvertime: "Lý do tăng ca",
    selectStaff: "Chọn nhân viên",
    checkedin: "Đã checkin",
    notCheckin: "Chưa checkin",
    on: "Bật",
    off: "Tắt",
    workDayDetail: "Chấm công",
    checkinByDay: "Chấm công theo ngày",
    checkinDetail: "Chi tiết checkin",
    checkinMoreDay: "Chấm công nhiều ngày",
    workDetail: "Chi tiết chấm công",
    mealsInMonth: "Báo cáo số suất cơm ca",
    detailCheckin: "Báo cáo chấm công chi tiết",
    detailInout: "Báo cáo lịch sử vào ra",
    generalReport: "Bảng chấm công",
    selectDateRange: "Chọn khoảng ngày",
    allBranches: "Tất cả các chi nhánh",
    totalDailyShifts: "Tổng số ca ngày",
    applyPublicity: "Áp dụng tính công",
    comeBackBefore: "Về sớm",
    takeABreak: "Xin nghỉ",
    repeatWeekly: "Lặp lại hàng tuần",
    setAsDefault: "Đặt làm mặc định",
    applyOvertime: "Có tính tăng ca",
    itAHoliday: "Là ngày nghỉ lễ",
    pleaseEnterEmployeeName: "Vui lòng nhập tên nhân viên...",
    to: "đến",
    sender: "Người gửi",
    weekly: "Hàng tuần",
    default: "Mặc định",
    learnMore: "Xem thêm",
    moreInformation: "Thông tin bổ sung",
    submitHome: "Đăng ký dùng thử",
    turnInOut: "Lượt ra vào",
    leaveHalfShift: "Nghỉ nửa ca",
    chooseLeaveHalf: "Chọn nửa ca nghỉ",
    leaveFirstHalf: "Nghỉ nửa ca trước",
    leaveSecondHalf: "Nghỉ nửa ca sau",
    refuse: "Từ chối",
    feedbackForm: "Đơn phản hồi",
    browseApplications: "Duyệt đơn",
    chooseTheAmountOfWork: "Chọn số công",
    halfWork: "Nửa công",
    oneWork: "Một công",
    sureClaimTime: "Bạn có chắc chắn muốn xoá đơn bù công này không? ",
    totalClaimTime: "Tổng số đơn bù công",
    userName: "Tên đăng nhập",
    titleListCheckin: "Danh sách Checkin",
    taxCode: "Mã số thuế",
    logout: "Đăng xuất",
    description: "Mô tả",
    addCompany: "Thêm công ty",
    totalCompany: "Tổng số công ty",
    monthlyStatistics: "Thống kê tháng",
    leaveRemain: "Số phép còn lại",
    workNumber: "Số ngày làm việc",
    workspaceTitle: "Workspace của bạn",
    personal: "Cá nhân",
    personalInformation: "Thông tin cá nhân",
    timekeeping: "Chấm công",
    leaveDetails: "Chi tiết nghỉ phép",
    inoutDetails: "Chi tiết ra vào",
    oldPassword: "Mật khẩu cũ",
    downloadFileTemplate: "Tải xuống file mẫu",
    importUserFromFile: "Nhập nhân viên từ file",
    searchUser: "Tên hoặc mã nhân viên",
    leave: "Nghỉ phép",
    reasonDenied: "Lý do từ chối",
    personalPage: "Trang cá nhân",
    moreDepartments: "Thêm phòng ban",
    nameBranch: "Tên chi nhánh",
    chooseDepartment: "Chọn phòng ban",
    noNodeChildren: "Không có cấp con",
    changeDepartment: "Chuyển phòng ban",
    changeDevice: "Chuyển vân tay",
    changeEmployee: "Chuyển nhân viên",
    leaveType: "Loại đơn báo",
    changeFace: "Chuyển khuôn mặt",
    totalLeaveType: "Tổng số",
    leaveTypeName: "Tên",
    leaveTypeCode: "Mã",
    chooseMonth: "Chọn tháng",
    chooseDatetime: "Chọn ngày giờ",
    chooseYear: "Chọn năm",
    positionName: "Tên chức vụ",
    addPosition: "Thêm chức vụ",
    choosePosition: "Chọn chức vụ",
    preview: "Xem trước",
    exportPdf: "Xuất ra pdf",
    exportExcel: "Xuất ra excel",
    groupUser: "Nhóm nhân sự",
    totalGroup: "Tổng số nhóm",
    addGroupUser: "Thêm nhóm nhân sự",
    groupName: "Tên nhóm",
    note: "Ghi chú",
    sureDeleteGroup: "Bạn có chắc muốn xóa nhóm nhân sự này?",
    print: "In",
    annualLeave: "Số ngày nghỉ phép",
    numberLeave: "Số ngày nghỉ còn lại",
    noShift: "Chưa có ca",
    extraLeaveDays: "Ngày phép thâm niên",
    totalLeavedDays: "Tổng ngày đã nghỉ",
    regimeLeaveDay: "Số ngày phép chế độ",
    approveStep: "Bước duyệt",
    addApproveStep: "Thêm bước duyệt",
    userType: "Loại người dùng",
    manager: "Quản lý",
    chef: "Đầu bếp",
    minuteOvertime: "Số phút làm thêm",
    offWorkFrom: "Chọn ngày làm thêm",
    reasonForOT: "Lý do làm thêm",
    importShiftFromFile: "Nhập ca từ file",
    totalOvertime: "Tổng số đơn tăng ca",
    checkin: "Chấm công",
    shift: "Ca làm việc",
    finishAtOverday: "Kết thúc ca ở ngày sau",
    chooseShift: "Chọn ca làm việc",
    chooseDevice: "Chọn máy",
    employeeAddedToShift: "Nhân viên đã được thêm vào ca {name}",
    employeeRemovedFromShift: "Nhân viên bị xóa khỏi ca {name}",
    action: "Hoạt động",
    dateRegister: "Ngày đăng ký",
    quantity: "Số lượng",
    operationDayOfMeal: "Các ngày đăng ký trong tuần",
    register: "Đăng ký",
    leaveReport: "Bảng giải trình chấm công",
    lateReport: "Báo cáo nhân sự đi trễ",
    earlyReport: "Báo cáo nhân sự về sớm",
    shiftReport: "Báo cáo ca làm việc",
    totalDevice: "Tổng số thiết bị",
    deviceCode: "Mã thiết bị",
    deviceName: "Tên thiết bị",
    deviceType: "Loại thiết bị",
    camCheckin: "Camera vào ca",
    camCheckout: "Camera tan ca",
    camCheckinout: "Camera vào và tan ca",
    fingerCheckin: "Máy vân tay vào ca",
    fingerCheckout: "Máy vân tay tan ca",
    fingerCheckinout: "Máy vân tay vào ca/tan ca",
    approveList: "Duyệt hàng loạt",
    sureOvertime: "Bạn có chắc chắn xoá đơn tăng ca này không?",
    content: "Nội dung",
    importCheckinFromFile: "Nhập chấm công từ file",
    importOvertimeFromFile: "Nhập file nhân viên tăng ca",
    importLeaveFromFile: "Nhập nghỉ phép từ file",
    notifyHeader: "Bạn có {count} thông báo",
    deleteAll: "Xóa tất cả",
    addDepartment: "Thêm phòng ban",
    departmentName: "Tên phòng ban",
    sureApproveOvertime: "Bạn có chắc muốn duyệt đơn tăng ca này",
    workShiftNumber: "Số ca làm việc",
    autoApprove: "Tự động duyệt đơn",
    device: "Thiết bị",
    meal: "Suất ăn",
    inout: "Vào / Ra",
    mealInfo: "Thông tin suất ăn",
    mealDetail: "Chi tiết suất ăn",
    totalMeal: "Tổng số đơn đăng ký",
    selectDateCancelMeal: "Chọn ngày hủy",
    confirm: "Xác nhận",
    unregisterDate: "Ngày hủy đăng kí",
    cancelMealList: "Danh sách nhân viên đăng kí cắt cơm ca",
    chooseEmployee: "Chọn nhân viên",
    employeeShift: "Số nhân sự mỗi ca",
    employeenoShift: "Số nhân sự chưa có ca",
    totalEmployees: "Tổng số nhân viên",
    inOut: "Vào/ra",
    selectDevice: "Chọn thiết bị",
    attendance: "Chấm công",
    handAttendance: 'Thêm lịch sử Vào/Ra',
    dateAttendance: "Ngày tính công",
    monthAttendance: "Tháng tính công",
    performedAt: "Ngày thực hiện",
    success: "Thành công",
    cancelMeal: "Hủy đăng kí",
    leaveDate: "Ngày xin nghỉ",
    create: "Tạo đơn",
    dateOT: "Ngày tăng ca",
    totalMinuteOvertime: "Tổng số phút tăng ca",
    startTime: "Giờ bắt đầu",
    startDate: "Ngày bắt đầu",
    endTime: "Giờ kết thúc",
    endDate: "Ngày kết thúc",
    detailLeave: "Chi tiết đơn xin nghỉ",
    timeLeave: "Thời gian xin nghỉ",
    OTDetail: "Chi tiết tăng ca",
    OTReport: "Bảng tổng hợp công tăng ca",
    dayOff: "Ngày nghỉ",
    aliasID: "Mã nhân viên"
  },
  formula: {
    dbError: "Lỗi truy vấn cơ sở dữ liệu",
  },
};
