<template>
  <!-- Page content -->

  <div id="main-default" class="container-fluid" style="padding:0px !important">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-xl-12">
          <h1 class="page-title mt-2">
            {{ $t("menu.attendanceData") }}
          </h1>
          <div class="col-xl-12 d-flex justify-content-between">
            <p>
              {{ $t("label.totalRecord") }}:
              {{ totalRecord }}
            </p>
            <p>
              {{ $t("label.totalEmployees") }}:
              {{ totalEmployees }}
            </p>
            <p v-if="device">
              {{ $t("label.device") }}:
              {{ device }}
            </p>
            <div class="text-right mb-2" v-if="userType == '2'">
              <!-- <button class="btn btn-primary mr-2" @click="downloadFileTemplate()">
                <font-awesome-icon icon="download" />
                {{ $t("label.downloadFileTemplate") }}
              </button> -->
              <b-form-file style="width: auto; text-align: left;" :placeholder="$t('label.importCheckinFromFile')"
                drop-placeholder="Drop file here..." accept=".xlsx, .xls, .dat" ref="file"></b-form-file>
              <b-button variant="btn btn-primary ml-2" @click="importCheckin()">{{ $t('label.import') }}
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row align-items-center justify-content-center">
            <div class="col-md-4 col-xs-12">
              <select class="form-control" v-model="modelDevice">
                <option :value="null">
                  <p style="color: #6c757d !important;">Chọn máy chấm công</p>
                </option>
                <option v-for="optionsDeviceItem in optionsDevice" v-bind:value="optionsDeviceItem.value">
                  {{ optionsDeviceItem.text }}
                </option>
              </select>
            </div>
            <div class="col-xs-12 col-lg-4 col-md-4">
              <flat-pickr v-model="dateRange" :config="configDateTimePicker" :placeholder="$t('label.selectDateRange')"
                name="date_apply" class="form-control"></flat-pickr>
            </div>
            <div class="col-xs-12 col-lg-4 col-md-4 mt-4">
              <b-form-group>
                <div class="autocomplete" v-click-outside="onClickOutside">
                  <b-form-input v-model="aliasId" @input="onChangeCode" type="text"
                    :placeholder="$t('label.employeeCode')">
                  </b-form-input>
                  <ul v-show="isOpenCodeRes" class="autocomplete-results">
                    <li v-for="(result, i) in codeResults" :key="i" @click="setCodeResult(result)"
                      class="autocomplete-result">
                      {{ result.value }} - {{ result.text }}
                    </li>
                  </ul>
                </div>
              </b-form-group>
            </div>
            <div class="col-xs-12 col-lg-4 col-md-4 mt-4">
              <!-- <input type="text" v-model="fullName" :placeholder="$t('label.staffsName')" class="form-control" /> -->
              <b-form-group>
                <div class="autocomplete" v-click-outside="handleClickOutside">
                  <b-form-input v-model="fullName" @input="onChangeName" type="text"
                    :placeholder="$t('label.fullName')">
                  </b-form-input>
                  <ul v-show="isOpenNameRes" class="autocomplete-results">
                    <li v-for="(result, i) in nameResults" :key="i" @click="setNameResult(result)"
                      class="autocomplete-result">
                      {{ result.value }} - {{ result.text }}
                    </li>
                  </ul>
                </div>
              </b-form-group>
            </div>
            <div class="col-xs-12 col-lg-4 col-md-4 ">
              <treeselect v-model="departmentid" :options="departmentTree" :default-expand-level="1"
                :placeholder="$t('label.chooseDepartment')" />
            </div>
            <div class="col-xs-12 col-lg-4 col-md-4">
              <b-button variant="btn btn-info  btn-outline-primary w-100 font-weight-bold" @click="getTempLogs()">{{
                $t("label.search") }}
              </b-button>
            </div>
            <div class="col-xs-12 col-lg-4 col-md-4 ">
              <b-button variant="btn btn-danger w-100 bg-gradient-danger w-100 font-weight-bold"
                @click="getLogsFromDevice()">
                Đồng bộ dữ liệu từ thiết bị
              </b-button>
            </div>
            <div class="col-xs-12 col-lg-4 col-md-4 " v-if="userType == '2'">
              <a href="javascript:void(0)" class="btn btn-danger w-100 bg-gradient-danger font-weight-bold"
                @click="attendance()">{{
                  $t("label.handAttendance") }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-xl-12 mt-4">
          <div class="row mt-3">
            <!-- <div class="col-12 text-right">
              <div class="mb-2">
                <a href="javascript:void(0)" @click="showModalAddCheckin()" class="btn btn-danger bg-gradient-danger">
                  <font-awesome-icon icon="plus" />
                  {{ $t("label.addNew") }}</a>
              </div>
            </div> -->
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table style="max-height: 500px;" striped bordered class="default-table" id="table-checkin" hover
                    :items="dataTableShow" :fields="fields" responsive show-empty
                    @head-clicked="handleHeaderClick($event)">
                    <template #cell(avatar)="data">
                      <a href="javascript:void(0)" @click="showImage(data.item.avatar)">
                        <img v-if="data.item.avatar" width="95px" height="95px" :src="data.item.avatar"
                          :alt="data.item.personName" />
                        <img v-else width="95px" height="95px" src="../../assets/img/avtDefault.jpg"
                          :alt="data.item.personName ? data.item.personName : data.fullName" />
                      </a>
                    </template>
                    <template #cell(dateOfEntry)="data">
                      {{ convertTimestampToDate(data.item.dateID) }}
                    </template>
                    <template #cell(fullName)="data">
                      <ul class="list-unstyled m-0">
                        <li>
                          <strong>{{ data.item.fullName }}</strong>
                        </li>
                      </ul>
                    </template>
                    <template #cell(shift)="data">
                      <span class="bold" v-if="data.item.default_shift == true">{{ $t("label.defaultShift") }}</span>
                      {{ data.item.shift }}
                    </template>

                    <template #empty="scope">
                      {{ $t("notify.message.nodata") }}
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="totalRecord" :per-page="perPage" align="right"
                    size="sm" class="m-4" aria-controls="table-checkin" v-on:click.native="getTempLogs()">
                  </b-pagination>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal v-model="modalImage" hide-footer centered hide-header modal-class="modal-image" size="lg">
        <div class="d-block text-center">
          <img :src="urlImage" />
        </div>
      </b-modal>
      <b-modal v-model="modalUpdateCheckin" :title="$t('label.addEdit') + ' ' + $t('label.checkin')"
        ref="modal-update-checkin" header-bg-variant="secondary" hide-footer centered modal-class="modal-image"
        size="lg">
        <b-form ref="form" class="row">
          <div class="col-lg-6 col-md-6 col-xs-12">
            <b-form-group :label="$t('label.employeeCode') + ' - ' + $t('label.fullName')">
              <div class="autocomplete" v-click-outside="onClickOutside">
                <b-form-input v-model="employee" @input="onChangeCodeName" type="text">
                </b-form-input>
                <ul v-show="isOpenCodeNameRes" class="autocomplete-results">
                  <li v-for="(result, i) in codeNameResults" :key="i" @click="setCodeNameResult(result)"
                    class="autocomplete-result">
                    {{ result.value }} - {{ result.text }}
                  </li>
                </ul>
              </div>
              <div class="error-message w-100 clear text-left">
                {{ msg["alias"] ? $t(msg["alias"]) : "" }}
              </div>
            </b-form-group>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12">
            <b-form-group :label="$t('label.shift')">
              <select v-model="shiftId" class="form-control">
                <option v-for="shift in shifts" v-bind:value="shift.value">
                  {{ shift.text }}
                </option>
              </select>

              <div class="error-message w-100 clear text-left">
                {{ this.msg["shiftId"] ? $t(this.msg["shiftId"]) : "" }}
              </div>
            </b-form-group>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12">
            <b-form-group :label="$t('label.dateOfEntry')">
              <flat-pickr v-model="dateID" :config="configDateIDPicker" :placeholder="$t('label.chooseDate')"
                name="dateID" class="form-control"></flat-pickr>
              <div class="error-message w-100 clear text-left">
                {{ msg["date"] ? $t(msg["date"]) : "" }}
              </div>
            </b-form-group>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12">
            <b-form-group :label="$t('label.toWork')">
              <flat-pickr v-model="startWork" :config="configDateTimePicker1" :placeholder="$t('label.chooseTime')"
                name="startWork" class="form-control"></flat-pickr>
              <div class="error-message w-100 clear text-left">
                {{ msg["checkin"] ? $t(msg["checkin"]) : "" }}
              </div>
            </b-form-group>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-12">
            <b-form-group :label="$t('label.endWork')">
              <flat-pickr v-model="endWork" :config="configDateTimePicker1" :placeholder="$t('label.chooseTime')"
                name="endWork" class="form-control"></flat-pickr>
              <div class="error-message w-100 clear text-left">
                {{ msg["checkout"] ? $t(msg["checkout"]) : "" }}
              </div>
            </b-form-group>
          </div>
          <div>
            <input type="hidden" name="id" :value="id" />
            <input type="hidden" name="personID" :value="personID" />
            <input type="hidden" name="avatar" :value="imgUrl" />
          </div>
          <div class="col-12 mt-3 text-center">
            <b-button class="btn btn-danger" @click="saveCheckin()">{{
              $t("label.save")
            }}</b-button>
            <b-button class="btn btn-default" @click="hideModalCheckin">{{
              $t("label.cancel")
            }}</b-button>
          </div>
        </b-form>
      </b-modal>
    </div>
    <!-- <div class="col-12">
      <b-modal v-model="modalAttendance" ref="modal-attendance" :title="'Nhập dữ liệu'"
        header-bg-variant="secondary" hide-footer centered size="lg" style="height: 50vh;">
       <div  class="row">
        <div class="col-xs-6 col-lg-6 col-md-3">
            <b-form-group :label="$t('label.department')">
                <flat-pickr v-model="dateRange" :config="configDateTimePicker" :placeholder="$t('label.selectDateRange')"
                name="date_apply" class="form-control"></flat-pickr>
            </b-form-group>
          </div>
          <div class="col-xs-6 col-lg-6 col-md-3">
            <b-form-group :label="$t('label.employeeCode')">
                <div class="autocomplete" v-click-outside="onClickOutside">
                  <b-form-input v-model="aliasId" @input="onChangeCodeAdd" type="number">
                  </b-form-input>
                  <ul v-show="isOpenCodeRes" class="autocomplete-results">
                    <li v-for="(result, i) in codeResults" :key="i" @click="setCodeResult(result)"
                      class="autocomplete-result">
                      {{ result.value }} - {{ result.text }}
                    </li>
                  </ul>
                </div>
              </b-form-group>
          </div>
       </div>
        <div class="row">
          <div class="col-xs-6 col-lg-6 col-md-3">
            <b-form-group :label="$t('label.chooseMonth')">
              <select v-model="monthModal" class="form-control">
                <option v-for="m in monthOptions" v-bind:value="m.value">
                  {{ m.text }}
                </option>
              </select>
            </b-form-group>
          </div>
          <div class="col-xs-6 col-lg-6 col-md-3">
            <b-form-group :label="$t('label.chooseYear')">
              <select v-model="yearModal" class="form-control">
                <option v-for="y in yearOptions" v-bind:value="y.value">
                  {{ y.text }}
                </option>
              </select>
            </b-form-group>
          </div>
        </div>

        <div class="d-block text-center">
          <div class="text-center">
            <b-button class="btn btn-danger" @click="processData">{{ $t("label.confirm") }}
            </b-button>
            <b-button class="btn btn-default" @click="hideModalAttendance">{{ $t("label.cancel") }}
            </b-button>
          </div>
        </div>
      </b-modal>
    </div> -->
    <div class="col-12">
      <b-modal v-model="modalAttendance" ref="modal-attendance" :title="$t('label.handAttendance')"
        header-bg-variant="secondary" hide-footer centered size="lg" style="height: 50vh;">
        <div class="row">
          <div class="col-xs-6 col-lg-6 col-md-3">
            <b-form-group :label="$t('label.employeeCode')">
              <div class="autocomplete" v-click-outside="onClickOutside">
                <b-form-input v-model="aliasId" @input="onChangeCodeAdd" type="number"
                  :placeholder="$t('label.employeeCode')">
                </b-form-input>
                <ul v-show="isOpenCodeRes" class="autocomplete-results">
                  <li v-for="(result, i) in codeResults" :key="i" @click="setCodeResult(result)"
                    class="autocomplete-result">
                    {{ result.value }} - {{ result.text }}
                  </li>
                </ul>
              </div>
            </b-form-group>
          </div>
          <div class="col-xs-6 col-lg-6 col-md-3">
            <b-form-group :label="$t('label.fullName')">
              <div class="autocomplete" v-click-outside="handleClickOutside">
                <b-form-input v-model="fullName" @input="onChangeName" type="text" :placeholder="$t('label.fullName')">
                </b-form-input>
                <ul v-show="isOpenNameRes" class="autocomplete-results">
                  <li v-for="(result, i) in nameResults" :key="i" @click="setNameResult(result)"
                    class="autocomplete-result">
                    {{ result.value }} - {{ result.text }}
                  </li>
                </ul>
              </div>
            </b-form-group>
          </div>
          <div class="col-xs-6 col-lg-6 col-md-3">
            <b-form-group :label="$t('label.chooseDatetime')">
              <flat-pickr v-model="addDate" :config="configDateTimePickerAdd" :placeholder="$t('label.chooseDatetime')"
                name="date_apply" class="form-control"></flat-pickr>
            </b-form-group>
          </div>
          <div class="col-xs-6 col-lg-6 col-md-3 ">
            <b-form-group :label="$t('label.type')">
              <select v-model="type" class="form-control">
                <option v-for="m in typeOptions" v-bind:value="m.value">
                  {{ m.text }}
                </option>
              </select>
            </b-form-group>
          </div>
        </div>

        <div class="d-block text-center">
          <div class="text-center">
            <b-button class="btn btn-danger" @click="processData">{{ $t("label.confirm") }}
            </b-button>
            <b-button class="btn btn-default" @click="hideModalAttendance">{{ $t("label.cancel") }}
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
    <pulse-loader :loading="loading" :text="true"></pulse-loader>
  </div>
</template>
<script>
import apiClient from "@/axios";
import apiServer from "@/axiosServer";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import FlatpickrLanguages from "flatpickr/dist/l10n";
import Treeselect from "@riophae/vue-treeselect";
import PulseLoader from "@/components/PulseLoader.vue";
import moment from "moment";
import Vue from "vue";

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

export default {
  components: {
    flatPickr,
    Treeselect,
    PulseLoader,
  },
  data() {
    return {
      isSupperAdmin: false,
      isComAdmin: false,
      userType: null,
      aliasId: null,
      fullName: null,
      loading: false,
      date: this.getNowDate(),
      configDateTimePicker: {
        dateFormat: "d/m/Y",
        mode: "range",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true,
        // onChange: function (selectedDates, dateStr, instance) {
        //   if (selectedDates.length === 1) {
        //     const startDate = selectedDates[0];
        //     const endDate = new Date(startDate);
        //     endDate.setDate(startDate.getDate() + 6);
        //     instance.set('minDate', startDate);
        //     instance.set('maxDate', endDate);
        //   }
        // }
      },
      configDateIDPicker: {
        dateFormat: "d/m/Y",
        locale: FlatpickrLanguages["en"],
        defaultDate: "today",
        maxDate: "today",
        shorthandCurrentMonth: true,
      },
      configDateTimePickerAdd: {
        enableTime: true, // Cho phép chọn giờ
        dateFormat: "d/m/Y H:i", // Định dạng ngày và giờ
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true,
      },

      configDateTimePicker1: {
        enableTime: true,
        dateFormat: "d/m/Y H:i:ss",
        defaultDate: this.dateID,
        time_24hr: true,
        locale: FlatpickrLanguages["en "],
        noCalendar: true,
        shorthandCurrentMonth: true,
      },
      urlImage: "",
      modalImage: false,
      accessToken: process.env.VUE_APP_TOKEN_HANET,
      placeId: "",
      branch: undefined,
      branchs: [],
      departmentid: null,
      departmentOption: [],
      departmentTree: [],
      positionTree: [], addDate: '',
      username: "",
      dateRange: null,
      from_date: null,
      to_date: null,
      usercode: null,
      departmentid: null,
      positionid: null,
      lateEarly: null,
      msg: [],
      dataTable: [],
      totalRecord: 0,
      currentPage: 1,
      modalAttendance: false,
      perPage: 25,
      fields: [
        {
          label: this.$t("label.idEmployee"),
          key: "aliasID",
          tdClass: "text-center",
          thClass: "text-center",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.fullName"),
          key: "fullName",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.department"),
          key: "department",
          class: "align-middle",
          sortable: false,
        },
        // {
        //   label: this.$t("label.sessionWork"),
        //   key: "shift",
        //   tdClass: "text-center",
        //   class: "align-middle",
        //   thClass: "text-center",
        //   sortable: true,
        // },
        // {
        //   label: this.$t("label.inOut"),
        //   key: "inOut",
        //   tdClass: "text-center",
        //   class: "align-middle",
        //   thClass: "text-center",
        //   sortable: true,
        // },
        {
          label: this.$t("label.dateOfEntry"),
          key: "dateID",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.time"),
          key: 'time',
          tdClass: "text-center",
          class: "align-middle",
          thClass: "text-center",
          sortable: true,
        },
      ],
      modalUpdateCheckin: false,
      id: null,
      employee: null,
      shiftId: null,
      shifts: [],
      dateID: null,
      nameResults: [],
      codeResults: [],
      imgUrl: null,
      startWork: null,
      endWork: null,
      personID: null,
      isOpenCodeNameRes: false,
      codeNameResults: [],
      departmentName: "",
      positionName: "",
      dataTableShow: [],
      device: "",
      totalEmployees: 0,
      isOpenNameRes: false,
      type: '',
      typeOptions: [
        {
          value: 'VAO', text: 'Vào'
        }, {
          value: 'RA', text: 'Ra'
        }
      ],
      isOpenCodeRes: false,
      column: null,
      sort: "asc",
      modelDevice: null,
      optionsDevice: [
        {
          value: '',
          text: this.$t('label.all')
        },
        {
          value: '0',
          text: this.$t('label.byHandle')
        },

      ],

    };
  },
  mounted() {
    this.userType = this.$store.getters.userType
    this.getListDeviceByPlace();

    // this.getListPersonByPlace();
    if (this.$store.getters.superAdmin == true) {
      this.isSupperAdmin = true;
    }
    if (this.$store.getters.comAdmin == true) {
      this.isComAdmin = true;
    }
    if (this.isSupperAdmin || this.isComAdmin) {
      this.getListBranch();
    }
    this.getDepartmentTree();
    // this.getPositionTree();
    // this.getListShifts();
    if (this.$store.getters.locale == "vi") {
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"];
    }
  },
  methods: {
    async onChangeName() {
      this.isOpenNameRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.fullName,
          searchType: "name"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.nameResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.nameResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },
    hideModalAttendance() {
      this.resetForm();
      this.$refs["modal-attendance"].hide();
    },
    async getListDeviceByPlace() {
      let res
      try {
        res = await apiServer.post('api/v1/get-devices',
          {
            currentPage: 1,
            rowPerPage: 500

          })
      } catch (error) {
      }
      if (res && res.data) {
        const arrayData = res.data.devices
        this.optionsDevice = [
          {
            value: '',
            text: this.$t('label.all')
          },
          {
            value: '0',
            text: this.$t('label.byHandle')
          },
        ]
        for (let i = 0; i < arrayData.length; i++) {
          this.deviceString += arrayData[i].deviceID + ','
          this.optionsDevice.push({
            value: arrayData[i].device_code,
            text: arrayData[i].device_name
          })
        }
      } else {
        this.optionsDevice = [
          {
            value: '',
            text: this.$t('label.all')
          },
          {
            value: '0',
            text: this.$t('label.byHandle')
          },
        ]
        this.isBusy = false
      }
    },
    async onChangeCode() {
      this.isOpenCodeRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.aliasId,
          searchType: "code"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.codeResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.codeResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },

    async onChangeCodeAdd() {
      this.isOpenCodeRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.aliasId,
          searchType: "code"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.codeResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.codeResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },
    setNameResult(result) {
      this.fullName = result.text;
      this.aliasId = result.value;
      this.isOpenNameRes = false;
    },
    setCodeResult(result) {
      this.fullName = result.text;
      this.aliasId = result.value;
      this.isOpenCodeRes = false;
    },
    changeDepartment() {
      setTimeout(() => { this.getListBranch() }, 500);
    },
    handleClickOutside() {
      this.isOpenNameRes = false;
    },
    getNowDate() {
      let today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },
    search() {
      if (this.departmentid && this.departmentid.length == 0) {
        this.departmentid = null;
      }
      if (this.positionid && this.positionid.length == 0) {
        this.positionid = null;
      }
      this.getListPersonByPlace();
      this.date = this.dateRange;
    },
    attendance() {
      this.addDate = ''
      this.aliasId = null
      this.fullName = null
      this.modalAttendance = true;
    },
    async processData() {
      this.loading = true;
      this.dataTableShow = []
      this.dataTable = []
      let res
      try {
        res = await apiServer.post('/api/v1/checkin/import-by-user', {
          checkinDateTime: this.convertStringToDateTime(this.addDate),
          aliasID: this.aliasId,
          type: this.type
        })
      } catch (error) {
        console.log(error)
        this.loading = false
      }

      if (res && res.data) {
        console.log(res.data.data);
        console.log(res);
        this.modalAttendance = false;
        this.dataTableShow = res.data.data
        // this.dataTable = res.data.data

        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t('Thêm thành công'),
          position: "bottom-center",
        });
        this.resetForm();
      }
      this.loading = false
    },
    hideModalAttendance() {
      this.resetForm();
      this.$refs["modal-attendance"].hide();
    },
    resetForm() {
      this.departmentId = 1;
      let today = new Date();
      this.dayModal = today.getDate()
      this.monthModal = (today.getMonth()).toString().padStart(2, '0');
      this.yearModal = today.getFullYear();
    },
    findDepartmentNameById() {
      this.departmentName = '';
      for (let i = 0; i < this.departmentTree.length; i++) {
        const department = this.departmentTree[i];
        if (department.id === this.departmentid) {
          this.departmentName = department.name
        }
        const childrenDepartment = department.children?.find(child => child.id === this.departmentid);
        if (department.children && department.children.length > 0 && childrenDepartment) {
          // this.departmentName = department.name;
          this.departmentName = childrenDepartment.name;
        }
      }

    },
    findPositionNameById() {
      this.positionName = '';
      console.log(this.positionTree)
      console.log(this.positionid)
      for (let i = 0; i < this.positionTree.length; i++) {
        const position = this.positionTree[i];
        if (position.id === this.positionid) {
          this.positionName = position.name
        }
      }
      console.log(this.positionName)

    },
    async getListBranch() {
      let res;
      try {
        res = await apiServer.post("/api/v1/company/get-all-branch", {});
        if (res && res.data) {
          this.branchs.push({
            value: undefined,
            text: this.$t("label.allBranches"),
          });
          for (let br of res.data) {
            this.branchs.push({ value: br.placeID, text: br.branch_name });
          }
          this.date = this.dateRange;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getDepartmentTree() {
      let res;
      try {
        res = await apiServer.post("api/v1/department/list", {});
      } catch (error) { }
      if (res && res.data) {
        this.departmentTree = res.data;
      }
    },
    async getPositionTree() {
      let res;
      try {
        res = await apiServer.get("api/v1/position/tree", {});
      } catch (error) { }
      if (res && res.data) {
        this.positionTree = [res.data];
      }
    },
    async getListShifts() {
      this.shifts = [];
      let res;
      try {
        res = await apiServer.post("api/v1/shifts/get", {
          shifts_name: this.shiftsName,
        });
      } catch (error) { }
      if (res && res.data) {
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.shifts.push({
              value: res.data[i].shift_id,
              text: res.data[i].shifts_name,
            });
          }
        }
      }
    },
    convertDateTime(date) {
      if (date == undefined || date == null) return null;
      return moment(date).format("DD/MM/yyyy HH:mm:ss");
    },
    convertOnlyTime(date) {
      if (date == undefined || date == null) return null;
      return moment(date).format("HH:mm:ss");
    },
    convertDate(inputDate) {
      if (inputDate == undefined || inputDate == null) return "";
      let date = new Date(inputDate);
      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const yyyy = date.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    },
    showImage(urlImage) {
      this.urlImage = urlImage;
      this.modalImage = true;
    },
    handleHeaderClick(column) {
      this.clickedColumn = column;
      this.getTempLogs(column)
    },
    async getListPersonByPlace() {
      this.loading = true;
      this.from_date = null;
      this.to_date = null;
      if (this.dateRange !== "") {
        let dateArr = this.dateRange?.split(" ");

        this.from_date = this.convertTimestampSendDate(dateArr[0]);
        if (dateArr.length == 1) {
          this.to_date = this.from_date;
        } else {
          this.to_date = this.convertTimestampSendDate(dateArr[2]);
        }
      }
      // if (this.dateRange !== null && this.dateRange !== '') {
      //   let dateArr = this.dateRange.split(' ');
      //   timeEnd = this.convertTimestampSendDate(dateArr[0]);
      //   if (dateArr.length == 1) {
      //     timeStart = timeEnd;
      //   }
      //   else {
      //     timeStart = this.convertTimestampSendDate(dateArr[2]);
      //   }

      // }
      let user_code = this.usercode != null ? this.usercode.trim() : null;
      let user_name = this.username != null ? this.username.trim() : null;
      let res;
      // eslint-disable-next-line no-useless-catch
      try {
        res = await apiServer.post("/api/v1/camera/get-check-in", {
          fromdate: this.from_date,
          todate: this.to_date,
          usercode: user_code,
          username: user_name,
          placeid: this.branch,
          departmentid: this.departmentid,
          positionid: this.positionid,
          islate: this.lateEarly,
          currentPage: this.currentPage,
          rowPerPage: this.perPage,
        });
      } catch (error) {
        this.loading = false;
        throw error;
      }
      if (res && res.data) {
        // this.dataTable = res.data.data.items;
        // this.totalRecord = res.data.data.totalRecord;
        this.loading = false;
      }
    },
    async onChangeCodeName() {
      this.isOpenCodeNameRes = true;
      let res;
      try {
        res = await apiServer.post("api/v1/user/suggest-employee", {
          placeID: this.$store.getters.placeID,
          keyword: this.employee,
        });
      } catch (error) { }
      if (res && res.data) {
        this.codeNameResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.codeNameResults.push({
              value: user.aliasID,
              text: user.fullName,
              personID: user.personID,
              avatar: user.avatar,
            });
          }
        }
      }
    },
    async saveCheckin() {
      let arrCodeName =
        this.employee != null ? this.employee.split(" - ") : [null];
      let res;
      this.loading = true;
      try {
        res = await apiServer.post("/api/v1/checkin/save", {
          id: this.id,
          dateID: this.convertTimestampSendDate(this.dateID),
          personID: this.personID,
          aliasID: arrCodeName[0],
          avatar_image_url: this.imgUrl,
          shiftId: this.shiftId,
          checkin: this.convertStringToDateTime(this.startWork),
          checkout: this.convertStringToDateTime(this.endWork),
        });
        if (res && res.data) {
          this.loading = false;
          this.modalUpdateCheckin = false;
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(res.data.message),
            position: "bottom-center",
          });
          this.getListPersonByPlace();
        }
      } catch (error) {
        this.loading = false;
        if (error.response.status === 400) {
          this.msg = error.response.data;
        }
        if (error.response.data.message) {
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notificationError"),
            text: this.$t(error.response.data.message),
            type: "error",
            position: "bottom-center",
          });
        }
      }
    },
    editCheckin(item) {
      this.id = item.id;
      this.shiftId = item.shiftId;
      this.employee = item.aliasID + " - " + item.fullName;
      this.dateID = this.convertTimestampToDate(item.dateID);
      this.startWork = this.convertDateTimeToString(item.checkin);
      this.endWork = this.convertDateTimeToString(item.checkout);
      this.imgUrl = item.avatar;
      this.modalUpdateCheckin = true;
      this.personID = item.personID;
    },
    async downloadFileTemplate() {
      let res;
      try {
        res = await apiServer
          .get("/api/v1/checkin/downloadTemplate", {
            responseType: "blob",
          })
          .then((response) => {
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "checkin");
            document.body.appendChild(link);
            link.click();
          });
      } catch (error) {
        console.log(error);
      }
    },
    async importCheckin() {
      this.dateRange = null;
      this.aliasId = null;
      this.departmentid = null
      let file = this.$refs.file.files[0];
      if (file == null) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notificationError"),
          text: this.$t("error.message.noSelectFile"),
          type: "error",
          position: "bottom-center",
        });
        return false;
      }
      this.modalImport = true;
      await this.confirmImportCheckin();
      await this.getTempLogs();
    },
    async confirmImportCheckin() {
      let file = this.$refs.file.files[0];
      const filename = file.name;
      const fileExt = filename.substring(filename.lastIndexOf('.'), filename.length)
      let importEndpoint = "api/v1/checkin/import";
      if (fileExt === ".dat") {
        importEndpoint = "api/v1/checkin/import-finger";
      }
      let formData = new FormData();
      formData.append("file", file);
      // formData.append("rowPerPage", this.perPage)
      // formData.append("currentPage", this.currentPage)
      // formData.append("placeId", this.placeId);
      // formData.append("companyId", this.company);
      // formData.append("file", files[0]);
      const config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };
      let res;
      this.msg = [];
      try {
        this.loading = true;
        res = await apiServer.post(importEndpoint, formData, config);
      } catch (error) {
        this.loading = false;
        if (error.response.data.message) {
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notificationError'),
            text: this.$t(error.response.data.message),
            type: 'error',
            position: 'bottom-center'
          });
        }
        // await this.getListPersonByPlace();
      } finally {
        this.loading = false;
      }
      if (res && res.data) {
        // console.log(res.data)
        // const table = res.data.data.items
        // this.dataTable = [];
        // this.dataTableShow = [];
        // this.dataTable = [...table.slice(0, 500)];
        // this.dataTableShow = [...table.slice(0, 500)];
        // this.totalRecord = res.data.data.totalRecords;
        // this.device = res.data.data.deviceName;
        // this.totalEmployees = res.data.data.totalEmployees;


        // this.$notify({
        //   group: "bottomnotification",
        //   title: this.$t("label.notification"),
        //   text: this.$t(res.data.message),
        //   position: "bottom-center",
        // });
        // await this.getListPersonByPlace();
      }
    },
    async getLogsFromDevice() {
      this.from_date = null;
      this.to_date = null;
      if (this.dateRange == null) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notificationError"),
          text: "Bạn chưa chọn ngày",
          type: "error",
          position: "bottom-center",
        });
        return false
      }
    //  if (this.departmentid === 1) {
    //    this.$notify({
    //      group: "bottomnotification",
    //      title: this.$t("label.notificationError"),
    //      text: this.$t("Không hỗ trợ đồng bộ cả công ty"),
   //       type: "error",
    //      position: "bottom-center",
    //    });
    //    return false
    //  }
      if (this.dateRange !== null) {
        let dateArr = this.dateRange?.split(" ");

        this.from_date = this.convertTimestampSendDate(dateArr[0]);
        if (dateArr.length == 1) {
          this.to_date = this.from_date;
        } else {
          this.to_date = this.convertTimestampSendDate(dateArr[2]);
        }
      }


    //  const fromDate = new Date(this.from_date);
    //  const toDate = new Date(this.to_date);
    //  const timeDiff = toDate - fromDate;
    //  const dayDiff = timeDiff / (1000 * 60 * 60 * 24);
    //  if (dayDiff > 7) {
    //    this.$notify({
    //      group: "bottomnotification",
    //    title: this.$t("label.notificationError"),
    //    text: this.$t("Chỉ đồng bộ khoảng thời gian 7 ngày"),
    //    type: "error",
    //    position: "bottom-center",
    //  });
    //  return false
    //}
      if ((this.departmentid && this.aliasId) || (this.departmentid && this.modelDevice) || (this.aliasId && this.modelDevice)) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notificationError"),
          text: this.$t("Chỉ đồng bộ theo thiết bị/ phòng ban/ mã nhân viên"),
          type: "error",
          position: "bottom-center",
        });
        return false
      }
      if (!this.departmentid && !this.aliasId && !this.modelDevice) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notificationError"),
          text: this.$t("Bạn chưa chọn thiết bị/ phòng ban/ nhân viên"),
          type: "error",
          position: "bottom-center",
        });
        return false

      }




      let res
      try {
        this.loading = true;
        res = await apiServer.post("/api/v1/sync-checkin", {
          fromDate: this.from_date,
          toDate: this.to_date,
          FacePersionId: this.aliasId,
          departmentId: this.departmentid,
          device: this.modelDevice
        });
        if (res && res.data) {
          this.loading = false;


          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: 'Đồng bộ thành công',
            position: "bottom-center",
          });
        }
      } catch (error) {
        console.log(error);

        this.loading = false;
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notificationError"),
          text: "Đồng bộ thất bại",
          type: "error",
          position: "bottom-center",
        });
      }
    },
    async getTempLogs(column) {
      this.from_date = null;
      this.to_date = null;
      this.sort = this.sort === 'asc' ? 'desc' : 'asc';
      if (this.dateRange !== null) {
        let dateArr = this.dateRange?.split(" ");

        this.from_date = this.convertTimestampSendDate(dateArr[0]);
        if (dateArr.length == 1) {
          this.to_date = this.from_date;
        } else {
          this.to_date = this.convertTimestampSendDate(dateArr[2]);
        }
      }
      let res
      try {
        this.loading = true;
        res = await apiServer.post("/api/v1/checkin/get-temp-logs", {
          fromDate: this.from_date,
          toDate: this.to_date,
          aliasID: this.aliasId,
          departmentId: this.departmentid,
          column: column,
          sort: this.sort,
          currentPage: this.currentPage,
          rowPerPage: this.perPage,
        });
        if (res && res.data) {
          this.loading = false;
          this.totalRecord = res.data.data.totalRecords
          this.dataTableShow = res.data.data.items
          this.device = res.data.data.deviceName;
          this.totalEmployees = res.data.data.totalEmployees;
          // this.$notify({
          //   group: "bottomnotification",
          //   title: this.$t("label.notification"),
          //   text: this.$t('Thành công'),
          //   position: "bottom-center",
          // });
          // console.log(res.data)
        }
      } catch (error) {
        this.loading = false;
        if (error.response.status === 400) {
          this.msg = error.response.data;
        }
        if (error.response.data.message) {
          // this.$notify({
          //   group: "bottomnotification",
          //   title: this.$t("label.notificationError"),
          //   text: this.$t(error.response.data.message),
          //   type: "error",
          //   position: "bottom-center",
          // });
        }
      }
    },
    onClickOutside() {
      this.isOpenCodeRes = false;
      this.isOpenCodeNameRes = false;
    },
    showModalAddCheckin() {
      this.id = null;
      this.shiftId = null;
      this.employee = null;
      this.dateID = this.getNowDate();
      this.startWork = this.dateID + " 08:00:00";
      this.endWork = this.dateID + " 17:00:00";
      this.imgUrl = null;
      this.modalUpdateCheckin = true;
    },
    setCodeNameResult(result) {
      this.employee = result.value + " - " + result.text;
      this.personID = result.personID;
      this.imgUrl = result.avatar;
      this.isOpenCodeNameRes = false;
    },
    hideModalCheckin() {
      this.$refs["modal-update-checkin"].hide();
    },
    convertTimestampToDate(timestamp) {
      if (timestamp == null || timestamp == undefined) return null;
      return moment(timestamp).format("DD/MM/yyyy");
    },
    convertDateTimeToString(timestamp) {
      if (timestamp == null || timestamp == undefined) return null;
      return moment(timestamp).format("DD/MM/yyyy HH:mm:ss");
    },
    convertTimestampSendDate(date) {
      if (date == undefined || date == null) {
        return null;
      }
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, "$3-$2-$1");
      var date = new Date(changedDate);
      return moment(date).format("yyyy-MM-DD");
    },
    convertStringToDateTime(date) {
      if (date == undefined || date == null) {
        return null;
      }
      const dateString = date;
      const changedDate = dateString.replace(
        /(..)\/(..)\/(....) (..):(..)/,
        "$3-$2-$1 $4:$5"
      );
      var date = new Date(changedDate);
      return moment(date.getTime()).format("yyyy-MM-DD HH:mm:ss");
    },
  },
  watch: {
    dateID(date) {
      this.startWork = date + " " + this.startWork.split(" ")[1];
      this.endWork = date + " " + this.endWork.split(" ")[1];
    },
    startWork(startTime) {
      if (!this.endWork || startTime > this.endWork) {
        this.endWork = startTime;
      }
    },
    endWork(endTime) {
      if (!this.startWork || endTime < this.startWork) {
        this.startWork = endTime;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.btn {
  font-weight: normal;
}

.filter-asc,
.filter-desc {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.filter-asc {
  margin-bottom: 1px;
  border-bottom: 5px solid #ccc;
}

.img-profile {
  width: 60px !important;
  border-radius: 10px;
  border: 1px solid #dedede;
}

.table-small td {
  font-size: 11px !important;
  padding: 5px !important;
  vertical-align: middle !important;
}

.autocomplete {
  position: relative;
}

.autocomplete-results {
  position: absolute;
  z-index: 999;
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  min-height: 1em;
  max-height: 18em;
  overflow: auto;
  background: #fff;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 2px 4px;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #eeeceb;
}

.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-25%, -130%);
  padding: 20px;
  border-radius: 5px;
  z-index: 100000;
}

.overlay p {
  margin: 0;
  font-size: 18px;
}
</style>
