<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid" style="padding:0px !important">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <h1 class="page-title mt-2">
                {{ $t("menu.employeeManager") }}
              </h1>
              <p class="text-muted">
                {{ $t("label.totalNumberOfEmployees") }} {{ totalUser }}
              </p>
            </div>
            <!-- <div class="col-md-6 col-xs-12 text-right" v-if="userType == '2'">
              <div class="mt-4">
                <a
                  href="javascript:void(0)"
                  class="btn btn-danger bg-gradient-danger"
                  @click="redirectAddNew"
                >
                  <font-awesome-icon icon="plus" />
                  {{ $t("label.addNew") }}</a
                >
              </div>
            </div> -->
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <b-form-group>
                <div class="autocomplete" v-click-outside="onClickOutside">
                  <b-form-input v-model="aliasId" @input="onChangeCode" type="number"
                    :placeholder="$t('label.employeeCode')">
                  </b-form-input>
                  <ul v-show="isOpenCodeRes" class=" autocomplete-results">
                    <li v-for="(result, i) in codeResults" :key="i" @click="setCodeResult(result)"
                      class="autocomplete-result">
                      {{ result.value }} - {{ result.text }}
                    </li>
                  </ul>
                </div>
              </b-form-group>
            </div>
            <div class="col-xs-12 col-md-3">
              <b-form-group>
                <div class="autocomplete" v-click-outside="onClickOutside">
                  <b-form-input v-model="fullName" @input="onChangeName" type="text"
                    :placeholder="$t('label.fullName')">
                  </b-form-input>
                  <ul v-show="isOpenNameRes" class="autocomplete-results">
                    <li v-for="(result, i) in nameResults" :key="i" @click="setNameResult(result)"
                      class="autocomplete-result">
                      {{ result.value }} - {{ result.text }}
                    </li>
                  </ul>
                </div>
              </b-form-group>
            </div>
            <div class="col-xs-12 col-md-3">
              <treeselect v-model="treeData" :multiple="true" :options="options" :flat="true" :default-expand-level="1"
                :placeholder="$t('label.chooseDepartment')" />
            </div>
            <div class="col-md-3 col-xs-12">
              <b-button variant="btn btn-info  btn-outline-primary w-100" @click="search()">{{ $t("label.search") }}
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row mt-1">
            <div class="col-xl-6 col-xs-12 mb-4">
              <button class="btn btn-primary mr-2" @click="changeDepartment()">
                <font-awesome-icon icon="exchange-alt" />
                Đẩy nhân viên xuống thiết bị
              </button>
            </div>
            <div class="col-xl-6 col-xs-12 mb-4 text-right">
              <button class="btn btn-danger bg-gradient-danger mr-2" @click="getDeviceFromDB()">
                <font-awesome-icon icon="exchange-alt" />
                Sao lưu vân tay/ khuôn mặt
              </button>
            </div>
           <!--   <div class="col-xl-6 col-xs-12" v-if="userType == '2'">
              <button class="btn btn-primary mr-2" @click="downloadFileTemplate()">
                <font-awesome-icon icon="download" />
                {{ $t("label.downloadFileTemplate") }}
              </button>
              <b-form-file style="width: auto; text-align: left" :placeholder="$t('label.importUserFromFile')"
                drop-placeholder="Drop file here..." accept=".xlsx, .xls" ref="file"></b-form-file>
              <b-button variant="btn btn-primary ml-2" @click="importUser()">{{ $t('label.import') }}
              </b-button>
            </div>
            -->
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table striped bordered class="default-table" hover :items="dataTable" :fields="fields" responsive
                    id="table-employee" @head-clicked="handleHeaderClick($event)" style="max-height: 500px;" show-empty>
                    <template #head(checkbox)="data">
                      <b-form-checkbox @click.native.stop @change="selectAll(data)" v-model="allSelected">
                      </b-form-checkbox>
                    </template>
                    <template #cell(checkbox)="data">
                      <b-form-checkbox @click.native.stop :value="data.item.aliasID" v-model="userSelected">
                      </b-form-checkbox>
                    </template>
                    <template #cell(avatar)="data">
                      <a href="javascript:void(0)" @click="showImage(data.item.avatar)">
                        <img v-if="data.item.avatar" width="35px" height="35px"
                          style="border-radius: 8px; max-width: 35px" :src="data.item.avatar"
                          :alt="data.item.username" />
                        <img v-else width="35px" height="35px" style="border-radius: 8px; max-width: 35px"
                          src="../../assets/img/avtUser.png" :alt="data.item.username" />
                      </a>
                    </template>
                    <template #cell(dateStartWork)="data">
                      {{ convertDate(data.item.dateStartWork) }}
                    </template>
                    <template #cell(fullName)="data">
                      {{ data.item.fullName }}
                    </template>
                    <template #cell(function)="data" v-if="userType == '2'">
                      <button class="btn btn-warning btn-sm" @click="editUser(data.item.id)" v-b-tooltip.hover
                        :title="$t('label.edit')">
                        <font-awesome-icon icon="edit" />
                      </button>
                      <!-- <button
                        class="btn btn-primary btn-sm"
                        @click="showChangePassword(data.item.id)"
                        v-b-tooltip.hover
                        :title="$t('label.changePassword')"
                      >
                        <font-awesome-icon icon="exchange-alt" />
                      </button>
                      <button
                        class="btn btn-danger btn-sm"
                        @click="showDelete(data.item.id)"
                        v-b-tooltip.hover
                        :title="$t('label.delete')"
                      >
                        <font-awesome-icon icon="trash" />
                      </button> -->
                    </template>
                    <template #empty="scope">
                      {{ $t("notify.message.nodata") }}
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="totalUser" :per-page="perPage" align="right"
                    size="sm" class="m-4" aria-controls="table-employee" v-on:click.native="search()"></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="modalImage" hide-footer centered hide-header modal-class="modal-image" size="lg">
      <div class="d-block text-center">
        <img :src="urlImage" />
      </div>
    </b-modal>
    <b-modal v-model="modalMoveDepart" id="modal-move-depart" hide-footer centered size="lg"
      :title="'Đẩy nhân viên xuống thiết bị'">
      <div class="row col-xl-12 text-center">
        <treeselect v-model="movedValue" :options="deviceOptions" :default-expand-level="1"
          :placeholder="'Chọn thiết bị'" />
      </div>
      <div class="d-block text-center mt-5">
        <b-button class="btn btn-danger" @click="moveDepartment()">Xác Nhận
        </b-button>
        <b-button class="btn btn-default" @click="$bvModal.hide('modal-move-depart')">{{ $t("label.cancel") }}
        </b-button>
      </div>
    </b-modal>
    <b-modal v-model="modalDeviceFromDB" id="modal-move-device" hide-footer centered size="lg"
      :title="'Sao lưu vân tay/ khuôn mặt trên thiết bị tới Database'">
      <div class="row col-xl-12 text-center">
        <treeselect v-model="movedValue" :options="deviceOptions" :default-expand-level="1"
          :placeholder="'Chọn thiết bị'" />
      </div>
      <div class="d-block text-center mt-5">
        <b-button class="btn btn-danger" @click="moveDeviceFromDB()">Xác Nhận
        </b-button>
        <b-button class="btn btn-default" @click="$bvModal.hide('modal-move-device')">{{ $t("label.cancel") }}
        </b-button>
      </div>
    </b-modal>
    <b-modal v-model="modalChangePassword" :title="$t('label.addEdit')" hide-footer ref="change-password"
      header-bg-variant="secondary" centered size="md">
      <b-form ref="form">
        <b-form-group id="input-group-1" :label="$t('label.password')" label-for="input-1">
          <b-form-input id="input-1" type="password" v-model="newPassWord" required></b-form-input>
          <div class="error-message w-100 clear text-left">
            {{
              msg["password"] && msg["password"][0]
                ? $t(msg["password"][0])
                : ""
            }}
          </div>
        </b-form-group>
        <b-form-group id="input-group-2" :label="$t('label.confirmPassword')" label-for="input-2">
          <b-form-input id="input-2" type="password" v-model="confirmPassword" required></b-form-input>
          <div class="error-message w-100 clear text-left">
            {{
              msg["confirmPassword"] && msg["confirmPassword"][0]
                ? $t(msg["confirmPassword"][0])
                : ""
            }}
          </div>
        </b-form-group>
      </b-form>
      <div class="d-block text-center">
        <div class="text-center">
          <b-button class="btn btn-danger" @click="changePassword(idChangePassword)">{{ $t("label.save") }}
          </b-button>
          <b-button class="btn btn-default" @click="hideModalChangePassword">{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="modalAlert" :title="$t('label.notificationError')" hide-footer id="modal-alert"
      header-bg-variant="secondary" modal-class="modal-delete text-center" centered size="md">
      <div class="d-block text-center error-message">
        <div class="mt-3" v-for="(item, index) in msg">
          <span :key="index">{{ item }}</span>
        </div>
      </div>
      <div class="d-block text-center mt-3">
        <div class="text-center">
          <b-button class="btn btn-danger" @click="$bvModal.hide('modal-alert')">
            {{ $t("label.close") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- modal change Password  -->
    <b-modal v-model="modalDelete" ref="modal-delete" hide-header hide-footer header-bg-variant="secondary"
      modal-class="modal-delete text-center" centered size="md">
      <!-- <div v-show="showDeleteSuccess">
        <div class="modal-delete--success">
          <div class="modal-delete--warning__content">✓</div>
        </div>
        <div>
          <h2 class="text-success">{{ $t("label.deleteSuccess") }}</h2>
        </div>
        <div class="mt-3">
          <b-button variant="secondary" @click="hideModalDelete"
            >{{ $t("label.close") }}
          </b-button>
        </div>
      </div> -->
      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureDeleteMember") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="definitelyDelete(idDelete)">{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="secondary" @click="hideModalDelete">{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal><!-- modal delete  -->
    <pulse-loader :loading="loading"></pulse-loader>
  </div>
</template>
<script>
import apiClient from "@/axios";
import apiServer from "@/axiosServer";
import moment from "moment";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import PulseLoader from "@/components/PulseLoader.vue";

export default {
  components: { Treeselect, PulseLoader },
  data() {
    return {
      placeAdmin: false,
      loading: false,
      urlImage: "",
      modalImage: false,
      modalChangePassword: false,
      modalDelete: false,
      idChangePassword: "",
      accessToken: process.env.VUE_APP_TOKEN_HANET,
      placeId: "",
      idDelete: "",
      totalUser: 0,
      dataTable: [],
      idUser: "",
      newPassWord: "",
      confirmPassword: "",
      currentPage: 1,
      newDateStartWork: "",
      imageAvatar: null,
      userName: null,
      branch: null,
      branchs: [],
      msg: [],
      modalAlert: false,
      fullName: null,
      perPage: 25,
      isOpenCodeRes: false,
      nameResults: [],
      codeResults: [],
      aliasId: '',
      isOpenNameRes: false,
      fields: [
        {
          label: "",
          key: "checkbox",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.image"),
          key: "avatar",
          class: "align-middle ",
          sortable: false,
        },
        {
          label: this.$t("label.employeeCode"),
          key: "aliasID",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.fullName"),
          key: "fullName",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.jobPosition"),
          key: "position",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.creatatJoin"),
          key: "dateStartWork",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.department"),
          key: "department",
          class: "align-middle",
          sortable: false,
        },
        {
          label: "",
          key: "function",
          class: "align-middle text-center",
          sortable: true,
        },
      ],
      treeData: null,
      options: [],
      userSelected: [],
      allSelected: false,
      modalMoveDepart: false,
      modalDeviceFromDB: false,
      movedValue: null,
      flag: 'asc',
      userType: null,
      deviceOptions: null,
      ipUser: ''
    };
  },
  created() {
    if (
      this.$store.getters.superAdmin !== true &&
      this.$store.getters.comAdmin !== true
    ) {
      this.placeAdmin = true;
    }
  },
  mounted() {
    this.userType = this.$store.getters.userType
    this.ipUser = this.$store.getters.ipUser
    this.getDepartmentTree();
    this.getEmployee();
    this.getDeviceOption();
    //this.getListBranch();
  },
  methods: {
    async downloadFileTemplate() {
      let res;
      try {
        res = await apiServer
          .get("/api/v1/user/download-template", {
            responseType: "blob",
          })
          .then((response) => {
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: "application/zip",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "import.zip");
            document.body.appendChild(link);
            link.click();
          });
      } catch (error) { }
    },
    async importUser() {
      let file = this.$refs.file.files[0];
      if (file == null) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notificationError"),
          text: this.$t("error.message.noSelectFile"),
          type: "error",
          position: "bottom-center",
        });
        return false;
      }
      if (this.isSupperAdmin || this.isComAdmin) {
        this.modalImport = true;
      } else {
        await this.confirmImportUser();
      }
    },
    async confirmImportUser() {
      let formData = new FormData();
      formData.append("file", this.$refs.file.files[0]);
      const config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };
      let res;
      this.msg = [];
      try {
        this.loading = true;
        res = await apiServer.post("/api/v1/user/import-user-v2", formData, config);
      } catch (error) {
        if (error.response.status === 400) {
          this.loading = false;
          this.msg = error.response.data;
          this.modalAlert = true;
          await this.getEmployee();
        }
      } finally {
        this.loading = false;
      }
      if (res && res.data) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data),
          position: "bottom-center",
        });
        await this.getEmployee();
      }
    },
    handleHeaderClick(column) {
      this.getEmployee(column)
    },
    selectAll() {
      if (this.allSelected == true) {
        this.userSelected = this.dataTable.map((x) => x.aliasID);
      } else {
        this.userSelected = [];
      }
    },
    getDeviceFromDB() {
      this.modalDeviceFromDB = true;
    },
    changeDepartment() {
      if (this.userSelected.length == 0) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t("validate.message.noUserSelected"),
          position: "bottom-center",
          type: "error",

        });
        return false;
      } else {
        this.modalMoveDepart = true;
      }
    },
    showImage(urlImage) {
      this.urlImage = urlImage;
      this.modalImage = true;
    },
    showChangePassword(id) {
      this.modalChangePassword = true;
      this.idChangePassword = id;
    },
    hideModalChangePassword() {
      this.$refs["change-password"].hide();
    },
    async getDepartmentTree() {
      let res;
      try {
        res = await apiServer.get("api/v1/department/tree", {});
      } catch (error) { }
      if (res && res.data) {
        this.options = [res.data];
      }
    },
    async getDeviceOption() {
      let res
      try {
        res = await apiServer.post('/api/v1/get-devices', {
          ConnectStatus: 1,
          // currentPage: '',
          // rowPerPage : ''
        })
      } catch (error) { }
      if (res && res.data) {
        this.deviceOptions = Array.from(new Set(res.data.devices.map(i => ({
          label: i.device_name,
          id: i.device_code
        }))));
      }
    },
    search() {
      this.getEmployee();
    },
    async getEmployee(column) {
      this.loading = true;
      let placeID = this.$store.getters.placeID;
      if (this.branch != null && this.branch.value != "") {
        placeID = [this.branch];
      }
      if (this.treeData && this.treeData.length == 0) {
        this.treeData = null;
      }
      this.dataTable = [];
      let username = this.userName != null ? this.userName.trim() : null;
      let res;
      this.flag = this.flag === 'asc' ? 'desc' : 'asc';
      try {
        res = await apiServer.post("api/v1/user/get-list-user", {
          placeID: placeID,
          // userName: username,
          department: this.treeData,
          currentPage: this.currentPage,
          rowPerPage: this.perPage,
          column: column,
          sort: this.flag,
          aliasid: this.aliasId

        });
      } catch (error) {
        this.loading = false;
      }
      if (res && res.data) {
        this.dataTable = res.data.data.results;
        this.totalUser = res.data.data.totalRecord;
        this.loading = false;
      }
    },
    async moveDepartment() {
      if (this.movedValue == null || this.movedValue == undefined) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: "Chưa chọn thiết bị",
          position: "bottom-center",
          type: "error",

        });
        return false;
      }
      let res;
      try {
        this.loading = true
        res = await apiServer.post("/api/v1/device/register-face", {
          deviceCode: this.movedValue,
          aliasID: this.userSelected,
          ipUser: this.ipUser
        });
      } catch (error) { }
      if (res && res.data) {
        this.loading = false
        this.modalMoveDepart = false;
        this.movedValue = null;
        this.userSelected = false;
        await this.getEmployee();
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
      }
      this.userSelected = [];
    },
    async moveDeviceFromDB() {
      if (this.movedValue == null || this.movedValue == undefined) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: "Chưa chọn thiết bị",
          position: "bottom-center",
          type: "error",

        });
        return false;
      }
      let res;
      try {
        this.loading = true
        res = await apiServer.post("/api/v1/device/sync-face-by-device", {
          deviceCode: this.movedValue,
          ipUser: this.ipUser
        });
      } catch (error) { }
      if (res && res.data) {
        this.loading = false
        this.modalDeviceFromDB = false;
        this.movedValue = null;
        await this.getEmployee();
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
      }
    },
    redirectAddNew() {
      this.$router.replace("/admin/user");
    },
    editUser(id) {
      this.$router.push("/admin/user/" + id);
    },
    hideModalDelete() {
      this.modalDelete = false;
      this.$refs["modal-delete"].hide();
    },
    showDelete(idDelete) {
      this.idDelete = idDelete;
      this.modalDelete = true;
    },
    async definitelyDelete(id) {
      let response;
      try {
        let objDelete = this.dataTable.find((x) => x.id == id);
        response = await apiServer.post("api/v1/user/delete-user", {
          id: id,
          aliasID: objDelete.aliasID,
        });
      } catch (error) { }
      if (response && response.status) {
        if (response.status === 200) {
          this.hideModalDelete();
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(response.data.message),
            position: "bottom-center",
          });
          await this.getEmployee();
        }
      }
    },
    async onChangeCode() {
      this.isOpenCodeRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.aliasId,
          searchType: "code"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.codeResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.codeResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },
    async onChangeName() {
      this.isOpenNameRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.fullName,
          searchType: "name"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.nameResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.nameResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },
    setCodeResult(result) {
      this.aliasId = result.value;
      this.fullName = result.text;
      this.isOpenCodeRes = false;
    },
    setNameResult(result) {
      this.aliasId = result.value;
      this.fullName = result.text;
      this.isOpenNameRes = false;
    },
    onClickOutside() {
      this.isOpenCodeRes = false;
      this.isOpenNameRes = false;
    },
    async changePassword(id) {
      let res;
      this.msg = [];
      try {
        res = await apiServer.post("/api/v1/user/update-password", {
          id: id,
          password: this.newPassWord,
          confirmPassword: this.confirmPassword,
        });
        if (res && res.data) {
          if (res.status === 200) {
            this.modalChangePassword = false;
            this.$notify({
              group: "bottomnotification",
              title: this.$t("label.notification"),
              text: this.$t(res.data),
              position: "bottom-center",
            });
          }
        }
      } catch (error) {
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notificationError'),
            text: "Cập nhập thất bại",
            type: 'error',
            position: 'bottom-center'
          });
      }
    },
    async getListBranch() {
      let res;
      try {
        res = await apiServer.post("/api/v1/company/get-all-branch", {});
        if (res && res.data) {
          this.branchs.push({
            value: null,
            text: this.$t("label.allBranches"),
          });
          for (let br of res.data) {
            this.branchs.push({ value: br.placeID, text: br.branch_name });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    convertDate(inputDate) {
      if (inputDate == "" || inputDate == null) return;
      return moment(inputDate).format("DD/MM/yyyy");
    },
  },
};
</script>
<style lang="scss">
$mobile-width: 480px;

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

table {
  tr {
    color: #000;
  }
}

#main-default.container-fluid {
  padding: 15px 30px 30px 30px !important;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 400px;
}

.btn {
  font-weight: normal;
}

.default-table {
  thead {
    tr {
      background: #f1f1f1;

      th {
        text-transform: none;
        font-size: 15px;
        padding-top: 18px;
        padding-bottom: 18px;
        letter-spacing: 0;
      }
    }
  }

  td {
    font-size: 15px !important;
  }
}

.btn-sm {
  font-size: 15px !important;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle,
.btn-danger {
  background-color: #ec1a43 !important;
  border-color: #ec1a43 !important;
}

.table-hover tbody tr:hover {
  color: #000 !important;
  background-color: #ffefec !important;
}

.filter-asc,
.filter-desc {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.filter-asc {
  margin-bottom: 1px;
  border-bottom: 5px solid #ccc;
}

.img-profile {
  width: 60px !important;
  border-radius: 10px;
  border: 1px solid #dedede;
}

.table-small td {
  font-size: 11px !important;
  padding: 5px !important;
  vertical-align: middle !important;
}

.modal-image {
  img {
    max-height: 60vh;
    width: auto;
  }

  @include mobile {
    .modal-dialog {
      width: 96vw;

      img {
        height: auto;
        width: 80vw;
      }
    }
  }
}

.tooltip {
  opacity: 1 !important;
}

.vue-treeselect--searchable:not(.vue-treeselect--disabled) .vue-treeselect__value-container {
  padding: 9px !important;
}

.vue-treeselect__placeholder {
  padding: 7px;
}

.vue-treeselect--searchable .vue-treeselect__input-container {
  padding: 0;
}

.vue-treeselect--has-value .vue-treeselect__value-container {
  padding: 4px !important;
}

.vue-treeselect__single-value {
  line-height: 44px !important;
}
</style>
