<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid" style="padding:0px !important">
    <div class="card col-12">
      <div class="row mt-3">
        <div class="col-xs-6 col-md-4 col-lg-6">
          <b-form-group :label="$t('label.reportType')">
            <select placeholder="$t('label.selectReport')" v-model="reportType" class="form-control"
              @change="reportTypeChange">
              <option :value="1" disabled selected hidden>
                <p style="color: #6c757d !important;">--- Chọn báo cáo ---</p>
              </option>
              <option v-for="listReportType in listReportTypes" v-bind:value="listReportType.value">
                {{ listReportType.label }}
              </option>
            </select>
            <!-- <treeselect v-model="reportType" @change="reportTypeChange" :multiple="false" :options="listReportTypes"
              :default-expand-level="Infinity" :noChildrenText="$t('label.noNodeChildren')"
              :placeholder="$t('label.selectReport')" /> -->
          </b-form-group>
        </div>
        <div class="col-xs-6 col-md-4 col-lg-6" v-if="isSupperAdmin || isComAdmin">
          <b-form-group :label="$t('label.branch')">
            <select v-model="placeId" class="form-control" @change="changeBranch">
              <option v-for="branch in branchs" v-bind:value="branch.value">
                {{ branch.text }}
              </option>
            </select>
          </b-form-group>
        </div>

        <div class="col-xs-6 col-md-4 col-lg-6" v-if="reportByDepart">
          <b-form-group :label="$t('label.department')">
            <treeselect v-model="departmentId" :multiple="false" :options="departmentOption"
              :default-expand-level="Infinity" :noChildrenText="$t('label.noNodeChildren')"
              :placeholder="$t('label.chooseDepartment')" />
          </b-form-group>
        </div>
        <div class="col-xs-6 col-lg-6 col-md-3" v-if="reportByPos">
          <b-form-group :label="$t('label.jobPosition')">
            <treeselect v-model="positionId" :multiple="false" :options="positionOption"
              :default-expand-level="Infinity" :noChildrenText="$t('label.noNodeChildren')"
              :placeholder="$t('label.choosePosition')" />
          </b-form-group>
        </div>
        <div class="col-xs-6 col-lg-6 col-md-3" v-if="reportByDate">
          <b-form-group :label="$t('label.selectDateRange')">
            <flat-pickr v-model="dateSelect" :config="configDateTimePicker" :placeholder='$t("label.selectDateRange")'
              name="reportDate" class="form-control"></flat-pickr>
          </b-form-group>
        </div>
        <div v-if="reportByMonth" class="col-xs-6 col-lg-6 col-md-3">
          <b-form-group :label="$t('label.chooseMonth')">
            <select v-model="month" class="form-control">
              <option v-for="m in monthOptions" v-bind:value="m.value">
                {{ m.text }}
              </option>
            </select>
          </b-form-group>
        </div>
        <div v-if="reportByMonth" class="col-xs-6 col-lg-6 col-md-3">
          <b-form-group :label="$t('label.chooseYear')">
            <select v-model="year" class="form-control">
              <option v-for="y in yearOptions" v-bind:value="y.value">
                {{ y.text }}
              </option>
            </select>
          </b-form-group>
        </div>
        <div class="col-xs-6 col-lg-6 col-md-3" v-if="reportByUser">
          <b-form-group :label="$t('label.employeeCode')">
            <div class="autocomplete" v-click-outside="onClickOutside">
              <b-form-input v-model="aliasId" @input="onChangeCode" type="text">
              </b-form-input>
              <ul v-show="isOpenCodeRes" class="autocomplete-results">
                <li v-for="(result, i) in codeResults" :key="i" @click="setCodeResult(result)"
                  class="autocomplete-result">
                  {{ result.value }} - {{ result.text }}
                </li>
              </ul>
            </div>
          </b-form-group>
        </div>

        <div class="col-xs-6 col-lg-6 col-md-3" v-if="reportByUser">
          <b-form-group :label="$t('label.fullName')">
            <div class="autocomplete" v-click-outside="handleClickOutside">
              <b-form-input v-model="fullName" @input="onChangeName" type="text">
              </b-form-input>
              <ul v-show="isOpenNameRes" class="autocomplete-results">
                <li v-for="(result, i) in nameResults" :key="i" @click="setNameResult(result)"
                  class="autocomplete-result">
                  {{ result.value }} - {{ result.text }}
                </li>
              </ul>
            </div>
          </b-form-group>
        </div>
        <!-- <div class="col-xs-6 col-lg-6 col-md-3" v-if="reportByLateEarly">
          <b-form-group :label="$t('label.late') + '/' + $t('label.comeBackBefore')">
            <b-form-checkbox v-model="lateEarly"></b-form-checkbox>
          </b-form-group>
        </div> -->
        <div class="col-12 text-right mb-2">
          <a href="javascript:void(0)" v-if="userType == 2" class="btn btn-danger bg-gradient-danger"
            @click="attendance()">{{
              $t("label.attendance") }}
          </a>
          <a href="javascript:void(0)" class="btn btn-danger bg-gradient-danger" @click="previewreport()">{{
            $t("label.preview") }}
          </a>
          <a href="javascript:void(0)" v-if="userType == 2 || departmentIdParent == 16 || departmentIdParent == 2 " class="btn btn-danger bg-gradient-danger" @click="exportReport()">{{
            $t("label.exportExcel") }}
          </a>
          <a href="javascript:void(0)" class="btn btn-danger bg-gradient-danger" @click="generatePDFmake()">{{
            $t("label.exportPdf") }}
          </a>
        </div>
      </div>
    </div>
    <div class="card col-12 pt-4">
      <div class="row">
        <h2 class="ml-3">Bảng lịch sử thực hiện chấm công</h2>
        <div class="card-body m-3 p-0 table-responsive">
          <b-table striped bordered class="default-table" id="table-checkin" hover :items="dataTableShow"
            :fields="fields" responsive show-empty empty-text="Không có dữ liệu"
            @head-clicked="handleHeaderClick($event)" style="max-height: 500px;">
            <template #cell(status)="data">
              <template v-if="data?.item.status == 1">
                {{ $t("label.success") }}
              </template>
            </template>
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="totalRecord" :per-page="perPage" align="right" size="sm"
            class="m-4" aria-controls="table-checkin" v-on:click.native="getProcessCheckinLog()">
          </b-pagination>
        </div>
      </div>
    </div>
    <div class="col-12">
      <b-modal v-model="modalAttendance" ref="modal-attendance" :title="'Chấm công'" header-bg-variant="secondary"
        hide-footer centered size="lg">
        <div class="row">
          <div class="col-xs-6 col-lg-6 col-md-3">
            <b-form-group :label="$t('label.department')">
              <treeselect v-model="departmentIdModal" :multiple="false" :options="departmentOptionModal"
                :default-expand-level="Infinity" :noChildrenText="$t('label.noNodeChildren')"
                :placeholder="$t('label.chooseDepartment')" />
            </b-form-group>
          </div>
          <div class="col-xs-6 col-lg-6 col-md-3">
            <b-form-group :label="$t('label.employeeCode')">
              <div class="autocomplete" v-click-outside="onClickOutside">
                <b-form-input v-model="aliasId" @input="onChangeCode" type="text">
                </b-form-input>
                <ul v-show="isOpenCodeRes" class="autocomplete-results">
                  <li v-for="(result, i) in codeResults" :key="i" @click="setCodeResult(result)"
                    class="autocomplete-result">
                    {{ result.value }} - {{ result.text }}
                  </li>
                </ul>
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6 col-lg-6 col-md-3">
            <b-form-group :label="$t('label.chooseMonth')">
              <select v-model="monthModal" class="form-control">
                <option v-for="m in monthOptions" v-bind:value="m.value">
                  {{ m.text }}
                </option>
              </select>
            </b-form-group>
          </div>
          <div class="col-xs-6 col-lg-6 col-md-3">
            <b-form-group :label="$t('label.chooseYear')">
              <select v-model="yearModal" class="form-control">
                <option v-for="y in yearOptions" v-bind:value="y.value">
                  {{ y.text }}
                </option>
              </select>
            </b-form-group>
          </div>
        </div>

        <div class="d-block text-center">
          <div class="text-center">
            <b-button class="btn btn-danger" @click="processData">{{ $t("label.confirm") }}
            </b-button>
            <b-button class="btn btn-default" @click="hideModalAttendance">{{ $t("label.cancel") }}
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
    <div class="col-12">
      <b-modal v-model="modalPreview" id="modal-preview" modal-class="modal-fullscreen" title="Xem trước báo cáo"
        header-bg-variant="gradient-primary" header-text-variant="light" body-bg-variant="secondary" centered
        modal-content-class="table-modal-content">
        <div id="print" class="table-modal-content">
          <div class="contain" style="margin-left: 3%; margin-right: 3%; border-color: black;">
            <preview-report1 v-if="reportType == 6" id="preview-type1" class="report1" :preview-data="previewData"
              :day="day" :month="month" :year="year" :departmentName="departmentNameReport"
              :departmentUnit="departmentUnit" :leaveTypes="leaveTypes">
            </preview-report1>
            <preview-report2 v-if="reportType == 7" id="preview-type2" :preview-data="previewData" :day="day"
              :month="currentMonth" :year="year" :departmentName="departmentName">
            </preview-report2>
            <preview-report v-if="reportType == 8" id="preview-type3" class="report3" :preview-data="previewData"
              :day="day" :month="month" :year="year" :departmentName="departmentNameReport"
              :departmentUnit="departmentUnit" :leaveTypes="leaveTypes">
            </preview-report>
            <preview-report4 v-if="reportType == 9" id="preview-type4" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentNameReport">
            </preview-report4>
            <preview-report5 v-if="reportType == 10" id="preview-type5" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentNameReport">
            </preview-report5>
            <preview-report6 v-if="reportType == 11" id="preview-type7" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentNameReport">
            </preview-report6>
            <preview-report7 v-if="reportType == 12" id="preview-type8" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentNameReport">
            </preview-report7>
            <preview-report8 v-if="reportType == 13" id="preview-type9" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentNameReport">
            </preview-report8>
            <preview-report9 v-if="reportType == 14" id="preview-type9" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentNameReport">
            </preview-report9>
          </div>
        </div>
        <template v-slot:modal-footer="{ cancel }">
          <b-button @click="generatePDFmake()">{{ $t('label.exportPdf') }}</b-button>
          <b-button @click="exportReport()" v-if="userType == 2 || departmentIdParent == 16 || departmentIdParent == 2 ">{{ $t('label.exportExcel') }}</b-button>
          <!-- <b-button @click="print()">{{ $t('label.print') }}</b-button> -->
          <b-button @click="cancel()">{{ $t('label.cancel') }}</b-button>
        </template>
      </b-modal>
    </div>
    <!-- <div class="col-12">
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
        :paginate-elements-by-height="1000" :filename="fileName" :pdf-quality="1" :manual-pagination="false"
        pdf-format="a1" :pdf-margin="10" pdf-orientation="landscape" pdf-content-width="97%"
        @progress="onPdfProgress($event)" ref="html2Pdf">
        <section slot="pdf-content" class="pdf-content">
          <div class="contain" style="margin-left: 3%; margin-right: 3%; border-color: black;">
            <preview-report1 v-if="reportType == 6" id="preview-type1" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentNameReport" :departmentUnit="departmentUnit">
            </preview-report1>
            <preview-report2 v-if="reportType == 7" id="preview-type2" :preview-data="previewData" :day="day"
              :month="currentMonth" :year="year" :departmentName="departmentName">
            </preview-report2>
            <preview-report v-if="reportType == 8" id="preview-type3" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentNameReport" :departmentUnit="departmentUnit">
            </preview-report>
            <preview-report4 v-if="reportType == 9" id="preview-type4" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentNameReport">
            </preview-report4>
            <preview-report5 v-if="reportType == 10" id="preview-type5" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentNameReport">
            </preview-report5>
            <preview-report6 v-if="reportType == 11" id="preview-type5" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentNameReport">
            </preview-report6>
            <preview-report7 v-if="reportType == 12" id="preview-type5" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentNameReport">
            </preview-report7>
            <preview-report8 v-if="reportType == 13" id="preview-type6" :preview-data="previewData" :day="day"
              :month="month" :year="year" :departmentName="departmentNameReport">
            </preview-report8>
          </div>
        </section>
      </vue-html2pdf>
    </div> -->
    <pulse-loader :loading="loading"></pulse-loader>
  </div>
</template>

<script>
import apiClient from '@/axios'
import apiServer from '@/axiosServer'
import flatPickr from 'vue-flatpickr-component'
import VueApexCharts from "vue-apexcharts"
import FlatpickrLanguages from "flatpickr/dist/l10n"
import Treeselect from '@riophae/vue-treeselect'
import VueHtml2pdf from "vue-html2pdf"
import PreviewReport from '@/components/PreviewReport.vue'
import PreviewReport1 from '@/components/PreviewReport1.vue'
import PreviewReport2 from '@/components/PreviewReport2.vue'
import PreviewReport4 from '@/components/PreviewReport4.vue'
import PreviewReport5 from '@/components/PreviewReport5.vue'
import PreviewReport6 from '@/components/PreviewReport6.vue'
import PreviewReport7 from '@/components/PreviewReport7.vue'
import PreviewReport8 from '@/components/PreviewReport8.vue'
import PreviewReport9 from '@/components/PreviewReport9.vue'
import PulseLoader from '@/components/PulseLoader.vue'
import moment from 'moment'
import Vue from "vue"
import { Printd } from 'printd'
import 'jspdf-autotable';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

export default {
  components: {
    flatPickr,
    Treeselect,
    VueHtml2pdf,
    PreviewReport,
    PreviewReport1,
    PreviewReport2,
    PreviewReport4,
    PreviewReport5,
    PreviewReport6,
    PreviewReport7,
    PreviewReport8,
    PreviewReport9,
    PulseLoader
  },
  data() {
    return {
      isSupperAdmin: false,
      isComAdmin: false,
      loading: false,
      accountNumber: '',
      lateNumber: '',
      branchNumber: '',
      workingNumber: '',
      absentNumber: '',
      reportType: 1,
      dateSelect: '',
      placeId: null,
      branchs: [],
      listReportTypes: [
        /*{ text: this.$t('label.workDetail'), value: 1 },
        { text: this.$t('label.checkinDetail'), value: 2 },
        { text: this.$t('label.checkinByDay'), value: 3 },
        { text: this.$t('label.checkinMoreDay'), value: 4 },
        { text: this.$t('label.workDayDetail'), value: 5 },*/
        { label: this.$t('label.mealsInMonth'), value: 6 },
        { label: this.$t('label.detailCheckin'), value: 7 },
        { label: this.$t('label.detailInout'), value: 14 },
        { label: this.$t('label.generalReport'), value: 8 },
        { label: this.$t('label.lateReport'), value: 11 },
        { label: this.$t('label.earlyReport'), value: 12 },
        { label: this.$t('label.shiftReport'), value: 9 },
        { label: this.$t('label.leaveReport'), value: 10 },
        { label: this.$t('label.OTReport'), value: 13 },
      ],
      configDateTimePicker: {
        dateFormat: 'd/m/Y',
        mode: "range",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
      },
      options: {
        chart: {
          id: "vuechart-example",
          type: 'bar',

          height: 300,
          width: '100%'
        },
        xaxis: {
          categories: [],
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }]
      },
      series: [],
      infoCheckin: {},
      countInoutIINnfos: [],
      departmentId: null,
      // departmentIdBranch: null,
      positionId: null,
      aliasId: null,
      fullName: null,
      reportByMonth: false,
      reportByDate: false,
      reportByDepart: false,
      reportByPos: false,
      reportByUser: false,
      reportByLateEarly: false,
      month: 0,
      year: 0,
      day: 0,
      monthOptions: [],
      yearOptions: [],
      departmentOption: [],
      departments: [],
      positionOption: null,
      lateEarly: null,
      isOpenCodeRes: false,
      isOpenNameRes: false,
      modalPreview: false,
      nameResults: [],
      codeResults: [],
      previewData: [],
      fileName: '',
      departmentName: '',
      isMerged: false,
      isMerge2: false,
      departmentUnit: '',
      currentDay: 0,
      currentMonth: 0,
      currentYear: 0,
      departmentNameReport: '',
      msg: null,
      modalAttendance: false,
      departmentIdModal: null,
      departmentOptionModal: [],
      monthModal: 0,
      yearModal: 0,
      currentPage: 1,
      perPage: 10,
      dataTableShow: [],
      leaveTypes: null,
      leaveTypesText: null,
      leaveType1: null,
      fields: [
        {
          label: this.$t('label.departmentEmployee'),
          key: 'departmentEmployee',
          class: "align-middle text-center",
          sortable: false
        },
        {
          label: this.$t('label.monthAttendance'),
          key: 'date',
          class: "align-middle text-center",
          sortable: false
        },
        {
          label: this.$t('label.performedBy'),
          key: 'performedBy',
          class: "align-middle text-center",
          sortable: false
        },
        {
          label: this.$t('label.status'),
          key: 'status',
          class: "align-middle text-center",
          sortable: false
        },
        {
          label: this.$t('label.performedAt'),
          key: 'performed_at',
          class: "align-middle text-center",
          sortable: false
        },
      ],
      totalRecord: null,
      userType: null,
      departmentUnitSmall: '',
      departmentIdParent: ''
    };
  },
  created() {
    let today = new Date();

    this.day = today.getDate()
    this.currentMonth = today.getMonth() + 1;
    this.month = (today.getMonth() + 1).toString().padStart(2, '0');
    this.year = today.getFullYear();
    this.monthModal = (today.getMonth()).toString().padStart(2, '0');
    this.yearModal = today.getFullYear();
    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        this.monthOptions.push({ text: '0' + i, value: '0' + i });
      } else {
        this.monthOptions.push({ text: i, value: i });
      }
    }
    for (let j = this.year; j >= this.year - 10; j--) {
      this.yearOptions.push({ text: j, value: j });
    }
    if (this.$store.getters.superAdmin == true) {
      this.isSupperAdmin = true;
    }
    if (this.$store.getters.comAdmin == true) {
      this.isComAdmin = true;
    }
  },
  mounted() {
    this.userType = this.$store.getters.userType
    this.departmentIdParent = this.$store.getters.departmentId
    if (this.userType == 2) {
      this.departmentIdModal = 1
      this.departmentId = 1
    } else {
      this.departmentIdModal = null
      this.departmentId = null
    }
    if (this.isSupperAdmin || this.isComAdmin) {
      this.getListBranch();
    } else {
      this.getDepartment();
    }
    if (this.$store.getters.locale == 'vi') {
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"];
    }
    this.getProcessCheckinLog();
    this.getListLeaveType()
  },
  methods: {
    changeBranch() {
      this.getDepartment();
      this.departmentId = 1;
      this.getPosition();
      this.positionId = null;
    },
    async getListLeaveType() {
      let res;
      try {
        res = await apiServer.post("/api/v1/leave-type/get-list", {
        });
        if (res && res.data.data.result) {
          this.leaveTypes = res.data.data.result.map(function (val, index) {
            return { value: val.code, text: val.name };
          });
          this.leaveTypes.push({
            text: "Các lý do khác",
            value: "K"
          })
          this.leaveTypesText = res.data.data.result.map(function (val, index) {
            return { value: val.code, text: val.code + " - " + val.name };
          });
          this.leaveTypes1 = res.data.data.result
            .filter(function (val) {
              return val.type === 1;
            })
            .map(function (val, index) {
              return { value: val.code, text: val.code + " - " + val.name };
            });
        }
      } catch (error) {
        if (error.response.status === 400) {
          console.log(error.response.message);
        }
      }
    },
    async getListBranch() {
      let res;
      try {
        res = await apiServer.post('/api/v1/company/get-all-branch', {});
        if (res && res.data) {
          this.branchs.push({ value: null, text: this.$t('label.allBranches') });
          for (let br of res.data) {
            this.branchs.push({ value: br.placeID, text: br.branch_name });
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    },
    async getDepartment() {
      this.departmentOption = []
      this.departmentOptionModal = []
      this.departments = []
      let res
      try {
        res = await apiServer.post('api/v1/department/list', {
          placeId: this.placeId
        })
      } catch (error) { }
      if (res && res.data) {
        this.departmentOption = res.data;
        this.departmentOptionModal = res.data;
        this.departments = res.data;
      }
    },
    async processData() {
      this.loading = true;
      let res
      try {
        res = await apiServer.post('/api/v1/checkin/process-data', {
          month: this.monthModal,
          year: this.yearModal,
          departmentId: this.departmentIdModal,
          aliasID: this.aliasId
        })
      } catch (error) {
        console.log(error)
        this.loading = false
      }

      if (res && res.data) {
        this.modalAttendance = false;
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t('Chấm thành công'),
          position: "bottom-center",
        });
        this.resetForm();
      }
      this.getProcessCheckinLog();
      this.loading = false
    },
    async getDepartment() {
      this.departmentOption = []
      this.departmentOptionModal = []
      this.departments = []
      let res
      try {
        res = await apiServer.post('api/v1/department/list', {
          placeId: this.placeId
        })
      } catch (error) { }
      if (res && res.data) {
        this.departmentOption = res.data;
        this.departmentOptionModal = res.data;
        this.departments = res.data;
      }
    },
    async getProcessCheckinLog() {
      this.dataTableShow = []
      let res
      try {
        res = await apiServer.post('api/v1/checkin/process-checkin-logs', {
          currentPage: this.currentPage,
          rowPerPage: this.perPage
        })
      } catch (error) { }
      if (res && res.data) {
        this.dataTableShow = res.data.data;
        this.totalRecord = res.data.total
      }
    },
    async getPosition() {
      let res
      try {
        res = await apiServer.post('api/v1/position/list', {
          placeId: this.placeId
        })
      } catch (error) { }
      if (res && res.data) {
        this.positionOption = [];
        if (res.data.length > 0) {
          this.positionOption = res.data;
        }
      }
    },
    findDepartmentNameById() {
      this.departmentName = '';
      this.departmentUnit = '';
      for (let i = 0; i < this.departments.length; i++) {
        const department = this.departments[i];
        if (department.id === this.departmentId) {
          this.departmentName = department.name
        }
        const childrenDepartment = department.children?.find(child => child.id === this.departmentId);
        if (department.children && department.children.length > 0 && childrenDepartment) {
          this.departmentName = department.name;
          this.departmentUnit = childrenDepartment.name;
        }
        if (department.children) {
          for (let child of department.children) {
            const nestedChildrenDepartment = child.children?.find(nestedChild => nestedChild.id === this.departmentId);
            if (nestedChildrenDepartment) {
              this.departmentName = department.name;
              this.departmentUnit = child.name;
              this.departmentUnitSmall = nestedChildrenDepartment.name;
    
              return;
            }
          }
        }
      }

    },
    async getDataReport(type) {
      this.loading = true;
      let res;
      let timeStart = '';
      let timeEnd = '';
      // await this.getDepartment()
      // console.log(this.departmentId)
      // console.log(this.departments)
      this.findDepartmentNameById();
      if (this.dateSelect !== null && this.dateSelect !== '') {
        let dateArr = this.dateSelect.split(' ');
        timeEnd = this.convertTimestampSendDate(dateArr[0]);
        if (dateArr.length == 1) {
          timeStart = timeEnd;
        }
        else {
          timeStart = this.convertTimestampSendDate(dateArr[2]);
        }
        // timeEnd = timeEnd?.replace(/-/g, "/");
        // timeStart = timeStart?.replace(/-/g, "/");
        // console.log(timeEnd);
      }
      let departmentName = this.departmentUnit ? this.departmentUnit : this.departmentName
      if (this.departmentUnitSmall) {
        departmentName = this.departmentUnitSmall
      } else if (this.departmentUnit) {
        departmentName = this.departmentUnit
      } else {
        departmentName = this.departmentName
      }
      let removedAccents = departmentName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      let _departmentName = departmentName

      // let _departmentName = removedAccents.replace(/\s+/g, "").replace(/\s/g, "").replace(/\./g, "_");

      try {
        let api = '';
        if (this.reportType == 1) {
          api = '/api/v1/report/work-detail';
          this.fileName = 'report-work';
        } else if (this.reportType == 2) {
          api = '/api/v1/report/checkin-detail';
          this.fileName = 'report-checkin';
        } else if (this.reportType == 3) {
          api = '/api/v1/report/checkin-per-day';
          this.fileName = 'report-per-day';
        } else if (this.reportType == 4) {
          api = '/api/v1/report/checkin-month';
          this.fileName = 'report-per-month';
        } else if (this.reportType == 5) {
          api = '/api/v1/report/work-day-detail';
          this.fileName = 'report-work-detail';
        } else if (this.reportType == 6) {
          api = '/api/v1/report/meals-in-month';
          this.fileName = `Bao-cao-suat-com-ca_${_departmentName}_${this.month}-${this.year}`;
        } else if (this.reportType == 7) {
          api = '/api/v1/report/detail-checkin';
          this.fileName = `Bao-cao-chi-tiet-thoi-gian-vao-ra_${_departmentName}_${timeStart}_${timeEnd}`;
        } else if (this.reportType == 8) {
          api = '/api/v1/report/general-checkin';
          this.fileName = `Bang-cham-cong_${_departmentName}_${this.month}-${this.year}`;
        } else if (this.reportType == 9) {
          api = '/api/v1/report/shift';
          this.fileName = `Bao-cao-ca-lam-viec_${_departmentName}`;
        } else if (this.reportType == 11) {
          api = '/api/v1/report/minute-late-in-month';
          this.fileName = `Bao-cao-nhan-su-di-tre_${_departmentName}_${this.month}-${this.year}`;
        } else if (this.reportType == 12) {
          api = '/api/v1/report/minute-out-early-in-month';
          this.fileName = `Bao-cao-nhan-su-ve-som_${_departmentName}_${this.month}-${this.year}`;
        } else if (this.reportType == 10) {
          api = '/api/v1/report/leave';
          this.fileName = `Bang-giai-trinh-cham-cong_${_departmentName}_${this.month}-${this.year}`;
        } else if (this.reportType == 13) {
          api = '/api/v1/report/overtime';
          this.fileName = `Bang-tong-hop-cong-tang-ca_${_departmentName}_${this.month}-${this.year}`;
        } else if (this.reportType == 14) {
          api = '/api/v1/report/in-out-history';
          this.fileName = `Bao-cao-lich-su-vao-ra_${_departmentName}_${this.month}-${this.year}`;
        }
        if(this.userType == 3 && this.departmentId == null) {
          this.departmentId = this.departmentIdParent
        }
        res = await apiServer.post(api, {
          placeid: this.placeId,
          fromdate: timeEnd,
          todate: timeStart,
          date: timeStart,
          year: this.year,
          month: this.month,
          departmentid: this.departmentId,
          positionid: this.positionId,
          aliasid: this.aliasId,
          fullname: this.fullName,
          lateearly: this.lateEarly,
          mode: "preview"
        });
      }
      catch (error) {
        this.loading = false;
        console.log(error)
        if (error.response.status == 400) {
          console.log(error.response)
          let responseText = await error.response.data.error;
          console.log(error.response.data.error)
          if (error.response.data.error['department']) {
            alert(this.$t(error.response.data.error['department']));
          } else {
            alert(this.$t(error.response.data.error));
          }
        }
      }
      if (res && res.data) {
        this.loading = false;
        this.departmentNameReport = res.data.departmentName;
        this.previewData = res.data.bookjson[0];
        if (this.previewData.length == 1) {
          alert(this.$t('notify.message.noData'));
        } else {
          if (type == "preview") {
            this.modalPreview = true;
          }
        }
      }
    },
    mergeCell(sttCell) {
      if (sttCell) {
        var nextRow = sttCell.parentNode.nextElementSibling;
        if (nextRow) {
          var nextCell = nextRow.querySelector("td:first-child");
          if (nextCell) {
            nextCell.parentNode.removeChild(nextCell);
            sttCell.rowSpan = 2;
            console.log("RowSpan set successfully, and content of the next cell in the next row cleared.");
          } else {
            console.error("Next cell in the next row not found");
          }
        } else {
          console.error("Next row not found");
        }
      } else {
        console.error("Element not found");
      }
    },
    async previewreport() {
      await this.getDataReport("preview");
    },
    async exportReport() {
      this.loading = true;
      let res;
      let timeStart = '';
      let timeEnd = '';

      this.findDepartmentNameById();

      if (this.dateSelect !== null && this.dateSelect !== '') {
        let dateArr = this.dateSelect.split(' ');
        timeEnd = this.convertTimestampSendDate(dateArr[0]);
        if (dateArr.length == 1) {
          timeStart = timeEnd;
        }
        else {
          timeStart = this.convertTimestampSendDate(dateArr[2]);
        }
      }
      let departmentName = this.departmentUnit ? this.departmentUnit : this.departmentName
      if (this.departmentUnitSmall) {
        departmentName = this.departmentUnitSmall
      } else if (this.departmentUnit) {
        departmentName = this.departmentUnit
      } else {
        departmentName = this.departmentName
      }
      let removedAccents = departmentName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      let _departmentName = removedAccents.replace(/\s+/g, "").replace(/\s/g, "").replace(/\./g, "_");
      try {
        let api = '';
        if (this.reportType == 1) {
          api = '/api/v1/report/work-detail';
          this.fileName = 'report-work';
        } else if (this.reportType == 2) {
          api = '/api/v1/report/checkin-detail';
          this.fileName = 'report-checkin';
        } else if (this.reportType == 3) {
          api = '/api/v1/report/checkin-per-day';
          this.fileName = 'report-per-day';
        } else if (this.reportType == 4) {
          api = '/api/v1/report/checkin-month';
          this.fileName = 'report-per-month';
        } else if (this.reportType == 5) {
          api = '/api/v1/report/work-day-detail';
          this.fileName = 'report-work-detail';
        } else if (this.reportType == 6) {
          api = '/api/v1/report/meals-in-month';
          this.fileName = `Bao-cao-suat-com-ca_${_departmentName}_${this.month}-${this.year}`;
        } else if (this.reportType == 7) {
          api = '/api/v1/report/detail-checkin';
          this.fileName = `Bao-cao-chi-tiet-thoi-gian-vao-ra_${_departmentName}_${timeStart}_${timeEnd}`;
        } else if (this.reportType == 8) {
          api = '/api/v1/report/general-checkin';
          this.fileName = `Bang-cham-cong_${_departmentName}_${this.month}-${this.year}`;
        } else if (this.reportType == 9) {
          api = '/api/v1/report/shift';
          this.fileName = `Bao-cao-ca-lam-viec_${_departmentName}`;
        } else if (this.reportType == 11) {
          api = '/api/v1/report/minute-late-in-month';
          this.fileName = `Bao-cao-nhan-su-di-tre_${_departmentName}_${this.month}-${this.year}`;
        } else if (this.reportType == 12) {
          api = '/api/v1/report/minute-out-early-in-month';
          this.fileName = `Bao-cao-nhan-su-ve-som_${_departmentName}_${this.month}-${this.year}`;
        } else if (this.reportType == 10) {
          api = '/api/v1/report/leave';
          this.fileName = `Bang-giai-trinh-cham-cong_${_departmentName}_${this.month}-${this.year}`;
        } else if (this.reportType == 13) {
          api = '/api/v1/report/overtime';
          this.fileName = `Bang-tong-hop-cong-tang-ca_${_departmentName}_${this.month}-${this.year}`;
        } else if (this.reportType == 14) {
          api = '/api/v1/report/in-out-history';
          this.fileName = `Bao-cao-lich-su-vao-ra_${_departmentName}_${this.month}-${this.year}`;
        }
        if(this.userType == 3 && this.departmentId == null) {
          this.departmentId = this.departmentIdParent
        }
        res = await apiServer.post(api, {
          placeid: this.placeId,
          fromdate: timeEnd,
          todate: timeStart,
          date: timeStart,
          year: this.year,
          month: this.month,
          departmentid: this.departmentId,
          positionid: this.positionId,
          aliasid: this.aliasId,
          fullname: this.fullName,
          lateearly: this.lateEarly
        }, {
          responseType: 'blob'
        }).then((response) => {
          this.loading = false;
          const url = URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', this.fileName)
          document.body.appendChild(link)
          link.click();
        });
      } catch (error) {
        console.log(error);
        if (error.response.status === 400) {
          this.loading = false;
          console.log(error.response.data.text())
          let responseText = await error.response.data.text();
          console.log(JSON.parse(responseText).error)
          if (JSON.parse(responseText).error['department']) {
            alert(this.$t(JSON.parse(responseText).error['department']));
          } else {
            alert(this.$t(JSON.parse(responseText).error));
          }
        }
      }
      this.departmentName = '';
      this.departmentUnit = '';
      this.departmentUnitSmall = '';
    },

    generatePDF() {
      this.loading = true;
      this.$refs.html2Pdf.generatePdf();
    },
    onPdfProgress(event) {
      if (event == 100) {
        this.loading = false;
        this.modalPreview = false;
      }
    },
    async generatePDFmake() {
      try {
        this.previewData = null;
        await this.getDataReport(null);
        let dataTable = this.previewData.map(row => {
          if (row[0] === null) {
            return row.slice(1);
          }
          return row;
        });
        if (this.reportType == 9) {
          dataTable.splice(0, 3)
          dataTable.splice(-2)
          console.log(dataTable)
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          const documentDefinition = {
            pageSize: 'A4',
            content: [
              {
                text: `CÔNG TY CỔ PHẦN CAO SU ĐÀ NẴNG`, style: 'rightheader',
              },
              {
                text: !this.departmentNameReport ? '' : `Đơn vị: ${this.departmentNameReport}`, style: 'rightheader'
              },
              {
                text: 'BẢNG TỔNG HỢP CA LÀM VIỆC ' + this.departmentNameReport, style: 'subheader'
              },
              {
                style: 'table',
                table: {
                  headerRows: 1,
                  widths: [25, 30, 125, '*', '*', '*'],
                  body: dataTable
                }
              },
              { text: '..... Ngày ... tháng ... năm ....', style: 'date' },
              {
                columns: [
                  {
                    width: 120,
                    text: ''
                  },
                  {
                    width: 200,
                    text: 'PHÒNG NHÂN SỰ - TIỀN LƯƠNG',
                    style: 'bold'
                  },
                  {
                    width: 100,
                    text: ''
                  },
                  {
                    width: '*',
                    text: 'TRƯỞNG ĐƠN VỊ',
                    style: 'bold'
                  },
                ]
              },
              // { text: 'Phòng NSTL                                                                                         Đơn vị', style: 'footer' },
            ],
            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
              },
              subheader: {
                fontSize: 14,
                bold: true,
                margin: [0, 10, 0, 5],
                alignment: 'center'
              },
              rightheader: {
                fontSize: 10,
                bold: true,
              },
              table: {
                margin: [0, 5, 0, 15],
              },
              tableHeader: {
                bold: true,
                color: 'black',
                alignment: 'center'
              },
              bold: {
                bold: true,
              },
              date: {
                alignment: 'right',
              },
              footer: {
                alignment: 'center'
              }
            },
            defaultStyle: {
              fontSize: 10,
            }
          };
          const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
          pdfDocGenerator.download(this.fileName + ".pdf");
        } else if (this.reportType == 6) {
          dataTable = [dataTable[0].map(cell => ({ text: cell, style: 'tableHeader' })), ...dataTable.slice(1)];
          dataTable.splice(0, 1);


          dataTable = dataTable.map((row) => {
            return row.map((cell) => {
              return cell === null ? '' : cell;
            });
          });
          dataTable = dataTable.map((row, rowIndex) => {
            return row.map((cell, cellIndex) => {
              if (cellIndex === 2) {
                return { text: cell, alignment: "left" };
              }
              return cell;
            });

          });
          dataTable = dataTable.map((row, rowIndex) => {
            if (rowIndex === 0) {
              return row.map((cell, cellIndex) => {
                if (cellIndex === 0) {
                  return { text: 'STT', border: [true, true, true, false] };
                } else if (cellIndex === 1) {
                  return { text: 'Mã NV', border: [true, true, true, false] };
                } else if (cellIndex === 2) {
                  return { text: 'Họ và tên', border: [true, true, true, false] };
                }
                return cell;
              });
            }
            return row;
          });

          dataTable = dataTable.map((row, rowIndex) => {
            if (rowIndex === 1) {
              return row.map((cell, cellIndex) => {
                if (cellIndex === 0) {
                  return { text: '', border: [true, false, true, true] };
                } else if (cellIndex === 1) {
                  return { text: '', border: [true, false, true, true] };
                } else if (cellIndex === 2) {
                  return { text: '', border: [true, false, true, true] };
                }
                return cell;
              });
            }
            return row;
          });

          dataTable = dataTable.map((col, colIndex) => {
            if (colIndex === 0) {
              return col.map((cell, cellIndex) => {
                if (cellIndex === col.length - 1) {
                  return { text: cell, rowSpan: 2 };
                }
                return cell;
              });
            }
            return col;
          });

          console.log(dataTable)
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          const documentDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            content: [
              {
                text: `CÔNG TY CỔ PHẦN CAO SU ĐÀ NẴNG`, style: 'rightheader',
              },
              {
                text: !this.departmentNameReport ? '' : `Đơn vị: ${this.departmentNameReport}`, style: 'subheader'
              },
              { text: `BẢNG BÁO CÁO SỐ XUẤT CƠM CA THÁNG ${this.month}/${this.year}`, style: 'nameTable' },

              {
                style: this.departmentId === 1 ? 'smallTable' : 'table',
                table: {
                  body: dataTable
                }
              },
              {
                table: {
                  widths: [430, 300],
                  body: [
                    [' ', { text: 'Đà Nẵng, Ngày ... tháng ... năm ....', style: 'date' }],
                  ]
                },
                layout: 'noBorders'
              },
              { text: 'PHÒNG NHÂN SỰ TIỀN LƯƠNG                                      THỦ TRƯỞNG ĐƠN VỊ                                                              NGƯỜI KIỂM TRA', style: 'footer' },
            ],
            styles: {
              nameTable: {
                fontSize: 14,
                bold: true,
                margin: [5, 5, 5, 5],
                alignment: 'center'
              },
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
              },
              subheader: {
                fontSize: 12,
                bold: true,
                alignment: 'left'
              },
              rightheader: {
                fontSize: 12,
                bold: true,
              },
              table: {
                alignment: 'center',
                margin: [0, 0, 0, 5],
                // width: '100%',
              },
              smallTable: {
                alignment: 'center',
                margin: [10, 0, 0, 15],
                fontSize: 5
              },
              tableHeader: {
                fontSize: 10,
                bold: true,
                color: 'black',
                alignment: 'center'
              },
              symbol: {
                fontSize: 10,
                margin: [20, 0, 0, 5],
                alignment: 'left',
              },
              date: {
                fontSize: 8,
                alignment: 'right',
              },
              footer: {
                margin: [0, 0, 50, 5],
                fontSize: 9,
                alignment: 'right',
                bold: true,
              },
            },
            defaultStyle: {
              fontSize: 7,
            }

          };

          const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

          pdfDocGenerator.download(this.fileName + ".pdf");
        } else if (this.reportType == 7) {
          dataTable = [dataTable[0].map(cell => ({ text: cell, style: 'tableHeader' })), ...dataTable.slice(1)];
          dataTable.splice(0, 2);
          dataTable = dataTable.map((row) => {
            return row.map((cell) => {
              return cell === null ? '' : cell;
            });
          });
          let hasMaNVFlag = false;

          dataTable = dataTable.map((row) => {
            const hasMaNV = row.some((cell) => typeof cell === 'string' && cell.includes('Mã NV: '));
            const hasHoTen = row.some((cell) => typeof cell === 'string' && cell.includes('Họ tên: '));
            const hasTongGioCong = row.some((cell) => typeof cell === 'string' && cell.includes('Tổng giờ công:'));
            const hasTongCong = row.some((cell) => typeof cell === 'string' && cell.includes('Tổng công:'));

            if (hasMaNV || hasHoTen || hasTongGioCong || hasTongCong) {
              let newRow = row.map((cell) => {
                if (typeof cell === 'string' && (cell.includes('Họ tên:') || cell.includes('Tổng giờ công:') || cell.includes('Tổng công:'))) {
                  return { text: cell, colSpan: 2, border: [false, false, false, false] }
                } else if (typeof cell === 'string' && cell.includes('Mã NV: ')) {
                  hasMaNVFlag = true;
                  return { text: cell, border: [false, false, false, false] }
                } else {
                  return typeof cell === 'string' ? { text: cell, border: [false, false, false, false] } : cell;
                }
              });

              // if (hasMaNVFlag) {
              //   hasMaNVFlag = false;
              //   newRow.forEach((cell) => {
              //     cell.margin = [0, 12, 0, 0];
              //   });
              // }
              if (hasMaNVFlag) {
                hasMaNVFlag = false;
                newRow = newRow.map((cell) => {
                  return { ...cell, margin: [0, 12, 0, 0] };
                });
              }
              return newRow;
            }

            return row;
          });

          dataTable = dataTable.filter((row) => {
            const hasToBoPhan = row.some((cell) => typeof cell === 'string' && cell.includes('Tổ (bộ phận) :'));
            return !hasToBoPhan;
          });

          dataTable = dataTable.map((row, index) => {
            if (index === dataTable.length - 1) {
              return row.map(cell => {
                return { text: cell, bold: true };
              });
            }
            return row;
          });

          console.log(dataTable)
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          const documentDefinition = {
            pageSize: 'A4',
            content: [
              {
                text: `CÔNG TY CỔ PHẦN CAO SU ĐÀ NẴNG`, style: 'rightheader'
              },
              {
                text: `CHI TIẾT CHẤM CÔNG ${this.departmentUnit ? this.departmentUnit.toUpperCase() : this.departmentNameReport.toUpperCase()}`, style: 'header'
              },
              { text: `Đà Nẵng, ngày ${this.day} tháng ${this.currentMonth} năm ${this.year}`, style: 'date' },
              { text: `Kính gửi ...`, alignment: 'center' },
              {
                style: 'table1',
                table: {
                  widths: [515],
                  body: [
                    [`Đơn vị : ${this.departmentNameReport}`]
                  ]
                }
              },
              {
                style: 'table1',
                table: {
                  widths: [515],
                  body: [
                    [`Tổ (bộ phận) : ${this.departmentUnit}`]
                  ]
                }
              },
              {
                style: 'table',
                table: {
                  widths: [70, 125, 120, 70, 95, 10],
                  body: dataTable
                }
              },
              { text: '..... Ngày ... tháng ... năm ....', style: 'date' },
              {
                table: {
                  widths: [10, 180, 190, 100, 10],
                  body: [
                    [' ', { text: `PHÒNG NHÂN SỰ TIỀN LƯƠNG`, style: 'footer' }, { text: `THỦ TRƯỞNG ĐƠN VỊ`, style: 'footer' }, { text: `NGƯỜI KIỂM TRA`, style: 'footer' }],
                  ]
                },
                layout: 'noBorders'
              },
            ],
            styles: {
              header: {
                fontSize: 13,
                bold: true,
                margin: [0, 0, 0, 10],
                alignment: 'center',
                uppercase: true
              },
              subheader: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              rightheader: {
                fontSize: 12,
                bold: true,
                margin: [0, 10, 0, 5],
              },
              table: {
                margin: [0, 5, 0, 15],
              },
              tableHeader: {
                bold: true,
                color: 'black',
                alignment: 'center'
              },
              date: {
                alignment: 'right',
              },
              footer: {
                alignment: 'center',
                bold: true,
              },
              table1: {
                bold: true,
              }
            },
            defaultStyle: {
              fontSize: 10,
            }
          };

          const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

          pdfDocGenerator.download(this.fileName + ".pdf");
        }
        else if (this.reportType == 14) {

          dataTable = [dataTable[0].map(cell => ({ text: cell, style: 'tableHeader' })), ...dataTable.slice(1)];
          dataTable.splice(0, 1);
          dataTable = dataTable.map((row) => {
            return row.map((cell) => {
              return cell === null ? '' : cell;
            });
          });
          let hasMaNVFlag = false;

          dataTable = dataTable.map((row) => {
            const hasMaNV = row.some((cell) => typeof cell === 'string' && cell.includes('Mã NV: '));
            const hasHoTen = row.some((cell) => typeof cell === 'string' && cell.includes('Họ tên: '));
            const hasTongGioCong = row.some((cell) => typeof cell === 'string' && cell.includes('Ca làm việc:'));

            if (hasMaNV || hasHoTen || hasTongGioCong) {
              let newRow = row.map((cell) => {
                if (typeof cell === 'string' && (cell.includes('Họ tên:') || cell.includes('Ca làm việc:'))) {
                  return { text: cell, colSpan: 1, border: [false, false, false, false] }
                } else if (typeof cell === 'string' && cell.includes('Mã NV: ')) {
                  hasMaNVFlag = true;
                  return { text: cell, border: [false, false, false, false] }
                } else {
                  return typeof cell === 'string' ? { text: cell, border: [false, false, false, false] } : cell;
                }
              });

              // if (hasMaNVFlag) {
              //   hasMaNVFlag = false;
              //   newRow.forEach((cell) => {
              //     cell.margin = [0, 12, 0, 0];
              //   });
              // }
              if (hasMaNVFlag) {
                hasMaNVFlag = false;
                newRow = newRow.map((cell) => {
                  return { ...cell, margin: [0, 12, 0, 0] };
                });
              }
              return newRow;
            }

            return row;
          });

          dataTable = dataTable.filter((row) => {
            const hasToBoPhan = row.some((cell) => typeof cell === 'string' && cell.includes('Tổ (bộ phận) :'));
            return !hasToBoPhan;
          });

          dataTable = dataTable.map((row, index) => {
            if (index === dataTable.length - 1) {
              return row.map(cell => {
                return { text: cell, bold: true };
              });
            }
            return row;
          });

          console.log(dataTable)
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          const documentDefinition = {
            pageSize: 'A4',
            content: [
              {
                text: `CÔNG TY CỔ PHẦN CAO SU ĐÀ NẴNG`, style: 'rightheader'
              },
              {
                text: `LỊCH SỬ VÀO RA ${this.departmentUnit ? this.departmentUnit.toUpperCase() : this.departmentNameReport.toUpperCase()}`, style: 'header'
              },
              { text: `Đà Nẵng, ngày ${this.day} tháng ${this.currentMonth} năm ${this.year}`, style: 'date' },
              { text: `Kính gửi ...`, alignment: 'center' },
              {
                style: 'table1',
                table: {
                  widths: [515],
                  body: [
                    [`Đơn vị : ${this.departmentNameReport}`]
                  ]
                }
              },
              {
                style: 'table1',
                table: {
                  widths: [515],
                  body: [
                    [`Tổ (bộ phận) : ${this.departmentUnit}`]
                  ]
                }
              },
              {
                style: 'table',
                table: {
                  widths: [200, 100, 100, 100],
                  body: dataTable
                }
              },
              { text: '..... Ngày ... tháng ... năm ....', style: 'date' },
              {
                table: {
                  widths: [10, 180, 190, 100],
                  body: [
                    [' ', { text: `PHÒNG NHÂN SỰ TIỀN LƯƠNG`, style: 'footer' }, { text: `THỦ TRƯỞNG ĐƠN VỊ`, style: 'footer' }, { text: `NGƯỜI KIỂM TRA`, style: 'footer' }],
                  ]
                },
                layout: 'noBorders'
              },
            ],
            styles: {
              header: {
                fontSize: 13,
                bold: true,
                margin: [0, 0, 0, 10],
                alignment: 'center',
                uppercase: true
              },
              subheader: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              rightheader: {
                fontSize: 12,
                bold: true,
                margin: [0, 10, 0, 5],
              },
              table: {
                margin: [0, 5, 0, 15],
              },
              tableHeader: {
                bold: true,
                color: 'black',
                alignment: 'center'
              },
              date: {
                alignment: 'right',
              },
              footer: {
                alignment: 'center',
                bold: true,
              },
              table1: {
                bold: true,
              }
            },
            defaultStyle: {
              fontSize: 10,
            }
          };

          const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

          pdfDocGenerator.download(this.fileName + ".pdf");


        } else if (this.reportType == 8) {
          dataTable = [dataTable[0].map(cell => ({ text: cell, style: 'tableHeader' })), ...dataTable.slice(1)];

          dataTable?.splice(0, 4);
          dataTable?.splice(-(this.leaveTypes.length / 2 + 3));
          dataTable = dataTable.map((row) => {
            return row.map((cell) => {
              return cell === null ? '' : cell;
            });
          });
          dataTable = dataTable.map((row, rowIndex) => {
            return row.map((cell, cellIndex) => {
              if (cellIndex === 1) {
                return { text: cell, alignment: "left", width: 500 };
              } else if (cellIndex === 0) {
                return { text: cell, width: 10 };
              }
              return cell;
            });

          });
          const boldRowStyle = { bold: true };
          const widthName = { bold: true, width: "180px" };

          dataTable = dataTable.map((row, rowIndex) => {
            if (rowIndex === 0) {
              return row.map((cell, cellIndex) => {
                if (cellIndex === 0) {
                  return { text: 'STT', border: [true, true, true, false], style: boldRowStyle };
                } else if (cellIndex === 1) {
                  return { text: 'Họ và tên', border: [true, true, true, false], style: widthName };
                } else if (cellIndex === 2) {
                  return { text: 'Mã NV', border: [true, true, true, false], style: boldRowStyle };
                } else if (cellIndex === 3) {
                  return { text: 'Hệ số', border: [true, true, true, false], style: boldRowStyle };
                } else if (cellIndex === 4) {
                  return { text: 'HTNV', border: [true, true, true, false], style: boldRowStyle };
                }
                return cell;
              });
            }
            return row;
          });

          dataTable = dataTable.map((row, rowIndex) => {
            if (rowIndex === 1) {
              return row.map((cell, cellIndex) => {
                if (cellIndex === 0) {
                  return { text: '', border: [true, false, true, true] };
                } else if (cellIndex === 1) {
                  return { text: '', border: [true, false, true, true] };
                } else if (cellIndex === 2) {
                  return { text: '', border: [true, false, true, true] };
                } else if (cellIndex === 3) {
                  return { text: '', border: [true, false, true, true] };
                } else if (cellIndex === 4) {
                  return { text: '', border: [true, false, true, true] };
                }
                return cell;
              });
            }
            return row;
          });


          dataTable = dataTable.map((col, colIndex) => {
            if (colIndex === 0) {
              return col.map((cell, cellIndex) => {
                if (cellIndex === col.length - this.leaveTypes1?.length) {
                  return { text: 'Ngày nghỉ', colSpan: this.leaveTypes1?.length };
                }
                else if (cellIndex === col.length - this.leaveTypes1?.length - 2) {
                  return { text: 'Thực tế', colSpan: 2 }
                }
                else if (cellIndex === col.length - this.leaveTypes1?.length - 4) {
                  return { text: 'Máy chấm', colSpan: 2 }
                }

                return cell;
              });
            }
            return col;
          });
          let widthTable = []
          if (dataTable[2].length === 42) {
            widthTable = [10, 68, 14, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 9.8, 13, 13, 13, 13, 13, 13, 13, 4, 4, 4, 4, 4, 4];
          } else if (dataTable[2].length === 43) {
            widthTable = [10, 68, 14, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 9.2, 13, 13, 13, 13, 13, 13, 13, 4, 4, 4, 4, 4, 4];
          } else if (dataTable[2].length === 44) {
            widthTable = [10, 68, 14, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 8.9, 13, 13, 13, 13, 13, 13, 13, 4, 4, 4, 4, 4, 4];
          } else if (dataTable[2].length === 45) {
            widthTable = [10, 68, 14, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 8.7, 13, 13, 13, 13, 13, 13, 13, 4, 4, 4, 4, 4, 4];
          } else if (dataTable[2].length === 46) {
            widthTable = [10, 68, 14, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 13, 13, 13, 13, 13, 13, 13, 4, 4, 4, 4, 4, 4, 4];
          } else if (dataTable[2].length === 47) {
            widthTable = [10, 68, 14, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 13, 13, 13, 13, 13, 13, 13, 4, 4, 4, 4, 4, 4, 4, 4];
          } else if (dataTable[2].length === 48) {
            widthTable = [10, 68, 14, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 13, 13, 13, 13, 13, 13, 13, 4, 4, 4, 4, 4, 4, 4, 4, 4];
          } else if (dataTable[2].length === 49) {
            widthTable = [10, 68, 14, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 8.3, 13, 13, 13, 13, 13, 13, 13, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4];
          }
          const leaveTypeRows = [];
          for (let i = 0; i < this.leaveTypesText.length; i += 2) {
            leaveTypeRows.push([
              { text: this.leaveTypesText[i], style: 'symbol' },
              { text: this.leaveTypesText[i + 1] || 'K - Các lý do khác', style: 'symbol' }
            ]);
          }
          if (this.leaveTypesText.length % 2 == 0) {
            leaveTypeRows.push([{ text: "K - Các lý do khác", style: 'symbol' },
            { text: '', style: 'symbol' }
            ]);
          }
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          const documentDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            content: [
              {
                text: `CÔNG TY CỔ PHẦN CAO SU ĐÀ NẴNG`, style: 'rightheader',
              },
              {
                text: !this.departmentNameReport ? '' : `Đơn vị: ${this.departmentNameReport}`, style: 'subheader'
              },
              { text: `BẢNG CHẤM CÔNG THÁNG  ${this.month}/${this.year}`, style: 'nameTable' },
              { text: `(Các đơn vị nộp Bảng chấm công về P.Nhân sự tiền lương chậm nhất là ngày 3 hàng tháng)`, style: 'tableHeader' },

              {
                style: this.departmentId === 1 ? 'smallTable' : 'table',
                table: {
                  widths: widthTable,
                  body: dataTable
                },
                layout: {
                  hLineWidth: function (i, node) { return 0.1; },
                  vLineWidth: function (i, node) { return 0.1; },
                },
                padding: [0, 0, 0, 0],
                bold: true,
              },
              {
                table: {
                  widths: [630, 1000],
                  body: [
                    [' ', { text: 'Đà Nẵng, Ngày ... tháng ... năm ....', style: 'date' }],
                  ]
                },
                layout: 'noBorders'
              },
              {
                table: {
                  widths: [230, 170, 170, 170],
                  body: [
                    [
                      { text: 'Các kí hiệu:', style: 'symbol' },
                      { text: `PHÒNG NHÂN SỰ TIỀN LƯƠNG`, style: 'footer' },
                      { text: `THỦ TRƯỞNG ĐƠN VỊ`, style: 'footer' },
                      { text: `NGƯỜI KIỂM TRA`, style: 'footer' }
                    ],
                  ]
                },
                layout: 'noBorders'
              },
              {
                table: {
                  widths: [100, 150],
                  body: leaveTypeRows
                },
                layout: 'noBorders'
              }
            ],
            styles: {
              nameTable: {
                fontSize: 11,
                bold: true,
                alignment: 'center'
              },
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
              },
              subheader: {
                fontSize: 10,
                bold: true,
                margin: [-32, 0, 0, 15],
                alignment: 'left'
              },
              rightheader: {
                fontSize: 9,
                bold: true,
                margin: [-32, 0, 0, 0],
              },
              table: {
                alignment: 'center',
                margin: [-32, 0, 0, 0],
              },
              smallTable: {
                alignment: 'center',
                margin: [-32, 0, 0, 0],
                fontSize: 5
              },
              tableHeader: {
                fontSize: 9,
                bold: true,
                color: 'black',
                alignment: 'center',
                margin: [0, 0, 0, 5],
              },
              symbol: {
                fontSize: 5,
                margin: [0, 0, 0, 2],
                alignment: 'left',
                italics: true,
              },
              date: {
                fontSize: 7,
              },
              footer: {
                margin: [0, 0, 50, 0],
                fontSize: 8,
                alignment: 'right',
                bold: true,
              }
            },
            defaultStyle: {
              fontSize: 5.8,
              margin: [0, 0, 0, 0],
              padding: [0, 0, 0, 0]

            }

          };
          const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
          pdfDocGenerator.download(this.fileName + ".pdf");
          // }
        } else if (this.reportType == 11 || this.reportType == 12) {
          dataTable = [dataTable[0].map(cell => ({ text: cell, style: 'tableHeader' })), ...dataTable.slice(1)];
          dataTable.splice(0, 1);
          dataTable = dataTable.map((row) => {
            return row.map((cell) => {
              return cell === null ? '' : cell;
            });
          });
          dataTable = dataTable.map((row) => {
            const hasMaNV = row.some((cell) => typeof cell === 'string' && cell.includes('Mã NV: '));
            const hasHoTen = row.some((cell) => typeof cell === 'string' && cell.includes('Họ và tên: '));
            const hasNgayTinhCong = row.some((cell) => typeof cell === 'string' && cell.includes('Ngày tính công'));

            if (hasMaNV || hasHoTen) {
              return row.map((cell) => {
                return typeof cell === 'string' ? { text: cell, border: [false, false, false, false] } : cell;
              });
            }
            if (hasNgayTinhCong) {
              return row.map((cell) => {
                return typeof cell === 'string' ? { text: cell, bold: true } : cell;
              });
            }

            return row;
          });
          console.log(dataTable)
          let nameReport
          if (this.reportType == 11) {
            nameReport = `BÁO CÁO SỐ PHÚT ĐI TRỄ THÁNG`
          } else if (this.reportType == 12) {
            nameReport = `BÁO CÁO SỐ PHÚT VỀ SỚM THÁNG`
          }
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          const documentDefinition = {
            pageSize: 'A4',
            content: [
              {
                table: {
                  widths: [270, 0],
                  body: [
                    [{ text: `CÔNG TY CỔ PHẦN CAO SU ĐÀ NẴNG`, style: 'subheader' }, ''],
                    [{ text: !this.departmentNameReport ? '' : `Đơn vị: ${this.departmentNameReport}`, style: 'subheader' }, ''],
                  ]
                },
                layout: 'noBorders'
              },
              {
                text: `${nameReport} ${this.month}/${this.year}`, style: 'header'
              },
              {
                style: 'table',
                table: {
                  widths: [140, 100, 80, 83, 60],
                  body: dataTable
                }
              },
              { text: '..... Ngày ... tháng ... năm ....', style: 'date' },
              {
                table: {
                  widths: [100, 280, 120],
                  body: [
                    [' ', { text: `PHÒNG NHÂN SỰ - TIỀN LƯƠNG`, style: 'footer' }, { text: `NGƯỜI KIỂM TRA`, style: 'footer' }],
                  ]
                },
                layout: 'noBorders'
              },
            ],
            styles: {
              header: {
                fontSize: 13,
                bold: true,
                margin: [0, 0, 0, 10],
                alignment: 'center'
              },
              subheader: {
                fontSize: 12,
                bold: true,
                alignment: 'center'
              },
              rightheader: {
                fontSize: 12,
                bold: true,
                margin: [0, 10, 0, 5],
              },
              table: {
                margin: [0, 5, 0, 15],
              },
              tableHeader: {
                bold: true,
                color: 'black',
                alignment: 'center'
              },
              date: {
                alignment: 'right',
              },
              footer: {
                alignment: 'center',
                bold: true,
              },
              table1: {
                bold: true,
              }
            },
            defaultStyle: {
              fontSize: 10,
            }
          };

          const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

          pdfDocGenerator.download(this.fileName);
        } else if (this.reportType == 10) {
          dataTable = dataTable.slice(3, -7)
          dataTable = dataTable.map((row) => {
            let newRow = [];
            let rowShouldBeBold = row.some((cell) => {
              return typeof cell === 'string' && (
                cell.includes('STT') ||
                cell.includes('Công tác; Hội họp; Tập huấn; Việc ngoài công ty; Bù công; ...') ||
                cell.includes('Chế độ (Phép; Ốm; Thai sản; Nghỉ việc riêng có lương; Nghỉ việc riêng không lương;...)') ||
                cell.includes('Các lý do khác')
              );
            });

            for (let i = 0; i < row.length; i++) {
              const cell = row[i];
              const hasMaNV = typeof cell === 'string' && cell.includes('Công tác; Hội họp; Tập huấn; Việc ngoài công ty; Bù công; ...');
              const hasHoTen = typeof cell === 'string' && cell.includes('Chế độ (Phép; Ốm; Thai sản; Nghỉ việc riêng có lương; Nghỉ việc riêng không lương;...)');
              const hasNgayTinhCong = typeof cell === 'string' && cell.includes('Các lý do khác');
              const hasSTT = typeof cell === 'string' && cell.includes('STT');
              if (hasMaNV || hasHoTen || hasNgayTinhCong) {
                newRow.push({ text: cell, bold: true, alignment: 'left', colSpan: 4 });
                i += 3;
              } else if (rowShouldBeBold) {
                newRow.push(typeof cell === 'string' ? { text: cell, bold: true } : { ...cell, bold: true });
              } else if (i == 1) {
                newRow.push({ text: cell, alignment: 'left' });
              } else {
                newRow.push(cell);
              }
            }

            return newRow;
          });
          console.log(dataTable)
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          const documentDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            content: [
              {
                text: `CÔNG TY CỔ PHẦN CAO SU ĐÀ NẴNG`, style: 'rightheader',
              },
              {
                text: !this.departmentNameReport ? '' : `Đơn vị: ${this.departmentNameReport}`, style: 'rightheader'
              },
              {
                text: `BẢNG GIẢI TRÌNH CHẤM CÔNG THÁNG ${this.month}/${this.year}`, style: 'subheader'
              },
              {
                style: 'table',
                table: {
                  headerRows: 1,
                  widths: [20, 170, 60, 150, 320],
                  body: dataTable
                }
              },
              { text: 'Đà Nẵng, ngày .. tháng .. năm ....', style: 'date' },
              {
                table: {
                  widths: [620, 120],
                  body: [
                    [' ', { text: `TRƯỞNG ĐƠN VỊ`, style: 'footer' }],
                  ]
                },
                layout: 'noBorders'
              },

              {
                text: ` Ghi chú:`, style: 'bold',
              },

              {
                text: ` - Công tác: Đi công tác tại các địa phương khác không thể thực hiện chấm công.`, style: 'note'
              },

              {
                text: ` - Hội họp, Tập huấn: Đi tham gia hội họp, tập huấn tại Đà Nẵng không thể thực hiện chấm công.`, style: 'note'
              },

              {
                text: ` - Làm việc ngoài Công ty: Đi tham gia làm việc bên ngoài Công ty tại Đà Nẵng không thể chấm công đúng thời gian quy định.`, style: 'note'
              },

              {
                text: ` - Bù công: Người lao động được phép bù công theo Quy định của Công ty.`, style: 'note'
              },

              // { text: 'Phòng NSTL                                                                                         Đơn vị', style: 'footer' },
            ],
            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
              },
              subheader: {
                fontSize: 13,
                bold: true,
                margin: [0, 10, 0, 5],
                alignment: 'center'
              },
              table: {
                margin: [0, 5, 0, 15],
                alignment: 'center'
              },
              tableHeader: {
                bold: true,
                color: 'black',
                alignment: 'center'
              },
              date: {
                alignment: 'right',
              },
              bold: {
                fontSize: 9,
                bold: true,
              },
              note: {
                fontSize: 9,
              },
              rightheader: {
                bold: true,
              },
              footer: {
                bold: true,
                alignment: 'center'
              }
            },
            defaultStyle: {
              fontSize: 10,
            }
          };
          const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
          pdfDocGenerator.download(this.fileName + ".pdf");
        } else if (this.reportType == 13) {
          dataTable = [dataTable[0].map(cell => ({ text: cell, style: 'tableHeader' })), ...dataTable.slice(1)];
          dataTable.splice(0, 1);


          dataTable = dataTable.map((row) => {
            return row.map((cell) => {
              return cell === null ? '' : cell;
            });
          });
          dataTable = dataTable.map((row, rowIndex) => {
            return row.map((cell, cellIndex) => {
              if (cellIndex === 2) {
                return { text: cell, alignment: "left" };
              }
              return cell;
            });

          });
          dataTable = dataTable.map((row, rowIndex) => {
            if (rowIndex === 0) {
              return row.map((cell, cellIndex) => {
                if (cellIndex === 0) {
                  return { text: 'STT', border: [true, true, true, false] };
                } else if (cellIndex === 1) {
                  return { text: 'Mã NV', border: [true, true, true, false] };
                } else if (cellIndex === 2) {
                  return { text: 'Họ và tên', border: [true, true, true, false] };
                }
                return cell;
              });
            }
            return row;
          });

          dataTable = dataTable.map((row, rowIndex) => {
            if (rowIndex === 1) {
              return row.map((cell, cellIndex) => {
                if (cellIndex === 0) {
                  return { text: '', border: [true, false, true, true] };
                } else if (cellIndex === 1) {
                  return { text: '', border: [true, false, true, true] };
                } else if (cellIndex === 2) {
                  return { text: '', border: [true, false, true, true] };
                }
                return cell;
              });
            }
            return row;
          });

          dataTable = dataTable.map((col, colIndex) => {
            if (colIndex === 0) {
              return col.map((cell, cellIndex) => {
                if (cellIndex === col.length - 1) {
                  return { text: cell, rowSpan: 2 };
                }
                return cell;
              });
            }
            return col;
          });

          console.log(dataTable)
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          const documentDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            content: [
              {
                text: `CÔNG TY CỔ PHẦN CAO SU ĐÀ NẴNG`, style: 'rightheader',
              },
              {
                text: !this.departmentNameReport ? '' : `Đơn vị: ${this.departmentNameReport}`, style: 'subheader'
              },
              { text: `BẢNG TỔNG HỢP CÔNG TĂNG CA THÁNG ${this.month}/${this.year}`, style: 'nameTable' },

              {
                style: this.departmentId === 1 ? 'smallTable' : 'table',
                table: {
                  body: dataTable
                }
              },
              {
                table: {
                  widths: [430, 300],
                  body: [
                    [' ', { text: 'Đà Nẵng, Ngày ... tháng ... năm ....', style: 'date' }],
                  ]
                },
                layout: 'noBorders'
              },
              { text: 'PHÒNG NHÂN SỰ TIỀN LƯƠNG                                      THỦ TRƯỞNG ĐƠN VỊ                                                              NGƯỜI KIỂM TRA', style: 'footer' },
            ],
            styles: {
              nameTable: {
                fontSize: 14,
                bold: true,
                margin: [5, 5, 5, 5],
                alignment: 'center'
              },
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
              },
              subheader: {
                fontSize: 12,
                bold: true,
                alignment: 'left'
              },
              rightheader: {
                fontSize: 12,
                bold: true,
              },
              table: {
                alignment: 'center',
                margin: [0, 0, 0, 5],
                // width: '100%',
              },
              smallTable: {
                alignment: 'center',
                margin: [10, 0, 0, 15],
                fontSize: 5
              },
              tableHeader: {
                fontSize: 10,
                bold: true,
                color: 'black',
                alignment: 'center'
              },
              symbol: {
                fontSize: 10,
                margin: [20, 0, 0, 5],
                alignment: 'left',
              },
              date: {
                fontSize: 8,
                alignment: 'right',
              },
              footer: {
                margin: [0, 0, 50, 5],
                fontSize: 9,
                alignment: 'right',
                bold: true,
              },
            },
            defaultStyle: {
              fontSize: 7,
            }

          };

          const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

          pdfDocGenerator.download(this.fileName + ".pdf");
        }
      }
      catch (error) {
        console.error('An error occurred during PDF generation:', error);
        alert('Tạo PDF thất bại. Vui lòng thử lại sau');
      }
      this.departmentName = '';
      this.departmentUnit = '';
      this.departmentUnitSmall = '';
    },
    async getSummary() {
      let res
      try {
        res = await apiServer.post('api/v1/dashboard/summary', {
        })
      } catch (error) {
      }
      if (res && res.data) {
        let data = res.data;
        for (let i = 0; i < data.length; i++) {
          if (data[i].title == "accountNumber") {
            this.infoCheckin.accountNumber = data[i].countcol;
          } else if (data[i].title == "checkedin") {
            this.infoCheckin.checkedin = data[i].countcol;
          } else if (data[i].title == "lateNumber") {
            this.infoCheckin.lateNumber = data[i].countcol;
          } else if (data[i].title == "notCheckin") {
            this.infoCheckin.notCheckin = data[i].countcol;
          } else if (data[i].title == "leave") {
            this.infoCheckin.leave = data[i].countcol;
          } else {
            console.log(data[i].title, data[i].countcol);
          }
        }
      }
    },

    async onChangeCode() {
      this.isOpenCodeRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.aliasId,
          searchType: "code"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.codeResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.codeResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },
    handleHeaderClick(column) {
      this.clickedColumn = column;
      console.log('Bạn đã click vào header của cột:', column);
      // Thực hiện các thao tác khi click vào header của cột tương ứng
    },
    setCodeResult(result) {
      this.fullName = result.text;
      this.aliasId = result.value;
      this.isOpenCodeRes = false;
    },

    async onChangeName() {
      this.isOpenNameRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.fullName,
          searchType: "name"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.nameResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.nameResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },
    hideModalAttendance() {
      this.resetForm();
      this.$refs["modal-attendance"].hide();
    },
    resetForm() {
      this.departmentId = 1;
      let today = new Date();
      this.dayModal = today.getDate()
      this.monthModal = (today.getMonth()).toString().padStart(2, '0');
      this.yearModal = today.getFullYear();
    },
    setNameResult(result) {
      this.fullName = result.text;
      this.aliasId = result.value;
      this.isOpenNameRes = false;
    },
    onClickOutside() {
      this.isOpenCodeRes = false;
    },
    handleClickOutside() {
      this.isOpenNameRes = false;
    },
    async getCountInout() {
      let res
      try {
        res = await apiServer.get('api/v1/report/count-inout', {
        })
      } catch (error) {
      }
      // console.log(res)
      if (res && res.data) {
        this.countInoutIINnfos = res.data;
      }
    },

    async getStatistic() {
      let res
      try {
        res = await apiServer.post('api/v1/checkin/statistic', {
        })
      } catch (error) {
      }
      if (res && res.data) {
        // console.debug(res.data);
        //this.series.push({names:'series-1'});
        let checkedIn = [];
        let checkinLate = [];
        let checkoutEarly = [];
        let leaved = [];
        let unCheckin = [];
        for (let v of res.data) {
          this.options.xaxis.categories.push(v.dateId);
          checkedIn.push(v.checkedin);
          checkinLate.push(v.checkin_late);
          checkoutEarly.push(v.checkout_early);
          leaved.push(v.leaved);
          unCheckin.push(v.un_checkin);
        }
        this.series.push({ name: this.$t('label.checkedin'), data: checkedIn });
        this.series.push({ name: this.$t('label.late'), data: checkinLate });
        this.series.push({ name: this.$t('label.comeBackBefore'), data: checkoutEarly });
        this.series.push({ name: this.$t('label.takeABreak'), data: leaved });
        this.series.push({ name: this.$t('label.notCheckin'), data: unCheckin });
      }
    },

    reportTypeChange() {
      this.getDepartment();
      if (this.reportType === 3) {
        this.dateSelect = null;
        this.configDateTimePicker.mode = "single";
      } else {
        this.configDateTimePicker.mode = "range";
        this.leaveTypeHalf = null;
      }
      this.reportByDepart = false;
      this.reportByPos = false;
      this.reportByMonth = false;
      this.reportByDate = true;
      this.reportByUser = false;
      this.reportByLateEarly = false;
      if ([6, 7, 8, 14].includes(this.reportType)) {
        this.reportByDepart = true;
        this.reportByPos = false;
      }
      if ([6, 8, 13].includes(this.reportType)) {
        this.reportByDepart = true;
        this.reportByUser = true;
        this.reportByMonth = true;
        this.reportByDate = false;
      }
      if (this.reportType == 7) {
        this.reportByUser = true;
        this.reportByLateEarly = true;
      }
      if (this.reportType == 14) {
        this.reportByUser = true;
        this.reportByLateEarly = true;
      }
      if ([9, 10].includes(this.reportType)) {
        this.reportByUser = true;
        this.reportByDepart = true;
        this.reportByDate = false;
        this.reportByMonth = true;
      }
      if ([11, 12].includes(this.reportType)) {
        // this.reportByDepart = true;
        // this.reportByDate = false;
        // if (this.reportType == 10) {
        //   this.reportByUser = true;
        // }
        this.reportByDepart = true;
        this.reportByUser = true;
        this.reportByMonth = true;
        this.reportByDate = false;
      }

    },
    attendance() {
      this.modalAttendance = true;
    },
    print() {
      const d = new Printd()
      d.print(document.getElementById('print'),
        [`#print table tr td { border: 1px solid #e9ecef;padding: 2px; } #print table tr:first-child td {text-align: center; font-weight: bold}`])
    },
    convertDate(inputDate, format) {
      if (inputDate == undefined || inputDate == null) return '';
      let date = new Date(inputDate)
      if (date !== 'Invalid Date') {
        let day = ("0" + date.getDate()).slice(-2)
        // current month
        let month = ("0" + (date.getMonth() + 1)).slice(-2)
        // current year
        let year = date.getFullYear();
        if (format == 'dd-MM-yyyy') {
          return day + "-" + month + "-" + year
        } else if (format == 'dd/MM/yyy') {
          return day + "/" + month + "/" + year
        } else if (format == 'yyyy/MM/dd') {
          return year + "/" + "/" + month + "/" + day
        } else {
          return year + "-" + month + "-" + day
        }
      }
    },
    convertTimestampToDate(timestamp) {
      return moment(timestamp).format('DD/MM/yyyy')
    },
    convertTimestampSendDate(date) {
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, '$3-$2-$1');
      var date = new Date(changedDate);
      return moment(date).format('yyyy-MM-DD')
    }
  }
}
</script>

<style scoped lang="scss">
.icon-dashdoard {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 40px;
}

.list-person-in-out--item {
  background: #fff;
  border-radius: 6px;
  padding: 5px 10px;
  display: flex;
  margin-bottom: 15px;

  .list-person-in-out--item__content {
    display: flex;
    width: 85%;
    justify-content: space-between;
    align-content: center;
    padding: 10px 5px 0;

    h3 {
      line-height: 1.5;
      margin-bottom: 0;
    }

    p {
      font-size: 13px;
      margin-bottom: 0;
    }
  }

  .list-person-in-out--item__img {
    width: 15%;
    padding-top: 5px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

.autocomplete {
  position: relative;
}

.autocomplete-results {
  position: absolute;
  z-index: 999;
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  min-height: 1em;
  max-height: 18em;
  overflow: auto;
  background: #fff;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 2px 4px;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #eeeceb;
}

tr.tbl-footer {
  border: 1px solid #f2f5f8 !important;
}
</style>

<style>
/* tr.tbl-footer {
  border: 1px solid #ebeef2 !important;
}

.tbl-footer td:nth-child(n+6) {
  border: none !important;
} */

.sign-table td:nth-child(2) {
  transform: skewX(-15deg);
}

#tbl-preview tr.tbl-footer td[data-v-2b2175c8] {
  border: 1px solid #e2e5e8 !important;
}

.middle-text {
  text-align: center;
  vertical-align: middle;
}

.text-left {
  text-align: left !important;
}

.pdf-content {
  break-inside: avoid;
}

.table-modal-content {
  max-width: 135vw;
  overflow-x: auto;
}

.report1 {
  zoom: 0.7;
}

.report3 {
  zoom: 0.5;
}


/* .custom-width {
  width: 50% !important;
} */

/* tr.tbl-footer {
  border: 1px solid black !important;
}
.table-hover tr {
  border: 1px solid black !important;
} */

.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  left: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: 100000;
}

.modal-fullscreen .modal-dialog .modal-body {
  width: 100%;
  height: calc(100vh - 200px);
  overflow: auto;
}

/* // Tree Select fix */
.vue-treeselect--searchable:not(.vue-treeselect--disabled) .vue-treeselect__value-container {
  padding: 9px !important;
}

/* .vue-treeselect__single-value {
    padding: 7px;
} */
</style>
